import CheckIcon from "../../../../../assets/icons/common/toggleable/IconCheck";
import CrossIcon from "../../../../../assets/icons/common/toggleable/IconClose";
import Grid from "@mui/material/Grid";
import React from "react";
import Group from "../../../../Wizard/Group";
import { requiredValidator } from "../../../../../services/validationRules";
import { AbstractCase } from "../../../../../types/Entities/AbstractCase";

type ClientReceivedReactionProps = {
  product: AbstractCase;
};

const ClientReceivedReaction = ({ product }: ClientReceivedReactionProps) => {
  if (product.extrajudicialReaction !== "noReaction") {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Group
        type={"radio"}
        name={"reactionReceived"}
        question={"Haben Sie zwischenzeitlich eine Reaktion der Gegenseite erhalten?"}
        options={[
          {
            labelText: "Ja",
            labelIcon: <CheckIcon />,
            labelIconActive: <CheckIcon active />,
            value: "yes",
          },
          {
            labelText: "Nein",
            labelIcon: <CrossIcon />,
            labelIconActive: <CrossIcon active />,
            value: "no",
          },
        ]}
        validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
      />
    </Grid>
  );
};
export default ClientReceivedReaction;
