import React from "react";
import { Box, Grid, Hidden } from "@mui/material";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import CheckIcon from "../../../../assets/icons/common/toggleable/IconCheck";
import CrossIcon from "../../../../assets/icons/common/toggleable/IconClose";
import { dateFieldDefault, pastDateDefault, requiredValidator } from "../../../../services/validationRules";
import MenuItem from "@mui/material/MenuItem";
import { translate } from "../../../../services/translations/translations";
import { useForm } from "../../../../provider/Form/FormProvider";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import Group from "../../../Wizard/Group";
import Typography from "@mui/material/Typography";
import FormLegend from "../../Common/FormLegend/FormLegend";
import ValidatorDateField from "../../../FormFields/ValidatorDateField";
import NumberFieldConfigured from "../../ConfiguredFormElements/NumberFieldConfigured/NumberFieldConfigured";
import { ContractDetailsProps } from "../../../../types/ContractDetailsProps";
import wizardStyle from "../../wizardStyle";

export default function TravelContractDetails({ submitProps, product }: ContractDetailsProps) {
  const { values, handleSubmit } = useForm();
  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} sx={wizardStyle.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Details zu Ihrem Vertrag</strong>
              </FormLegend>
            </Grid>
            <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
              <FormLegend>Was für ein Problem gibt es bei Ihrer Reise?</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorSelectField label="Problem mit Reise" name="reasonForDispute" validators={[requiredValidator("Bitte geben Sie Ihr Problem an")]}>
                <MenuItem value="cancelled">{translate(`${product.name}.reasonForDispute.options.cancelled.label`, values)}</MenuItem>
                <MenuItem value="participationImpossible">
                  {translate(`${product.name}.reasonForDispute.options.participationImpossible.label`, values)}
                </MenuItem>
                <MenuItem value="notAsAdvertised">{translate(`${product.name}.reasonForDispute.options.notAsAdvertised.label`, values)}</MenuItem>
                <MenuItem value="other">{translate(`${product.name}.reasonForDispute.options.other.label`, values)}</MenuItem>
              </ValidatorSelectField>
            </Grid>
            <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
              <FormLegend>Bitte teilen Sie uns hier mehr Details zu Ihrer Buchung mit</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                label="Buchungs- oder Kundennummer"
                name="contractNumber"
                helperText="Bitte geben Sie hier die Buchungs- oder Kundennummer Ihrer Reise an. Sie finden diese meist in der Buchungsbestätigung oder in Ihren Reiseunterlagen."
                validators={[requiredValidator("Bitte geben Sie die Buchungs- oder Kundennummer an")]}
              />
            </Grid>
            <Grid item xs={12}>
              <ValidatorDateField
                label="Tag der Buchung"
                name="bookingDate"
                validators={[requiredValidator("Bitte geben Sie das Datum an"), dateFieldDefault, pastDateDefault]}
              />
            </Grid>
            <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
              <FormLegend>Von wann bis wann haben Sie die Reise gebucht?</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorDateField
                label="Start der Reise"
                name="journeyStart"
                validators={[requiredValidator("Bitte geben Sie das Datum an"), dateFieldDefault]}
              />
            </Grid>
            <Grid item xs={12}>
              <ValidatorDateField label="Ende der Reise" name="journeyEnd" validators={[requiredValidator("Bitte geben Sie das Datum an"), dateFieldDefault]} />
            </Grid>
            {values.reasonForDispute === "cancelled" && (
              <>
                <Grid item xs={12}>
                  <Group
                    type="radio"
                    name="contractualPartnerHasCancelled"
                    question="Hat Ihr Reiseanbieter die Reise von sich aus abgesagt?"
                    options={[
                      {
                        labelText: "Ja",
                        labelIcon: <CheckIcon />,
                        labelIconActive: <CheckIcon active />,
                        value: "yes",
                      },
                      {
                        labelText: "Nein",
                        labelIcon: <CrossIcon />,
                        labelIconActive: <CrossIcon active />,
                        value: "no",
                      },
                    ]}
                    validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
                  />
                </Grid>
                {values.contractualPartnerHasCancelled === "yes" && (
                  <>
                    <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                      <FormLegend>Wann hat Ihr Reiseanbieter die Reise abgesagt?</FormLegend>
                    </Grid>
                    <Grid item xs={12}>
                      <ValidatorDateField
                        name="contractualPartnerCancellationDate"
                        label="Reise abgesagt am"
                        validators={[requiredValidator("Bitte geben Sie das Datum an"), dateFieldDefault, pastDateDefault]}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
            <Grid item xs={12}>
              <Group
                type="radio"
                name="buyerPaidStatus"
                question="Haben Sie Ihrem Reiseanbieter die Reise bereits vollständig bezahlt?"
                options={[
                  {
                    labelText: translate(`${product.name}.buyerPaidStatus.options.fully.label`, values),
                    labelIcon: <CheckIcon />,
                    labelIconActive: <CheckIcon active />,
                    value: "fully",
                  },
                  {
                    labelText: translate(`${product.name}.buyerPaidStatus.options.partially.label`, values),
                    labelIcon: <CrossIcon />,
                    labelIconActive: <CrossIcon active />,
                    value: "partially",
                  },
                ]}
                validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
              />
            </Grid>
            <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
              <FormLegend>Wann haben Sie die (letzte) Zahlung veranlasst?</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorDateField
                name="buyerPaidDate"
                label="(Letzte) Zahlung am"
                validators={[requiredValidator("Bitte geben Sie das Datum an"), dateFieldDefault, pastDateDefault]}
              />
            </Grid>
            <NumberFieldConfigured caseData={product} fieldPath={"buyerPaidAmount"} fieldType={"money"} numberType={"float"} required />
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
