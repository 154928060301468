import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconQuestionmarkBubble = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0  512 512">
      <g style={{ fill: getIconColor(active, theme) }}>
        <g>
          <path
            d="M216.5,73.9C174,90.7,146,132.2,146,177.7c0,16.5,13.4,30,30,30c16.6,0,30-13.4,30-30
          c0-28.4,22.9-51.6,51.6-51.6c28.1,0,51.7,23.7,51.7,51.6c0,21.6-13,40.5-33.1,48.3c-14.4,5.5-26.6,15.1-35.3,27.6
          c-15.8,22.9-13.2,42.2-13.2,72.1c0,2.4,0.3,4.7,0.8,7.1c0.8,3.4,3.8,5.7,7.3,5.7c4.9,0,8.4-4.6,7.3-9.3c-0.6-2.4-0.4-0.7-0.4-33.1
          c0-25,15.3-47,38.9-56.1c44.6-17.1,57.7-74.6,23.1-109.2c-42.1-42.1-113.7-12.1-113.6,47c0,8.2-6.7,15-15,15c-8.3,0-15-6.7-15-15
          c0-54.1,45.4-100.1,102.8-96.4c49.8,3.1,90.5,44.9,90.5,96.4c0,40.3-24.3,75.7-62,90.2c-5.8,2.2-10.7,6-14.3,11
          c-6.8,9.7-5.4,15.3-5.4,46.7c0,8.2-6.8,15-15,15c-4.1,0-7.5,3.4-7.5,7.5c0,15.7,37.5,6.2,37.5-22.5V296c0-6.3,3.9-11.7,10-14.1
          c43.6-16.8,71.6-58.2,71.6-104.2C369.3,99.4,289.7,44.8,216.5,73.9z"
          />
        </g>
        <g>
          <path
            d="M283.3,400.5c-11.7-19.4-38.7-19-50.6-1.1c-13.1,19.7,0.6,46.6,25.2,46.6C280.6,446,295.6,421,283.3,400.5z
           M254.1,430.5c-5.8-1.4-10-5.8-11.1-11.6c-3.1-16.6,18.8-25,27.5-10.7C277.4,419.8,267.2,433.7,254.1,430.5z"
          />
        </g>
        <g>
          <path
            d="M384.4,34.5C251.2-42.8,80,14.8,20.1,156.4c-24.4,57.5-26.4,121.9-7.3,179.8c13.3,40.2,35.1,69.5,36.8,71.1
          l0,0l0,0c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c2.8,2.4,5.2,1.7,6.2,1.7l0,0c0.1,0,0.8-0.2,1.4-0.4c0,0,0,0,0,0
          s0.2-0.1,0.2-0.1h0c4.8-2.1,5.7-8.2,3.1-11.3C-55,237.5,60.7,15.1,256,15.1c134.8,0,241,110,241,240.9
          c0,133.1-107.8,240.9-241,240.9c-51.1,0-100-15.8-141.3-45.7c-2.3-1.7-5.5-1.9-8-0.5l-84.2,45.6c-4,2.1-7.6-1.4-7.6-4.4
          c0-1.8-1.8,2.1,32-60.3c2.7-5-1-11.1-6.6-11.1c-2.8,0-5.3,1.5-6.6,3.9C-0.2,487.3,0,485.2,0,491.9c0,14.7,16,25.1,29.7,17.6
          l80.1-43.4c86.6,60.4,201.9,61.2,289.4,2.1C555.8,362.5,547.9,129.4,384.4,34.5z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconQuestionmarkBubble;
