/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { MediaObject } from "./MediaObject/MediaObject";

export enum InquiryToCustomerStatus {
  STATUS_OPEN = 10,
  STATUS_IN_PROGRESS = 20,
  STATUS_OVERDUE = 30,
  STATUS_DONE = 40,
}

export interface RequestedDocument {
  temporaryFilesToCombine: Array<MediaObject>;
  documentName: string;
  mediaObjectId: number;
  mandatoryType?: "" | "full" | "deadlineDependent";
  mandatory?: boolean; //deprecated -> remove this with its fallback usages in january 2024
  deadlineDependentMandatory?: boolean;
  identifier?: string;
}

export default interface InquiryToCustomer {
  id: number;
  "@id": string;
  product: string;
  productId: number;
  status: InquiryToCustomerStatus;
  doneBy: string;
  createdBy: string;
  inquiryType: "appointment" | "information" | "documents" | "informationAndDocuments" | "decision" | "link";
  overdueConsequences: "noFurtherActionRequired" | "actionRequired";
  inquiryTextAppointment?: string;
  inquiryTextInformation?: string;
  inquiryTextDocuments?: string;
  inquiryTextInformationCustomer?: string;
  inquiryTextDocumentsCustomer?: string;
  inquiryTextAppointmentCustomer?: string;
  deadline: string;
  hideInquiryTextDocumentsCustomer: boolean;
  requestedDocuments?: Array<RequestedDocument>;
  created: string;
  inquiryDecisionQuestion?: string;
  inquiryDecisionAnswer?: string;
  linkUrl?: string;
}
