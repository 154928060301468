import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconDocumentHouseSign = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44">
        <g>
          <path
            style={{ fill: "none" }}
            d="M220.738,34.864c-6.991-9.199-16.741-11.146-21.594-11.519v25.957c0,2.995,2.441,5.432,5.441,5.432
			h24.199C228.705,51.05,227.514,43.779,220.738,34.864z"
          />
          <path
            style={{ fill: "none" }}
            d="M146.858,256.006c0.377-0.966,0.149-1.541-0.09-1.935c-0.291-0.476-0.71-0.936-1.601-0.936
			c-0.197,0-0.417,0.022-0.663,0.072c-3.668,0.74-8.618,6.261-9.079,9.305c-0.119,0.782,0.271,1.419,0.76,1.893
			C140.325,262.434,145.684,259.014,146.858,256.006z"
          />
          <path
            style={{ fill: "none" }}
            d="M195.054,248.778c-0.479-0.783-0.973-1.558-1.494-2.314c-0.006-0.009-0.012-0.018-0.018-0.026
			c-0.525-0.761-1.079-1.503-1.646-2.236c-0.107-0.139-0.213-0.278-0.322-0.415c-0.555-0.702-1.129-1.391-1.722-2.064
			c-0.116-0.132-0.234-0.261-0.351-0.391c-0.625-0.696-1.263-1.382-1.927-2.047c-0.663-0.662-1.347-1.299-2.041-1.922
			c-0.133-0.119-0.264-0.239-0.398-0.357c-0.672-0.59-1.358-1.162-2.057-1.715c-0.141-0.111-0.283-0.22-0.425-0.33
			c-0.731-0.564-1.471-1.117-2.229-1.641c-0.011-0.008-0.023-0.015-0.035-0.022c-0.756-0.521-1.528-1.014-2.31-1.491
			c-0.087-0.054-0.173-0.107-0.26-0.16l-4.76,15.198l-0.694,2.216c0.067,0.039,0.134,0.082,0.202,0.122
			c0.259,0.154,0.519,0.314,0.781,0.482c0.104,0.067,0.206,0.136,0.309,0.206c0.235,0.156,0.463,0.319,0.689,0.488
			c0.099,0.074,0.197,0.146,0.293,0.222c0.307,0.243,0.604,0.495,0.874,0.764c0.268,0.268,0.519,0.563,0.761,0.869
			c0.079,0.099,0.152,0.2,0.229,0.302c0.165,0.221,0.325,0.443,0.479,0.674c0.072,0.107,0.143,0.211,0.212,0.319
			c0.174,0.271,0.341,0.54,0.5,0.809c0.029,0.05,0.061,0.1,0.09,0.149c0.005,0.009,0.011,0.018,0.017,0.027l4.104-1.285l13.309-4.17
			C195.161,248.951,195.107,248.864,195.054,248.778z"
          />
          <path
            style={{ fill: "none" }}
            d="M234.905,173.415l-6.64,6.64c0.094,0.058,0.184,0.123,0.277,0.181
			c0.821,0.508,1.633,1.031,2.428,1.582c0.071,0.05,0.14,0.102,0.211,0.152c0.754,0.527,1.493,1.078,2.222,1.645
			c0.141,0.109,0.283,0.217,0.422,0.327c0.749,0.594,1.482,1.209,2.202,1.843c0.148,0.13,0.293,0.263,0.44,0.395
			c0.737,0.663,1.464,1.34,2.168,2.044c0.704,0.704,1.38,1.43,2.043,2.167c0.133,0.146,0.267,0.293,0.397,0.442
			c0.633,0.718,1.247,1.45,1.839,2.198c0.113,0.143,0.222,0.287,0.334,0.431c0.562,0.724,1.108,1.457,1.633,2.206
			c0.053,0.076,0.109,0.149,0.162,0.226c0.549,0.792,1.071,1.601,1.576,2.419c0.06,0.097,0.126,0.188,0.185,0.284l11.439-11.441
			c0.888-0.888,1.4-2.036,1.441-3.234c0.04-1.142-0.358-2.19-1.12-2.952l-12.674-12.675c-0.724-0.724-1.718-1.122-2.799-1.122
			c-1.236,0-2.471,0.526-3.388,1.443l-4.615,4.615v0.186H234.905z"
          />
          <path
            style={{ fill: "none" }}
            d="M129.537,245.204c0.619-1.519,0.663-2.903,0.131-4.117c-0.089-0.202-0.751-0.427-1.93-0.427
			c-1.447,0-3.672,0.337-6.576,1.466c-5.56,2.161-14.248,7.937-11.544,15.873c0.22,0.644,0.464,1.246,0.731,1.811
			C124.383,253.303,128.407,247.977,129.537,245.204z"
          />
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M65.938,79.196h3.736c0-1.202,0.975-2.177,2.177-2.177s2.177,0.975,2.177,2.177v29.299h10.461
				V85.36c0-1.202,0.975-2.177,2.177-2.177H99.22c1.202,0,2.177,0.975,2.177,2.177v23.135h10.461V79.196
				c0-1.202,0.975-2.177,2.177-2.177s2.177,0.975,2.177,2.177h3.736L92.942,47.201L65.938,79.196z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M243.585,201.698c-0.491-0.841-1.011-1.665-1.551-2.478c-0.091-0.138-0.181-0.276-0.273-0.413
				c-0.534-0.787-1.092-1.561-1.671-2.318c-0.105-0.139-0.212-0.275-0.319-0.412c-0.594-0.762-1.207-1.51-1.844-2.238
				c-0.088-0.102-0.18-0.199-0.269-0.3c-0.678-0.763-1.374-1.513-2.1-2.238c-0.151-0.151-0.314-0.288-0.467-0.438
				c-0.582-0.567-1.17-1.128-1.775-1.665c-0.098-0.088-0.194-0.177-0.293-0.264c-0.732-0.64-1.482-1.254-2.246-1.85
				c-0.134-0.104-0.267-0.209-0.401-0.311c-0.459-0.351-0.933-0.677-1.403-1.011c-0.311-0.222-0.614-0.456-0.929-0.67
				c-0.13-0.088-0.262-0.173-0.392-0.261c-0.82-0.544-1.651-1.068-2.5-1.564c-0.021-0.012-0.041-0.025-0.062-0.038l-0.002,0.003
				l-44.806,44.806c0.088,0.054,0.172,0.115,0.26,0.169c0.83,0.512,1.649,1.041,2.451,1.597c0.063,0.044,0.124,0.091,0.187,0.134
				c0.763,0.535,1.511,1.092,2.249,1.665c0.136,0.106,0.273,0.209,0.409,0.316c0.751,0.597,1.488,1.214,2.211,1.85
				c0.145,0.128,0.288,0.259,0.432,0.388c0.739,0.664,1.468,1.343,2.174,2.049c0.706,0.706,1.384,1.434,2.048,2.172
				c0.131,0.146,0.263,0.291,0.393,0.438c0.635,0.72,1.25,1.454,1.844,2.204c0.111,0.14,0.218,0.282,0.328,0.423
				c0.564,0.726,1.111,1.46,1.637,2.21c0.053,0.076,0.109,0.149,0.161,0.225c0.548,0.791,1.069,1.599,1.574,2.416
				c0.059,0.096,0.126,0.188,0.185,0.284l29.74-29.739l6.529-6.528l8.537-8.536l0.003-0.003
				C243.616,201.747,243.599,201.724,243.585,201.698z M238.648,201.106l-3.558,3.558l-6.529,6.529l-28.288,28.287
				c-0.425,0.425-0.982,0.638-1.539,0.638c-0.557,0-1.114-0.213-1.539-0.638c-0.85-0.85-0.85-2.229,0-3.078l31.366-31.365
				l6.529-6.529l0.479-0.479c0.85-0.851,2.228-0.849,3.078,0C239.499,198.878,239.499,200.257,238.648,201.106z"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M197.422,132.182H70.229c-1.202,0-2.177,0.975-2.177,2.177s0.975,2.177,2.177,2.177h127.193
				c1.202,0,2.177-0.975,2.177-2.177S198.624,132.182,197.422,132.182z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M197.422,150.679H70.229c-1.202,0-2.177,0.975-2.177,2.177s0.975,2.177,2.177,2.177h127.193
				c1.202,0,2.177-0.975,2.177-2.177S198.624,150.679,197.422,150.679z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M197.422,169.175H70.229c-1.202,0-2.177,0.975-2.177,2.177s0.975,2.177,2.177,2.177h127.193
				c1.202,0,2.177-0.975,2.177-2.177S198.624,169.175,197.422,169.175z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M197.422,187.671H70.229c-1.202,0-2.177,0.975-2.177,2.177s0.975,2.177,2.177,2.177h127.193
				c1.202,0,2.177-0.975,2.177-2.177S198.624,187.671,197.422,187.671z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M94.605,42.422c-0.827-0.979-2.499-0.979-3.326,0l-29.854,35.37
				c-0.775,0.919-0.659,2.292,0.259,3.067s2.292,0.659,3.067-0.259l1.186-1.404l27.005-31.995l27.005,31.995l1.186,1.404
				c0.431,0.51,1.045,0.772,1.664,0.772c0.496,0,0.994-0.169,1.403-0.514c0.918-0.775,1.035-2.148,0.259-3.067L94.605,42.422z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M71.851,77.02c-1.202,0-2.177,0.975-2.177,2.177v31.476c0,1.202,0.975,2.177,2.177,2.177h14.814
				c1.202,0,2.177-0.975,2.177-2.177V87.537h8.201v23.135c0,1.202,0.975,2.177,2.177,2.177h14.814c1.202,0,2.177-0.975,2.177-2.177
				V79.196c0-1.202-0.975-2.177-2.177-2.177s-2.177,0.975-2.177,2.177v29.299h-10.461V85.36c0-1.202-0.975-2.177-2.177-2.177H86.665
				c-1.202,0-2.177,0.975-2.177,2.177v23.135H74.027V79.196C74.027,77.994,73.053,77.02,71.851,77.02z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M261.645,177.89l-12.674-12.675c-1.547-1.546-3.634-2.397-5.877-2.397
				c-2.382,0-4.739,0.991-6.466,2.719l-1.537,1.537V58.28c0.356-2.271,1.418-13.457-9.153-27.367
				c-9.999-13.155-24.576-14.178-28.805-14.178c-0.622,0-1.047,0.02-1.292,0.034H47.929c-6.601,0-11.971,5.37-11.971,11.971v47.972
				c0,1.803,1.461,3.265,3.265,3.265s3.265-1.462,3.265-3.265V28.74c0-3,2.441-5.441,5.441-5.441h144.687v26.004
				c0,6.596,5.37,11.961,11.971,11.961h23.976v112.151h0.187l-5.573,5.572l-27.18,27.18H70.229c-1.202,0-2.177,0.975-2.177,2.177
				s0.975,2.177,2.177,2.177h121.414l-14.142,14.142H70.229c-1.202,0-2.177,0.975-2.177,2.177s0.975,2.177,2.177,2.177h103.854
				l-6.384,20.383l-3.45,10.466c-0.084,0.258-0.102,0.521-0.089,0.781c-0.254,0.011-0.51,0.073-0.746,0.208
				c-0.875,0.504-1.455,0.781-1.829,0.933c-0.004-0.385,0.013-0.857,0.026-1.249c0.019-0.522,0.039-1.093,0.039-1.7
				c0-0.572,0-2.313-1.437-2.938c-1.672-0.729-3.169,0.665-6.151,3.441c-1.376,1.281-3.089,2.876-5.055,4.468
				c-2.667,2.16-6.162,2.766-9.002,2.288c4.11-2.264,8.576-5.522,9.896-8.904c0.664-1.699,0.544-3.367-0.346-4.823
				c-1.231-2.015-3.253-2.854-5.695-2.361c-5.132,1.035-11.016,7.756-11.662,12.016c-0.2,1.32,0.096,2.581,0.833,3.685
				c-0.157,0.051-0.307,0.098-0.448,0.138c-6.994,2-15.636,2.751-20.493-3.24c11.379-5.305,18.254-10.73,20.471-16.17
				c0.951-2.332,0.984-4.635,0.098-6.659c-1.555-3.547-7.801-2.59-12.679-0.693c-8.902,3.461-16.515,10.978-13.451,19.969
				c0.249,0.731,0.528,1.43,0.837,2.092c-1.013,0.438-2.072,0.881-3.178,1.327c-0.836,0.338-1.24,1.289-0.902,2.125
				s1.29,1.239,2.125,0.902c1.243-0.502,2.446-1.006,3.607-1.51c3.121,4.35,7.986,6.579,14.3,6.579
				c3.059,0,6.458-0.523,10.162-1.583c0.615-0.175,1.427-0.469,2.36-0.861c3.885,2.042,10.411,2.117,15.221-1.777
				c2.054-1.663,3.813-3.3,5.225-4.616c0.704-0.655,1.458-1.357,2.082-1.894c-0.006,0.201-0.013,0.396-0.02,0.585
				c-0.069,1.937-0.128,3.609,1.282,4.459c1.357,0.817,3.076,0.147,5.412-1.196c0.402-0.231,0.666-0.609,0.768-1.027
				c0.166,0.04,0.334,0.066,0.505,0.066c0.229,0,0.458-0.036,0.682-0.109l10.464-3.452l21.234-6.651
				c0.335-0.105,0.641-0.29,0.889-0.538l0.304-0.304l28.674-28.672v48.31c0,2.994-2.437,5.431-5.431,5.431H47.929
				c-3,0-5.441-2.437-5.441-5.431V86.843c0-1.803-1.461-3.265-3.265-3.265s-3.265,1.462-3.265,3.265v184.463
				c0,6.595,5.37,11.96,11.971,11.96h175.202c6.595,0,11.96-5.365,11.96-11.96v-54.839l12.782-12.781l13.45-13.452
				c1.67-1.67,2.634-3.858,2.714-6.163C264.119,181.709,263.27,179.514,261.645,177.89z M204.586,54.734
				c-3,0-5.441-2.437-5.441-5.432V23.346c4.853,0.372,14.603,2.319,21.594,11.519c6.776,8.915,7.967,16.186,8.047,19.87H204.586z
				 M109.618,257.999c-2.703-7.937,5.984-13.712,11.544-15.873c2.903-1.129,5.129-1.466,6.576-1.466c1.179,0,1.841,0.225,1.93,0.427
				c0.532,1.214,0.488,2.599-0.131,4.117c-1.13,2.772-5.154,8.099-19.188,14.605C110.082,259.245,109.838,258.643,109.618,257.999z
				 M135.426,262.513c0.461-3.044,5.411-8.564,9.079-9.305c0.246-0.05,0.466-0.072,0.663-0.072c0.89,0,1.31,0.46,1.601,0.936
				c0.24,0.394,0.467,0.969,0.09,1.935c-1.174,3.008-6.533,6.428-10.673,8.399C135.697,263.932,135.307,263.295,135.426,262.513z
				 M198.821,246.578c-0.059-0.097-0.126-0.188-0.185-0.284c-0.505-0.817-1.025-1.625-1.574-2.416
				c-0.052-0.075-0.108-0.148-0.161-0.225c-0.525-0.75-1.073-1.484-1.637-2.21c-0.109-0.141-0.217-0.283-0.328-0.423
				c-0.594-0.75-1.209-1.484-1.844-2.204c-0.129-0.147-0.262-0.292-0.393-0.438c-0.664-0.738-1.342-1.466-2.048-2.172
				c-0.706-0.706-1.435-1.385-2.174-2.049c-0.144-0.129-0.287-0.26-0.432-0.388c-0.723-0.636-1.459-1.253-2.211-1.85
				c-0.135-0.107-0.272-0.21-0.409-0.316c-0.737-0.573-1.485-1.13-2.249-1.665c-0.063-0.043-0.124-0.09-0.187-0.134
				c-0.802-0.556-1.622-1.085-2.451-1.597c-0.088-0.054-0.171-0.115-0.26-0.169l44.806-44.806l0.002-0.003
				c0.021,0.013,0.041,0.026,0.062,0.038c0.849,0.496,1.68,1.021,2.5,1.564c0.13,0.088,0.262,0.173,0.392,0.261
				c0.315,0.214,0.618,0.448,0.929,0.67c0.47,0.334,0.944,0.66,1.403,1.011c0.134,0.103,0.268,0.207,0.401,0.311
				c0.764,0.596,1.514,1.21,2.246,1.85c0.099,0.087,0.195,0.176,0.293,0.264c0.605,0.537,1.193,1.098,1.775,1.665
				c0.153,0.149,0.315,0.286,0.467,0.438c0.726,0.726,1.422,1.476,2.1,2.238c0.089,0.101,0.181,0.198,0.269,0.3
				c0.638,0.729,1.25,1.477,1.844,2.238c0.106,0.137,0.213,0.273,0.319,0.412c0.579,0.758,1.137,1.531,1.671,2.318
				c0.092,0.137,0.183,0.275,0.273,0.413c0.54,0.813,1.06,1.637,1.551,2.478c0.014,0.025,0.031,0.049,0.045,0.073l-0.003,0.003
				l-8.537,8.536l-6.529,6.528L198.821,246.578z M181.904,253.208l-4.104,1.285c-0.005-0.01-0.011-0.019-0.017-0.027
				c-0.029-0.049-0.061-0.1-0.09-0.149c-0.159-0.268-0.326-0.538-0.5-0.809c-0.069-0.107-0.141-0.214-0.212-0.319
				c-0.153-0.229-0.314-0.453-0.479-0.674c-0.076-0.102-0.15-0.203-0.229-0.302c-0.242-0.306-0.493-0.602-0.761-0.869
				c-0.269-0.269-0.566-0.521-0.874-0.764c-0.096-0.076-0.195-0.148-0.293-0.222c-0.225-0.168-0.455-0.332-0.689-0.488
				c-0.103-0.069-0.206-0.139-0.309-0.206c-0.261-0.167-0.522-0.328-0.781-0.482c-0.068-0.04-0.135-0.083-0.202-0.122l0.694-2.216
				l4.76-15.198c0.087,0.053,0.173,0.106,0.26,0.16c0.781,0.478,1.554,0.971,2.31,1.491c0.011,0.008,0.023,0.015,0.035,0.022
				c0.758,0.523,1.499,1.076,2.229,1.641c0.142,0.11,0.284,0.219,0.425,0.33c0.699,0.553,1.385,1.125,2.057,1.715
				c0.134,0.118,0.265,0.238,0.398,0.357c0.694,0.623,1.378,1.26,2.041,1.922c0.665,0.665,1.303,1.351,1.927,2.047
				c0.117,0.13,0.235,0.259,0.351,0.391c0.593,0.674,1.167,1.362,1.722,2.064c0.109,0.137,0.215,0.276,0.322,0.415
				c0.567,0.733,1.121,1.476,1.646,2.236c0.006,0.009,0.011,0.018,0.018,0.026c0.521,0.757,1.016,1.531,1.494,2.314
				c0.053,0.086,0.107,0.173,0.16,0.26L181.904,253.208z M258.245,187.154l-11.439,11.441c-0.059-0.097-0.125-0.188-0.185-0.284
				c-0.505-0.818-1.027-1.627-1.576-2.419c-0.053-0.076-0.109-0.149-0.162-0.226c-0.524-0.749-1.071-1.482-1.633-2.206
				c-0.112-0.144-0.221-0.288-0.334-0.431c-0.593-0.748-1.207-1.48-1.839-2.198c-0.131-0.149-0.265-0.296-0.397-0.442
				c-0.663-0.737-1.339-1.463-2.043-2.167c-0.705-0.704-1.431-1.381-2.168-2.044c-0.146-0.132-0.292-0.265-0.44-0.395
				c-0.72-0.634-1.453-1.249-2.202-1.843c-0.14-0.11-0.282-0.218-0.422-0.327c-0.729-0.566-1.468-1.117-2.222-1.645
				c-0.071-0.051-0.14-0.103-0.211-0.152c-0.795-0.551-1.607-1.074-2.428-1.582c-0.094-0.058-0.183-0.123-0.277-0.181l6.64-6.64
				h0.186v-0.186l4.615-4.615c0.917-0.917,2.152-1.443,3.388-1.443c1.081,0,2.075,0.398,2.799,1.122l12.674,12.675
				c0.762,0.762,1.16,1.811,1.12,2.952C259.645,185.118,259.133,186.267,258.245,187.154z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M235.091,204.664l3.558-3.558c0.85-0.85,0.85-2.229,0-3.078c-0.85-0.849-2.228-0.851-3.078,0
				l-0.479,0.479l-6.529,6.529l-31.366,31.365c-0.85,0.85-0.85,2.229,0,3.078c0.425,0.425,0.982,0.638,1.539,0.638
				c0.557,0,1.114-0.213,1.539-0.638l28.288-28.287L235.091,204.664z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconDocumentHouseSign;
