import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconChurch = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path d="M150,151.626c10.973,0,19.899-8.927,19.899-19.899c0-10.973-8.927-19.9-19.899-19.9c-10.973,0-19.9,8.927-19.9,19.9C130.099,142.699,139.026,151.626,150,151.626z M150,119.234c6.888,0,12.492,5.604,12.492,12.493s-5.604,12.492-12.492,12.492c-6.889,0-12.493-5.604-12.493-12.492S143.111,119.234,150,119.234z" />
        <path d="M247.704,188.337h-21.182v-14.985h15.259c0.007,0,0.013,0,0.02,0c2.045,0,3.704-1.658,3.704-3.703c0-0.94-0.351-1.799-0.928-2.452l-90.874-124.64V30.089h7.682c2.045,0,3.704-1.658,3.704-3.703c0-2.046-1.658-3.704-3.704-3.704h-7.682V15c0-2.045-1.658-3.704-3.704-3.704s-3.704,1.658-3.704,3.704v7.682h-7.681c-2.045,0-3.704,1.658-3.704,3.704c0,2.045,1.658,3.703,3.704,3.703h7.681v12.533c-0.779,1.009-1.797,2.404-3.232,4.372L55.227,167.468c-0.821,1.126-0.94,2.618-0.309,3.86c0.632,1.243,1.908,2.024,3.302,2.024h15.259v14.985H52.296c-18.597,0-33.726,15.129-33.726,33.725V285c0,2.045,1.658,3.704,3.704,3.704h54.908H150h0h0h72.817h54.908c2.045,0,3.704-1.658,3.704-3.704v-62.938C281.429,203.466,266.3,188.337,247.704,188.337z M150,50.053l84.496,115.893h-9.793l-71.708-98.55c-0.697-0.958-1.811-1.524-2.995-1.524c-1.886,0-1.886,0-6.124,5.824l-68.58,94.25h-9.793C81.384,144.165,136.52,68.541,150,50.053z M25.978,222.063c0-14.512,11.807-26.318,26.318-26.318h21.182v85.552h-47.5V222.063z M80.886,170.855c6.541-8.99,56.318-77.4,69.115-94.985l69.114,94.985v110.441h-28.312v-74.244c0-22.5-18.304-40.804-40.802-40.804c-22.5,0-40.804,18.305-40.804,40.804v44.819c0,2.045,1.658,3.704,3.704,3.704s3.704-1.659,3.704-3.704v-44.819c0-18.415,14.982-33.397,33.397-33.397c18.414,0,33.395,14.982,33.395,33.397v74.244H150h0h0h-32.706v-6.831c0-2.045-1.658-3.704-3.704-3.704s-3.704,1.658-3.704,3.704v6.831H80.886V170.855z M274.022,281.296h-47.5v-85.552h21.182c14.512,0,26.318,11.806,26.318,26.318V281.296z" />
      </g>
    </SvgIcon>
  );
};

export default IconChurch;
