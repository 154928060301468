import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconDocumentDataleak = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24">
        <g>
          <polygon style={{ fill: "none" }} points="179.133,66.688 174.747,62.301 174.747,66.688 		" />
          <path
            style={{ fill: "none" }}
            d="M164.732,20.887h-29.764c-6.218,0-10.731,4.076-10.731,9.693v2.197c0,5.617,4.513,9.693,10.731,9.693
			h29.764c6.22,0,10.735-4.076,10.735-9.693v-1.061c0-0.012-0.003-0.023-0.003-0.035s0.003-0.021,0.003-0.033V30.58
			C175.468,24.963,170.953,20.887,164.732,20.887z"
          />
          <path
            style={{ fill: "#137F7B" }}
            d="M192.772,137.67l-12.16-10.863v2.656c0,1.469-1.19,2.66-2.659,2.66
			c-11.256,0-19.221,2.924-23.674,8.691c-3.191,4.133-4.104,9.102-4.198,13.119c4.227-4.266,12.356-8.715,27.873-8.715
			c1.468,0,2.659,1.189,2.659,2.658v0.668L192.772,137.67z"
          />
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M241.789,210.885c-1.958,0-3.545,1.588-3.545,3.545v56.219c0,2.713-2.206,4.92-4.916,4.92H66.662
				c-2.71,0-4.916-2.207-4.916-4.92V95.551c0-1.959-1.587-3.545-3.545-3.545s-3.545,1.586-3.545,3.545v175.098
				c0,6.623,5.386,12.01,12.006,12.01h166.666c6.62,0,12.006-5.387,12.006-12.01V214.43
				C245.334,212.473,243.747,210.885,241.789,210.885z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M233.324,28.137H179.01c-0.078,0-0.151,0.02-0.228,0.023c-1.16-6.309-6.807-10.818-14.05-10.818
				h-29.764c-7.242,0-12.887,4.51-14.046,10.82c-0.08-0.006-0.156-0.025-0.237-0.025H66.654c-6.616,0-11.999,5.385-11.999,12.002
				v39.947c0,1.957,1.588,3.545,3.545,3.545s3.545-1.588,3.545-3.545V40.139c0-2.709,2.202-4.912,4.908-4.912h54.031
				c0.082,0,0.158-0.018,0.238-0.023c1.162,6.307,6.806,10.813,14.045,10.813h29.764c7.241,0,12.885-4.506,14.049-10.813
				c0.077,0.006,0.15,0.023,0.229,0.023h54.314c2.718,0,4.93,2.203,4.93,4.912v156.387c0,1.957,1.587,3.545,3.545,3.545
				s3.545-1.588,3.545-3.545V40.139C245.344,33.521,239.952,28.137,233.324,28.137z M175.468,31.648
				c0,0.012-0.003,0.021-0.003,0.033s0.003,0.023,0.003,0.035v1.061c0,5.617-4.515,9.693-10.735,9.693h-29.764
				c-6.218,0-10.731-4.076-10.731-9.693V30.58c0-5.617,4.513-9.693,10.731-9.693h29.764c6.22,0,10.735,4.076,10.735,9.693V31.648z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M87.729,193.281H209.01c0.979,0,1.772-0.795,1.772-1.773s-0.793-1.771-1.772-1.771H87.729
				c-0.979,0-1.772,0.793-1.772,1.771S86.75,193.281,87.729,193.281z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M85.956,172.32c0,0.979,0.793,1.773,1.772,1.773H209.01c0.979,0,1.772-0.795,1.772-1.773
				s-0.793-1.771-1.772-1.771H87.729C86.75,170.549,85.956,171.342,85.956,172.32z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M197.967,228.109H98.893c-0.979,0-1.772,0.793-1.772,1.771s0.793,1.773,1.772,1.773h99.074
				c0.979,0,1.772-0.795,1.772-1.773S198.946,228.109,197.967,228.109z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M197.967,208.922H98.893c-0.979,0-1.772,0.795-1.772,1.773s0.793,1.771,1.772,1.771h99.074
				c0.979,0,1.772-0.793,1.772-1.771S198.946,208.922,197.967,208.922z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M111.166,79.033c-1.468,0-2.659,1.189-2.659,2.658v65.193c0,1.469,1.19,2.66,2.659,2.66h33.092
				c0.318,0,0.619-0.066,0.901-0.168c-1.106,7.008,0.398,12.973,0.498,13.354c0.307,1.172,1.369,1.967,2.574,1.967
				c0.024,0,0.05,0,0.074-0.002c1.236-0.033,2.29-0.934,2.538-2.145c0.097-0.473,2.543-11.164,24.453-11.967v3.906
				c0,1.049,0.616,1.998,1.573,2.426c0.957,0.43,2.076,0.256,2.858-0.445l18.808-16.82c0.564-0.504,0.887-1.227,0.887-1.982
				c0-0.758-0.324-1.479-0.888-1.982l-13.757-12.289c0.248,0.076,0.505,0.129,0.777,0.129c1.468,0,2.659-1.191,2.659-2.66v-19.287
				c0-1.469-1.19-2.66-2.659-2.66s-2.659,1.191-2.659,2.66v19.287c0,0.369,0.075,0.719,0.21,1.037l-3.38-3.02
				c-0.782-0.699-1.9-0.873-2.857-0.443c-0.957,0.428-1.572,1.379-1.572,2.426v5.992c-9.816,0.396-17.382,3.033-22.589,7.848
				h-29.361V65.402v-6.859h46.084v10.803c0,1.469,1.191,2.66,2.659,2.66h10.806V94.32c0,1.469,1.19,2.66,2.659,2.66
				s2.659-1.191,2.659-2.66V69.545c0.005-0.066,0.02-0.131,0.02-0.199c0-0.734-0.31-1.389-0.792-1.871
				c-0.003-0.002-0.004-0.006-0.007-0.008l-0.138-0.139c-0.017-0.014-0.029-0.033-0.046-0.047l-13.281-13.277
				c-0.113-0.113-0.237-0.211-0.366-0.301c-0.038-0.027-0.077-0.049-0.116-0.074c-0.098-0.061-0.201-0.115-0.306-0.162
				c-0.042-0.02-0.083-0.039-0.126-0.057c-0.142-0.055-0.287-0.102-0.438-0.133c-0.003,0-0.005-0.002-0.008-0.002
				c-0.153-0.029-0.31-0.043-0.468-0.047c-0.018,0-0.034-0.004-0.052-0.004h-51.402c-1.468,0-2.659,1.189-2.659,2.658v6.861h-6.861
				c-1.468,0-2.659,1.189-2.659,2.658v9.643c0,1.469,1.19,2.658,2.659,2.658s2.659-1.189,2.659-2.658v-6.982h4.202v69.301
				c0,1.469,1.19,2.66,2.659,2.66h27.733c-0.966,1.646-1.698,3.342-2.24,5.031c-0.484-0.508-1.164-0.828-1.921-0.828h-30.433V81.691
				C113.825,80.223,112.634,79.033,111.166,79.033z M174.747,62.301l4.386,4.387h-4.386V62.301z M154.279,140.814
				c4.453-5.768,12.418-8.691,23.674-8.691c1.468,0,2.659-1.191,2.659-2.66v-2.656l12.16,10.863l-12.16,10.875v-0.668
				c0-1.469-1.19-2.658-2.659-2.658c-15.517,0-23.646,4.449-27.873,8.715C150.175,149.916,151.088,144.947,154.279,140.814z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M131.771,77.703h29.91c1.468,0,2.659-1.189,2.659-2.658s-1.19-2.66-2.659-2.66h-29.91
				c-1.468,0-2.659,1.191-2.659,2.66S130.303,77.703,131.771,77.703z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M131.771,120.688c-1.468,0-2.659,1.189-2.659,2.658s1.19,2.66,2.659,2.66h29.91
				c1.468,0,2.659-1.191,2.659-2.66s-1.19-2.658-2.659-2.658H131.771z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M176.084,111.271c0-1.469-1.19-2.66-2.659-2.66h-41.654c-1.468,0-2.659,1.191-2.659,2.66
				s1.19,2.658,2.659,2.658h41.654C174.894,113.93,176.084,112.74,176.084,111.271z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M173.426,96.537h-41.654c-1.468,0-2.659,1.189-2.659,2.658s1.19,2.66,2.659,2.66h41.654
				c1.468,0,2.659-1.191,2.659-2.66S174.894,96.537,173.426,96.537z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M173.426,84.461h-41.654c-1.468,0-2.659,1.189-2.659,2.658s1.19,2.66,2.659,2.66h41.654
				c1.468,0,2.659-1.191,2.659-2.66S174.894,84.461,173.426,84.461z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconDocumentDataleak;
