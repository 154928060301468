import React from "react";
import { Box, Grid, Hidden } from "@mui/material";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import IncompleteIcon from "../../../../assets/icons/common/toggleable/IconIncomplete";
import BuyerIcon from "../../../../assets/icons/contract/toggleable/IconBuyer";
import SellerIcon from "../../../../assets/icons/contract/toggleable/IconSeller";
import LostPackageIcon from "../../../../assets/icons/contract/toggleable/IconLostPackage";
import CheckIcon from "../../../../assets/icons/common/toggleable/IconCheck";
import CrossIcon from "../../../../assets/icons/common/toggleable/IconClose";
import QuestionMarkIcon from "../../../../assets/icons/common/toggleable/IconQuestionmark";
import { requiredValidator } from "../../../../services/validationRules";
import MenuItem from "@mui/material/MenuItem";
import { translate } from "../../../../services/translations/translations";
import { useForm } from "../../../../provider/Form/FormProvider";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import Group from "../../../Wizard/Group";
import Typography from "@mui/material/Typography";
import FormLegend from "../../Common/FormLegend/FormLegend";
import NumberFieldConfigured from "../../ConfiguredFormElements/NumberFieldConfigured/NumberFieldConfigured";
import { ContractDetailsProps } from "../../../../types/ContractDetailsProps";
import wizardStyle from "../../wizardStyle";

export default function PurchaseContractDetails({ product, submitProps }: ContractDetailsProps) {
  const { values, handleSubmit } = useForm();
  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} sx={wizardStyle.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Details zu Ihrem Vertrag</strong>
              </FormLegend>
            </Grid>
            <Grid item xs={12}>
              <Group
                type="radio"
                name="buyerOrSeller"
                question={translate(`${product.name}.buyerOrSeller.question`, values)}
                options={[
                  {
                    labelText: translate(`${product.name}.buyerOrSeller.options.buyer.label`, values),
                    labelIcon: <BuyerIcon />,
                    labelIconActive: <BuyerIcon active />,
                    value: "buyer",
                  },
                  {
                    labelText: translate(`${product.name}.buyerOrSeller.options.seller.label`, values),
                    labelIcon: <SellerIcon />,
                    labelIconActive: <SellerIcon active />,
                    value: "seller",
                  },
                ]}
                validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
              />
            </Grid>
            {values.buyerOrSeller && (
              <>
                <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                  <FormLegend>{translate(`${product.name}.channel.question`, values)}</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorSelectField
                    label={translate(`${product.name}.channel.label`, values)}
                    name="channel"
                    validators={[requiredValidator("Bitte geben Sie an, wo Ihr Artikel gekauft wurde.")]}
                  >
                    <MenuItem value="amazon">{translate(`${product.name}.channel.options.amazon.label`, values)}</MenuItem>
                    <MenuItem value="ebay">{translate(`${product.name}.channel.options.ebay.label`, values)}</MenuItem>
                    <MenuItem value="kleinanzeigen">{translate(`${product.name}.channel.options.kleinanzeigen.label`, values)}</MenuItem>
                    <MenuItem value="vinted">{translate(`${product.name}.channel.options.vinted.label`, values)}</MenuItem>
                    <MenuItem value="onlineOther">{translate(`${product.name}.channel.options.onlineOther.label`, values)}</MenuItem>
                    <MenuItem value="offline">{translate(`${product.name}.channel.options.offline.label`, values)}</MenuItem>
                    <MenuItem value="fleaMarket">{translate(`${product.name}.channel.options.fleaMarket.label`, values)}</MenuItem>
                    <MenuItem value="other">{translate(`${product.name}.channel.options.other.label`, values)}</MenuItem>
                  </ValidatorSelectField>
                </Grid>

                {values.buyerOrSeller === "buyer" && values.channel !== "offline" && values.channel !== "fleaMarket" && (
                  <>
                    <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                      <FormLegend>{translate(`${product.name}.contractNumber.question`, values)}</FormLegend>
                    </Grid>
                    <Grid item xs={12}>
                      <ValidatorTextField
                        name="contractNumber"
                        label={translate(`${product.name}.contractNumber.label`, values, "Bestellnummer")}
                        helperText="Sie finden diese Nummer z.B. auf Ihren Rechnungen oder in der Kaufbestätigungs-E-Mail."
                        validators={[requiredValidator("Bitte nennen Sie uns Ihre Bestellnummer oder Anzeigennummer.")]}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                  <FormLegend>{translate(`${product.name}.itemTitle.question`, values)}</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField
                    label={translate(`${product.name}.itemTitle.label`, values)}
                    name="itemTitle"
                    validators={[requiredValidator("Bitte geben Sie hier den Namen der Ware an.")]}
                  />
                </Grid>

                {values.buyerOrSeller === "buyer" && (
                  <>
                    <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                      <FormLegend>{translate(`${product.name}.articleNumber.question`, values)}</FormLegend>
                    </Grid>
                    <Grid item xs={12}>
                      <ValidatorTextField label={"Artikelnummer"} name={"articleNumber"} />
                    </Grid>
                  </>
                )}
                <NumberFieldConfigured caseData={product} fieldPath={"purchasePrice"} fieldType={"money"} numberType={"float"} required={true} />

                <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                  <FormLegend>{translate(`${product.name}.buyerPaidStatus.question`, values)}</FormLegend>
                </Grid>

                <Grid item xs={12}>
                  <ValidatorSelectField
                    label={translate(`${product.name}.buyerPaidStatus.label`, values)}
                    name="buyerPaidStatus"
                    validators={[requiredValidator("Bitte geben Sie den Zahlstatus an")]}
                  >
                    <MenuItem value="fully">{translate(`${product.name}.buyerPaidStatus.options.fully.label`, values)}</MenuItem>
                    <MenuItem value="partially">{translate(`${product.name}.buyerPaidStatus.options.partially.label`, values)}</MenuItem>
                    <MenuItem value="none">{translate(`${product.name}.buyerPaidStatus.options.none.label`, values)}</MenuItem>
                  </ValidatorSelectField>
                </Grid>

                <Grid item xs={12}>
                  <Group
                    type="radio"
                    name="goodsOrServiceProvidedStatus"
                    question={translate(`${product.name}.goodsOrServiceProvidedStatus.question`, values)}
                    options={[
                      {
                        labelText: translate(`${product.name}.goodsOrServiceProvidedStatus.options.fully.label`, values),
                        labelIcon: <CheckIcon />,
                        labelIconActive: <CheckIcon active />,
                        value: "fully",
                      },
                      {
                        labelText: translate(`${product.name}.goodsOrServiceProvidedStatus.options.partially.label`, values),
                        labelIcon: <IncompleteIcon />,
                        labelIconActive: <IncompleteIcon active />,
                        value: "partially",
                      },
                      {
                        labelText: translate(`${product.name}.goodsOrServiceProvidedStatus.options.none.label`, values),
                        labelIcon: <CrossIcon />,
                        labelIconActive: <CrossIcon active />,
                        value: "none",
                      },
                    ]}
                    validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
                  />
                </Grid>
                {values.goodsOrServiceProvidedStatus !== "none" && (
                  <>
                    <Grid item xs={12}>
                      <Group
                        type="radio"
                        name="locationOfGood"
                        question={translate(`${product.name}.locationOfGood.question`, values)}
                        options={[
                          {
                            labelText: translate(`${product.name}.locationOfGood.options.buyer.label`, values),
                            labelIcon: <BuyerIcon />,
                            labelIconActive: <BuyerIcon active />,
                            value: "buyer",
                          },
                          {
                            labelText: translate(`${product.name}.locationOfGood.options.seller.label`, values),
                            labelIcon: <SellerIcon />,
                            labelIconActive: <SellerIcon active />,
                            value: "seller",
                          },
                          {
                            labelText: translate(`${product.name}.locationOfGood.options.elsewhere.label`, values),
                            labelIcon: <LostPackageIcon />,
                            labelIconActive: <LostPackageIcon active />,
                            value: "elsewhere",
                          },
                          {
                            labelText: translate(`${product.name}.locationOfGood.options.unknown.label`, values),
                            labelIcon: <QuestionMarkIcon />,
                            labelIconActive: <QuestionMarkIcon active />,
                            value: "unknown",
                          },
                        ]}
                        validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Group
                        type="radio"
                        name="hasDefects"
                        question={translate(`${product.name}.hasDefects.question`, values)}
                        options={[
                          {
                            labelText: translate(`${product.name}.hasDefects.options.yes.label`, values),
                            labelIcon: <CheckIcon />,
                            labelIconActive: <CheckIcon active />,
                            value: "yes",
                          },
                          {
                            labelText: translate(`${product.name}.hasDefects.options.no.label`, values),
                            labelIcon: <CrossIcon />,
                            labelIconActive: <CrossIcon active />,
                            value: "no",
                          },
                        ]}
                        validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
                      />
                    </Grid>

                    {values.hasDefects === "yes" && (
                      <>
                        <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                          <FormLegend>{translate(`${product.name}.defectsDescriptionClient.question`, values)}</FormLegend>
                        </Grid>
                        <Grid item xs={12}>
                          <ValidatorTextField
                            label={translate(`${product.name}.defectsDescriptionClient.label`, values)}
                            name="defectsDescriptionClient"
                            validators={[requiredValidator("Bitte beschreiben Sie die Mängel.")]}
                            multiline
                            rows={6}
                            helperText={
                              "Beschreiben Sie möglichst detailliert, damit wir eine gute Grundlage für das rechtliche Vorgehen haben und führen Sie mehrere Mängel in einer Liste mit Spiegelstrichen auf."
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                          <FormLegend>{translate(`${product.name}.numberRepairAttempts.question`, values)}</FormLegend>
                        </Grid>
                        <Grid item xs={12}>
                          <ValidatorSelectField
                            label={translate(`${product.name}.numberRepairAttempts.label`, values)}
                            name="numberRepairAttempts"
                            validators={[requiredValidator("Bitte geben Sie die Anzahl der Ausbesserungsversuche an")]}
                          >
                            <MenuItem value="0">{translate(`${product.name}.numberRepairAttempts.options.0.label`, values)}</MenuItem>
                            <MenuItem value="1">{translate(`${product.name}.numberRepairAttempts.options.1.label`, values)}</MenuItem>
                            <MenuItem value="2">{translate(`${product.name}.numberRepairAttempts.options.2.label`, values)}</MenuItem>
                            <MenuItem value="3">{translate(`${product.name}.numberRepairAttempts.options.3.label`, values)}</MenuItem>
                          </ValidatorSelectField>
                        </Grid>
                        <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                          <FormLegend>{translate(`${product.name}.numberReplacementAttempts.question`, values)}</FormLegend>
                        </Grid>
                        <Grid item xs={12}>
                          <ValidatorSelectField
                            label={translate(`${product.name}.numberReplacementAttempts.label`, values)}
                            name="numberReplacementAttempts"
                            validators={[requiredValidator("Bitte geben Sie die Anzahl der Neulieferungsversuche an")]}
                          >
                            <MenuItem value="0">{translate(`${product.name}.numberReplacementAttempts.options.0.label`, values)}</MenuItem>
                            <MenuItem value="1">{translate(`${product.name}.numberReplacementAttempts.options.1.label`, values)}</MenuItem>
                            <MenuItem value="2">{translate(`${product.name}.numberReplacementAttempts.options.2.label`, values)}</MenuItem>
                            <MenuItem value="3">{translate(`${product.name}.numberReplacementAttempts.options.3.label`, values)}</MenuItem>
                          </ValidatorSelectField>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
