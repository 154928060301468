import React from "react";
import * as PropTypes from "prop-types";
import familyLawTeaserCardSectionStyle from "./familyLawTeaserCardSectionStyle";
import iconDivorce from "../../../../assets/icons/familyLaw/IconDocumentSignpost";
import iconAlimony from "../../../../assets/icons/familyLaw/IconCalculator";
import iconInheritance from "../../../../assets/icons/familyLaw/IconDeath";
import iconFamily from "../../../../assets/icons/familyLaw/IconFamily";
import Container from "../../../Container/Container";
import Typography from "@mui/material/Typography";
import LinkedCardBox from "../../../LinkedCardBox/LinkedCardBox";

const FamilyLawTeaserCardSection = ({ heading, partner }) => {
  const teaserCards = [
    {
      image: iconDivorce,
      heading: "Trennung / Scheidung",
      link: `/lp/${partner}/scheidung`,
    },
    {
      image: iconAlimony,
      heading: "Unterhalt",
      link: `/lp/${partner}/unterhalt`,
    },
    {
      image: iconInheritance,
      heading: "Erbe / Todesfall",
      link: `/lp/${partner}/todesfall`,
    },
    {
      image: iconFamily,
      heading: "Anderes Thema",
      link: `/lp/${partner}/familienrecht-beratung`,
    },
  ];

  return (
    <Container sx={familyLawTeaserCardSectionStyle.container}>
      {heading && (
        <Typography variant={"h2"} sx={familyLawTeaserCardSectionStyle.heading}>
          {heading}
        </Typography>
      )}
      <LinkedCardBox linkedCards={teaserCards} />
    </Container>
  );
};

FamilyLawTeaserCardSection.propTypes = {
  heading: PropTypes.string,
  partner: PropTypes.string.isRequired,
};

export default FamilyLawTeaserCardSection;
