import React from "react";
import SummaryDetailsItem from "../../../Common/SummaryDetailsItem/SummaryDetailsItem";
import SummaryDetailsProps from "../../../../../types/SummaryDetailsProps";

const SummaryDetailsSubscription = ({ caseData, formUrl }: SummaryDetailsProps) => {
  return (
    <>
      <SummaryDetailsItem fieldPath={"wasDeceived"} formUrl={formUrl} caseData={caseData} />
      <SummaryDetailsItem fieldPath={"salesChannel"} formUrl={formUrl} caseData={caseData} />
      <SummaryDetailsItem fieldPath={"subscriptionName"} formUrl={formUrl} caseData={caseData} />
      <SummaryDetailsItem fieldPath={"contractNumber"} formUrl={formUrl} caseData={caseData} />
    </>
  );
};

export default SummaryDetailsSubscription;
