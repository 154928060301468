import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconCourtScales = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50">
        <g>
          <path
            style={{ fill: "none" }}
            d="M154.973,62.099c0-2.739-2.229-4.968-4.969-4.968c-2.748,0-4.983,2.229-4.983,4.968
			c0,2.75,2.235,4.986,4.983,4.986C152.744,67.085,154.973,64.848,154.973,62.099z"
          />
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M66.923,208.261c14.227,0,24.962-6.794,31.906-20.195c3.352-6.468,4.995-12.959,5.757-16.929
				H29.233C31.137,181.404,38.805,208.261,66.923,208.261z"
            />
            <path style={{ fill: "#137F7B" }} d="M195.39,171.137c1.903,10.267,9.571,37.124,37.688,37.124s35.785-26.853,37.688-37.124H195.39z" />
            <path
              style={{ fill: "#137F7B" }}
              d="M152.169,43.41c-0.712-0.082-1.432-0.135-2.166-0.135s-1.454,0.053-2.166,0.134
				c-8.661,0.997-15.547,7.878-16.542,16.533c-0.081,0.709-0.133,1.426-0.133,2.157c0,0.737,0.053,1.46,0.136,2.176
				c1,8.652,7.878,15.53,16.53,16.531c0.715,0.083,1.438,0.136,2.174,0.136s1.459-0.053,2.174-0.136
				c8.648-1.001,15.522-7.883,16.519-16.54c0.082-0.712,0.134-1.433,0.134-2.166c0-0.727-0.051-1.441-0.132-2.147
				C167.71,51.293,160.828,44.407,152.169,43.41z M150.004,70.634c-4.705,0-8.532-3.829-8.532-8.535
				c0-4.696,3.828-8.516,8.532-8.516c4.696,0,8.518,3.82,8.518,8.516C158.521,66.805,154.7,70.634,150.004,70.634z"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M258.142,129.104l12.049,20.731c0.659,1.134,1.848,1.766,3.071,1.766
				c0.605,0,1.219-0.155,1.78-0.481c1.694-0.985,2.27-3.157,1.285-4.851l-12.049-20.731c-0.985-1.694-3.156-2.27-4.851-1.285
				C257.732,125.238,257.157,127.41,258.142,129.104z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M153.545,234.062v-48.047c0-1.96-1.588-3.549-3.548-3.549s-3.548,1.589-3.548,3.549v48.047
				c0,1.96,1.588,3.549,3.548,3.549S153.545,236.022,153.545,234.062z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M24.963,151.121c1.694,0.984,3.866,0.41,4.851-1.285l37.109-63.856l37.113,63.856
				c0.659,1.133,1.849,1.766,3.071,1.766c0.605,0,1.219-0.155,1.78-0.481c1.694-0.985,2.27-3.157,1.285-4.851L72.9,82.14
				c14.151-1.319,42.904-11.551,53.39-15.419c1.94,9.979,10.038,17.784,20.166,19.281v87.49c0,1.959,1.588,3.548,3.548,3.548
				s3.548-1.589,3.548-3.548V86.001c10.123-1.498,18.215-9.304,20.153-19.284c10.479,3.866,39.245,14.104,53.396,15.422
				l-37.268,64.13c-0.985,1.694-0.41,3.866,1.285,4.851c0.561,0.326,1.174,0.481,1.779,0.481c1.223,0,2.413-0.633,3.071-1.766
				l37.109-63.855l20.191,34.743c0.984,1.694,3.156,2.271,4.851,1.285c1.695-0.985,2.27-3.157,1.285-4.851L238.003,80.33
				c8.919-4.035,12.85-12.335,13.042-12.752c0.821-1.78,0.044-3.888-1.735-4.709s-3.888-0.044-4.709,1.735
				c-0.041,0.088-4.177,8.836-13.554,10.51c-7.37,1.316-38.368-8.846-57.068-15.866c-1.277-10.806-9.715-19.448-20.427-21.033
				V20.887c0-1.96-1.588-3.549-3.548-3.549s-3.548,1.589-3.548,3.549v17.328c-10.72,1.583-19.164,10.228-20.441,21.037
				c-18.701,7.021-49.692,17.181-57.062,15.862c-9.24-1.65-13.49-10.383-13.557-10.523c-0.826-1.77-2.928-2.541-4.703-1.722
				c-1.779,0.821-2.556,2.929-1.735,4.709c0.192,0.417,4.123,8.716,13.04,12.752l-38.32,65.94
				C22.693,147.964,23.269,150.136,24.963,151.121z M147.838,43.409c0.712-0.082,1.433-0.134,2.166-0.134s1.454,0.053,2.166,0.135
				c8.658,0.997,15.541,7.883,16.53,16.542c0.081,0.706,0.132,1.42,0.132,2.147c0,0.733-0.052,1.454-0.134,2.166
				c-0.996,8.657-7.871,15.539-16.519,16.54c-0.715,0.083-1.438,0.136-2.174,0.136s-1.459-0.053-2.174-0.136
				c-8.652-1-15.53-7.878-16.53-16.531c-0.083-0.715-0.136-1.439-0.136-2.176c0-0.73,0.052-1.448,0.133-2.157
				C132.291,51.287,139.177,44.406,147.838,43.409z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M112.251,167.911c0.091-0.993-0.241-1.979-0.913-2.715s-1.624-1.156-2.621-1.156H25.127
				c-0.997,0-1.949,0.42-2.621,1.156c-0.672,0.737-1.003,1.723-0.913,2.716c0.043,0.474,4.805,47.446,45.33,47.446
				C107.451,215.358,112.208,168.385,112.251,167.911z M66.923,208.261c-28.118,0-35.786-26.857-37.689-37.124h75.353
				c-0.762,3.969-2.405,10.461-5.757,16.929C91.885,201.466,81.15,208.261,66.923,208.261z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M25.127,161.292h83.591c1.96,0,3.548-1.588,3.548-3.548s-1.588-3.548-3.548-3.548H25.127
				c-1.96,0-3.548,1.588-3.548,3.548S23.167,161.292,25.127,161.292z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M137.619,237.6c1.96,0,3.548-1.589,3.548-3.549c0-1.959-1.588-3.548-3.548-3.548
				c-17.879,0-37.475,4.329-47.245,36.43c-0.328,1.076-0.126,2.243,0.543,3.146c0.669,0.903,1.727,1.436,2.851,1.436h112.467
				c1.124,0,2.182-0.533,2.851-1.436c0.669-0.903,0.871-2.07,0.543-3.146c-9.771-32.102-29.367-36.43-47.248-36.43
				c-1.96,0-3.548,1.589-3.548,3.548c0,1.96,1.588,3.549,3.548,3.549c15.115,0,30.195,3.375,38.916,26.818H98.706
				C107.427,240.975,122.505,237.6,137.619,237.6z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M274.873,164.04h-83.59c-0.997,0-1.949,0.42-2.621,1.156c-0.672,0.737-1.004,1.722-0.913,2.715
				c0.043,0.475,4.803,47.447,45.329,47.447c40.528,0,45.285-46.973,45.329-47.447c0.091-0.993-0.241-1.979-0.913-2.715
				S275.87,164.04,274.873,164.04z M233.078,208.261c-28.118,0-35.786-26.857-37.688-37.124h75.377
				C268.863,181.408,261.196,208.261,233.078,208.261z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M191.283,154.195c-1.96,0-3.548,1.588-3.548,3.548s1.588,3.548,3.548,3.548h83.59
				c1.96,0,3.548-1.588,3.548-3.548s-1.588-3.548-3.548-3.548H191.283z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M209.659,279.113H88.519c-0.98,0-1.774,0.794-1.774,1.774s0.794,1.774,1.774,1.774h121.141
				c0.98,0,1.774-0.794,1.774-1.774S210.639,279.113,209.659,279.113z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M150.004,70.634c4.696,0,8.518-3.829,8.518-8.535c0-4.696-3.821-8.516-8.518-8.516
				c-4.705,0-8.532,3.82-8.532,8.516C141.472,66.805,145.299,70.634,150.004,70.634z M150.004,57.131
				c2.74,0,4.969,2.229,4.969,4.968c0,2.75-2.229,4.986-4.969,4.986c-2.748,0-4.983-2.237-4.983-4.986
				C145.021,59.359,147.256,57.131,150.004,57.131z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconCourtScales;
