import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconCalculator = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7">
        <g>
          <path
            style={{ fill: "#137F7B" }}
            d="M165.079,253.746v2.446c0,1.785-1.447,3.231-3.231,3.231h-23.696c-1.785,0-3.231-1.447-3.231-3.231
			v-23.695c0-1.328,0.803-2.467,1.948-2.963c-4.479-4.866-8.679-9.981-12.614-15.278c-0.402,0.18-0.845,0.284-1.314,0.284H99.244
			c-1.785,0-3.231-1.447-3.231-3.231v-23.696c0-1.785,1.447-3.231,3.231-3.231h6.241c-2.657-4.983-5.141-10.002-7.452-15.01h-5.901
			c-1.785,0-3.231-1.447-3.231-3.231v-18.599c-4.226-11.154-7.662-21.869-10.438-31.566v153.251c0,4.157,3.382,7.539,7.539,7.539
			h127.995c2.419,0,4.57-1.149,5.951-2.926C198.954,271.283,180.783,264.054,165.079,253.746z M126.171,256.192
			c0,1.785-1.447,3.231-3.231,3.231H99.244c-1.785,0-3.231-1.447-3.231-3.231v-23.695c0-1.785,1.447-3.231,3.231-3.231h23.695
			c1.785,0,3.231,1.447,3.231,3.231V256.192z"
          />
          <g>
            <path
              style={{ fill: "#FFFFFF" }}
              d="M213.997,71.399h-12.974v31.867h6.84c1.785,0,3.231,1.447,3.231,3.231s-1.447,3.231-3.231,3.231
				h-10.071h-95.584H92.137c-1.785,0-3.231-1.447-3.231-3.231s1.447-3.231,3.231-3.231h6.84V71.399H86.002
				c-4.157,0-7.539,3.382-7.539,7.539v37.036c2.776,9.697,6.211,20.412,10.438,31.566v-17.742c0-1.785,1.447-3.231,3.231-3.231
				h115.735c1.785,0,3.231,1.447,3.231,3.231v36.341c0,1.785-1.447,3.231-3.231,3.231h-24.096c-1.785,0-3.231-1.447-3.231-3.231
				s1.447-3.231,3.231-3.231h20.864V133.03H95.363v29.878h72.21c1.785,0,3.231,1.447,3.231,3.231s-1.447,3.231-3.231,3.231h-69.54
				c2.312,5.008,4.795,10.026,7.452,15.01h17.454c1.785,0,3.231,1.447,3.231,3.231v23.696c0,1.315-0.789,2.443-1.917,2.947
				c3.936,5.296,8.135,10.412,12.614,15.278c0.394-0.171,0.827-0.268,1.284-0.268h23.696c1.785,0,3.231,1.447,3.231,3.231v21.249
				c15.704,10.308,33.875,17.537,54.869,20.092c0.992-1.277,1.589-2.875,1.589-4.613V78.938
				C221.537,74.781,218.155,71.399,213.997,71.399z M165.079,211.309c0,1.785-1.447,3.231-3.231,3.231h-23.696
				c-1.785,0-3.231-1.447-3.231-3.231v-23.696c0-1.785,1.447-3.231,3.231-3.231h23.696c1.785,0,3.231,1.447,3.231,3.231V211.309z
				 M203.987,256.192c0,1.785-1.447,3.231-3.231,3.231H177.06c-1.785,0-3.231-1.447-3.231-3.231v-23.695
				c0-1.785,1.447-3.231,3.231-3.231h23.696c1.785,0,3.231,1.447,3.231,3.231V256.192z M203.987,211.309
				c0,1.785-1.447,3.231-3.231,3.231H177.06c-1.785,0-3.231-1.447-3.231-3.231v-23.696c0-1.785,1.447-3.231,3.231-3.231h23.696
				c1.785,0,3.231,1.447,3.231,3.231V211.309z"
            />
            <path
              style={{ fill: "#FFFFFF" }}
              d="M194.561,71.399v-3.231v-3.231V23.23h-89.121v41.706v3.231v3.231v31.867h89.121V71.399z
				 M154.003,93.583c-9.491,0-18.861-9.858-22.071-20.657h-6.36v-1.526v-3.231v-3.231v-0.709h5.194v-3.044h-5.194v-8.699h6.705
				c3.496-10.364,12.554-19.57,21.726-19.57c11.71,0,16.123,0,16.123,0l-1.958,9.785c0,0-6.523,0-11.742,0
				c-3.67,0-9.703,3.769-12.962,9.785h22.004l-2.438,8.699h-21.825v3.044h21.825l-0.199,0.709l-0.906,3.231l-0.906,3.231
				l-0.428,1.526h-17.676c3.085,6.647,9.624,10.872,13.511,10.872c5.219,0,11.742,0,11.742,0l1.958,9.785
				C170.126,93.583,165.713,93.583,154.003,93.583z"
            />
            <polygon
              style={{ fill: "#FFFFFF" }}
              points="102.476,190.844 102.476,208.077 119.708,208.077 119.708,207.895 119.708,190.844
				109.046,190.844 			"
            />
            <rect x="141.383" y="190.844" style={{ fill: "#FFFFFF" }} width="17.233" height="17.233" />
            <rect x="180.291" y="190.844" style={{ fill: "#FFFFFF" }} width="17.233" height="17.233" />
            <rect x="102.476" y="235.729" style={{ fill: "#FFFFFF" }} width="17.232" height="17.232" />
            <polygon
              style={{ fill: "#FFFFFF" }}
              points="141.383,235.729 141.383,252.961 158.616,252.961 158.616,249.233 158.616,235.729
				142.866,235.729 			"
            />
            <rect x="180.291" y="235.729" style={{ fill: "#FFFFFF" }} width="17.233" height="17.232" />
          </g>
          <g>
            <path
              style={{ fill: "#333333" }}
              d="M213.997,64.937h-12.974V19.999c0-1.785-1.447-3.231-3.231-3.231h-95.584
				c-1.785,0-3.231,1.447-3.231,3.231v44.938H86.002C78.281,64.937,72,71.218,72,78.938v190.287c0,7.721,6.281,14.002,14.002,14.002
				h127.995c7.721,0,14.003-6.281,14.003-14.002V78.938C228,71.218,221.718,64.937,213.997,64.937z M105.439,71.399v-3.231v-3.231
				V23.23h89.121v41.706v3.231v3.231v31.867h-89.121V71.399z M221.537,269.226c0,1.738-0.597,3.336-1.589,4.613
				c-1.381,1.777-3.532,2.926-5.951,2.926H86.002c-4.157,0-7.539-3.382-7.539-7.539V115.975V78.938c0-4.157,3.382-7.539,7.539-7.539
				h12.975v31.867h-6.84c-1.785,0-3.231,1.447-3.231,3.231s1.447,3.231,3.231,3.231h10.071h95.584h10.071
				c1.785,0,3.231-1.447,3.231-3.231s-1.447-3.231-3.231-3.231h-6.84V71.399h12.974c4.158,0,7.54,3.382,7.54,7.539V269.226z"
            />
            <path
              style={{ fill: "#333333" }}
              d="M207.867,126.567H92.132c-1.785,0-3.231,1.447-3.231,3.231v17.742v18.599
				c0,1.785,1.447,3.231,3.231,3.231h5.901h69.54c1.785,0,3.231-1.447,3.231-3.231s-1.447-3.231-3.231-3.231h-72.21V133.03h109.272
				v29.878h-20.864c-1.785,0-3.231,1.447-3.231,3.231s1.447,3.231,3.231,3.231h24.096c1.785,0,3.231-1.447,3.231-3.231v-36.341
				C211.099,128.014,209.652,126.567,207.867,126.567z"
            />
            <path
              style={{ fill: "#333333" }}
              d="M122.939,184.381h-17.454h-6.241c-1.785,0-3.231,1.447-3.231,3.231v23.696
				c0,1.785,1.447,3.231,3.231,3.231h23.695c0.469,0,0.912-0.104,1.314-0.284c1.128-0.504,1.917-1.632,1.917-2.947v-23.696
				C126.171,185.828,124.724,184.381,122.939,184.381z M119.708,207.895v0.182h-17.232v-17.233h6.57h10.662V207.895z"
            />
            <path
              style={{ fill: "#333333" }}
              d="M161.848,184.381h-23.696c-1.785,0-3.231,1.447-3.231,3.231v23.696
				c0,1.785,1.447,3.231,3.231,3.231h23.696c1.785,0,3.231-1.447,3.231-3.231v-23.696
				C165.079,185.828,163.632,184.381,161.848,184.381z M158.616,208.077h-17.233v-17.233h17.233V208.077z"
            />
            <path
              style={{ fill: "#333333" }}
              d="M200.756,184.381H177.06c-1.785,0-3.231,1.447-3.231,3.231v23.696
				c0,1.785,1.447,3.231,3.231,3.231h23.696c1.785,0,3.231-1.447,3.231-3.231v-23.696
				C203.987,185.828,202.541,184.381,200.756,184.381z M197.524,208.077h-17.233v-17.233h17.233V208.077z"
            />
            <path
              style={{ fill: "#333333" }}
              d="M156.426,42.699c5.219,0,11.742,0,11.742,0l1.958-9.785c0,0-4.413,0-16.123,0
				c-9.172,0-18.229,9.206-21.726,19.57h-6.705v8.699h5.194v3.044h-5.194v0.709v3.231v3.231v1.526h6.36
				c3.21,10.799,12.58,20.657,22.071,20.657c11.71,0,16.123,0,16.123,0l-1.958-9.785c0,0-6.523,0-11.742,0
				c-3.887,0-10.426-4.225-13.511-10.872h17.676l0.428-1.526l0.906-3.231l0.906-3.231l0.199-0.709h-21.825v-3.044h21.825
				l2.438-8.699h-22.004C146.723,46.468,152.755,42.699,156.426,42.699z"
            />
            <path
              style={{ fill: "#333333" }}
              d="M122.939,229.266H99.244c-1.785,0-3.231,1.447-3.231,3.231v23.695
				c0,1.785,1.447,3.231,3.231,3.231h23.695c1.785,0,3.231-1.447,3.231-3.231v-23.695
				C126.171,230.712,124.724,229.266,122.939,229.266z M119.708,252.961h-17.232v-17.232h17.232V252.961z"
            />
            <path
              style={{ fill: "#333333" }}
              d="M161.848,229.266h-23.696c-0.457,0-0.89,0.097-1.284,0.268c-1.145,0.497-1.948,1.635-1.948,2.963
				v23.695c0,1.785,1.447,3.231,3.231,3.231h23.696c1.785,0,3.231-1.447,3.231-3.231v-2.446v-21.249
				C165.079,230.712,163.632,229.266,161.848,229.266z M158.616,249.233v3.728h-17.233v-17.232h1.483h15.75V249.233z"
            />
            <path
              style={{ fill: "#333333" }}
              d="M200.756,229.266H177.06c-1.785,0-3.231,1.447-3.231,3.231v23.695
				c0,1.785,1.447,3.231,3.231,3.231h23.696c1.785,0,3.231-1.447,3.231-3.231v-23.695
				C203.987,230.712,202.541,229.266,200.756,229.266z M197.524,252.961h-17.233v-17.232h17.233V252.961z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconCalculator;
