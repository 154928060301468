import {
  STATUS_ACTION_REQUIRED,
  STATUS_CHECKED,
  STATUS_COMPLETED,
  STATUS_CREATED,
  STATUS_DATA_COMPLETED,
  STATUS_DATA_COMPLETED_LAWFIRM, STATUS_EVALUATE_JUDGEMENT,
  STATUS_EXTRAJUDICIAL_COMMUNICATION_SENT,
  STATUS_EXTRAJUDICIAL_REACTION_RECEIVED,
  STATUS_IN_PROGRESS,
  STATUS_LAWSUIT,
  STATUS_LAWSUIT_COMPLETED,
  STATUS_PREPARE_LAWSUIT,
  STATUS_TRIAL,
  STATUS_WAITING_FOR_FEEDBACK,
} from "../contractLaw/leadStatus";
import { APPLICATION_STATUS_SUMMARY } from "../contractLaw/applicationStatus";
import moment from "moment";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import { getCaseLink, getProductData } from "../productService";
import _get from "lodash/get";
import { Stack, Typography } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { AbstractCase } from "../../types/Entities/AbstractCase";
import {getContent as getContentContractLaw, getPercentage} from "../contractLaw/statusBoxSteps";

const getContent = (rentalContractCase: AbstractCase) => {
  const appointmentStatus = _get(rentalContractCase, "advisoryAppointment.status");
  const appointmentDate =
    _get(rentalContractCase, "advisoryAppointment.appointment.date") && moment(_get(rentalContractCase, "advisoryAppointment.appointment.date"));

  switch (rentalContractCase.caseCategory) {
    case "advisory":
      switch (rentalContractCase.leadStatus) {
        case STATUS_CREATED:
          return {
            header: "Fall schildern und Vorgehen festlegen",
            text: "Sie entscheiden, ob Sie nur eine kurze Beratung benötigen, oder wir direkt für Sie tätig werden sollen - zum Beispiel durch ein Schreiben an die Gegenseite. Sie wünschen eine rechtliche Vertretung durch uns? Dann können Sie hier auch direkt Ihre Vollmacht unterzeichnen.",
            buttonTarget: getCaseLink(rentalContractCase) + "/antrag",
            buttonLabel: "Weiteres Vorgehen festlegen",
          };
        case STATUS_DATA_COMPLETED:
        case STATUS_WAITING_FOR_FEEDBACK:
          return {
            header: "Jetzt Termin vereinbaren",
            text: "Vielen Dank für die Schilderung Ihres Falls. Sie können jetzt einen Termin für eine Erstberatung vereinbaren",
            buttonTarget: getCaseLink(rentalContractCase) + rentalContractCase.calendlyLink,
            buttonLabel: "Termin vereinbaren",
          };
        case STATUS_CHECKED:
          return {
            header: "Jetzt Termin vereinbaren",
            text: "Vielen Dank für die Schilderung Ihres Falls. Sie können jetzt einen Termin für eine Erstberatung vereinbaren",
            buttonTarget: getCaseLink(rentalContractCase) + rentalContractCase.calendlyLink,
            buttonLabel: "Termin vereinbaren",
          };
        case STATUS_IN_PROGRESS:
          if (appointmentStatus === "not_processed") {
            return {
              header: "Ihr Termin konnte nicht stattfinden",
              text: `Sie hatten einen Termin für ein Erstgespräch am ${
                appointmentDate && appointmentDate.format("DD.MM.YYYY")
              } gebucht. Der Termin konnte nicht stattfinden. Buchen Sie sich gerne einen neuen Termin.`,
              buttonTarget: getCaseLink(rentalContractCase) + rentalContractCase.calendlyLink,
              buttonLabel: "Neuen Termin buchen",
            };
          }
          return {
            header: "Termin erfolgreich gebucht",
            text:
              `Sie haben einen Termin für ein Erstgespräch am ${appointmentDate && appointmentDate.format("DD.MM.YYYY")} gebucht.` +
              ` Ihr Rechtsanwalt wird Sie um ${appointmentDate && appointmentDate.format("HH:mm")} Uhr anrufen.`,
          };
        case STATUS_COMPLETED:
          return {
            header: "Ihr Fall ist abgeschlossen",
            text: "Vielen Dank für Ihr Vertrauen und dafür, dass wir Sie unterstützen durften! Sie sind der Meinung, dass ihr Fall nicht abgeschlossen ist oder es sind im Nachgang doch noch Probleme aufgetreten? Dann schreiben Sie uns gerne jederzeit eine Nachricht und wir werden selbstverständlich umgehend für Sie tätig!",
          };
        default:
          return {
            header: "Technischer Fehler",
            text: "Leider kam es scheinbar zu einem technischen Fehler in Ihrem Fall. Bitte melden Sie sich per E-Mail an service@legalbird.de, damit wir Ihnen zeitnah weiterhelfen können.",
          };
      }
    default:
      switch (rentalContractCase.leadStatus) {
        case STATUS_CREATED:
          if (rentalContractCase.applicationStatus > APPLICATION_STATUS_SUMMARY) {
            return {
              header: "Ihre Vollmacht wird benötigt",
              text: "Um Ihren Fall zu prüfen und Ihr Anliegen als Ihr gesetzlicher Vertreter durchsetzen zu können, benötigen wir noch Ihre Vollmacht.",
              buttonTarget: getCaseLink(rentalContractCase) + "/antrag",
              buttonLabel: "Zur Vollmacht",
            };
          }
          return {
            header: "Fall schildern und Vorgehen festlegen",
            text: "Sie entscheiden, ob Sie nur eine kurze Beratung benötigen, oder wir direkt für Sie tätig werden sollen - zum Beispiel durch ein Schreiben an die Gegenseite. Sie wünschen eine rechtliche Vertretung durch uns? Dann können Sie hier auch direkt Ihre Vollmacht unterzeichnen.",
            buttonTarget: getCaseLink(rentalContractCase) + "/antrag",
            buttonLabel: "Weiteres Vorgehen festlegen",
          };
        case STATUS_DATA_COMPLETED:
        case STATUS_WAITING_FOR_FEEDBACK:
          return {
            header: "Ihre Angaben sind vollständig",
            text: "Vielen Dank für die Schilderung Ihres Falls. Wir prüfen nun Ihren Fall und leiten zeitnah weitere Schritte für Sie ein!",
          };
        case STATUS_DATA_COMPLETED_LAWFIRM:
          return {
            header: "Ihre Angaben sind vollständig",
            text: "Vielen Dank für die Schilderung Ihres Falls. Wir prüfen nun Ihren Fall und leiten zeitnah weitere Schritte für Sie ein!",
          };
        case STATUS_CHECKED:
          return {
            header: "Ihr Fall wurde geprüft",
            text: "Wir haben Ihren Fall geprüft und leiten jetzt weitere Schritte ein. Durch Sie ist nun zunächst nichts weiter zu veranlassen. Sie werden selbstverständlich immer direkt informiert, wenn es Neuigkeiten zu Ihrem Fall gibt!",
          };
        case STATUS_IN_PROGRESS:
          return {
            header: "Ihr Fall wird bearbeitet",
            text: "Wir setzen uns dafür ein, dass Ihr Anliegen zeitnah und professionell geklärt wird. Sie werden jederzeit von uns über Neuigkeiten informiert.",
          };
        case STATUS_EXTRAJUDICIAL_COMMUNICATION_SENT:
          return {
            header: "Schreiben an die Gegenseite",
            text:
              "Wir haben ein Schreiben an Ihren Vermieter geschickt und informieren Sie umgehend, sobald der Vermieter " +
              "auf das Schreiben reagiert. Sollte keine Reaktion stattfinden, kommen wir auf Sie zu, um Ihre Möglichkeiten" +
              " zu besprechen.",
          };
        case STATUS_EXTRAJUDICIAL_REACTION_RECEIVED:
        case STATUS_ACTION_REQUIRED:
          return !!rentalContractCase.finalClaimStatusClient
            ? {
                header: "Die Reaktion des Vermieters liegt vor",
                text: "Wir haben Ihre Rückmeldung erhalten und werden Ihren Fall entsprechend weiter bearbeiten. Sie werden über das weitere Vorgehen wie gewohnt per Nachricht und Ihre Servicewelt informiert.",
              }
            : {
                header: "Die Reaktion des Vermieters liegt vor",
                text: "Bitte prüfen Sie hier die Reaktion des Vermieters und teilen Sie uns mit, wie Sie weiter vorgehen möchten.",
                buttonTarget: getCaseLink(rentalContractCase) + "/rueckmeldung",
                buttonLabel: "Vorgehen festlegen",
              };
        case STATUS_PREPARE_LAWSUIT:
          return {
            header: "Ihre Klage wird vorbereitet",
            text: "Ihrerseits ist derzeit nichts weiter zu tun. Wir melden uns bei Neuigkeiten und Klärungsbedarf direkt bei Ihnen.",
          };
        case STATUS_LAWSUIT:
          return {
            header: "Gerichtsverfahren läuft",
            text: "Ihr Fall befindet sich im gerichtlichen Verfahren. Wir kümmern uns um alles Weitere und melden uns bei Neuigkeiten und Klärungsbedarf immer direkt bei Ihnen.",
          };
        case STATUS_TRIAL:
          return rentalContractCase.courtAppointment?.courtDate?.appointmentDateTime
            ? {
                header: "Ihr Gerichtstermin steht fest",
                text: "Das Gericht hat den Termin für Ihr Verfahren festgesetzt. Weitere Informationen können Sie dem Schreiben entnehmen, das Sie in Ihren Dokumenten finden. Melden Sie sich bei Fragen gerne direkt bei uns!",
              }
            : {
                header: "Warten auf Gerichtstermin",
                text: "Es wird auf ein Ladungsschreiben für Ihren Gerichtstermin gewartet. Sobald dieses eingeht, werden Sie es hier in der Servicewelt finden können.",
              };
        case STATUS_EVALUATE_JUDGEMENT:
          return getContentContractLaw(rentalContractCase);
        case STATUS_LAWSUIT_COMPLETED:
          return {
            header: "Der Rechtsstreit wurde beendet",
            text: "Ihr Vertragsrechtsfall wurde im gerichtlichen Verfahren entschieden. Wenn keine Partei das Ergebnis anfechtet, ist es rechtskräftig und Sie und die Gegenpartei müssen der Entscheidung des Gerichts Folge leisten.",
          };
        case STATUS_COMPLETED:
          return {
            header: "Ihr Fall ist abgeschlossen",
            text: "Vielen Dank für Ihr Vertrauen und dafür, dass wir Sie unterstützen durften! Sie sind der Meinung, dass ihr Fall nicht abgeschlossen ist oder es sind im Nachgang doch noch Probleme aufgetreten? Dann schreiben Sie uns gerne jederzeit eine Nachricht und wir werden selbstverständlich umgehend für Sie tätig!",
          };
        default:
          return {
            header: "Technischer Fehler",
            text: "Leider kam es scheinbar zu einem technischen Fehler in Ihrem Fall. Bitte melden Sie sich per E-Mail an service@legalbird.de, damit wir Ihnen zeitnah weiterhelfen können.",
          };
      }
  }
};

const getButton = (rentalContractCase: AbstractCase) => {
  const content: Record<string, any> = getContent(rentalContractCase);
  if (!content.buttonLabel) {
    return null;
  }
  return (
    <Button
      component={Link}
      to={content?.linkType === "external" ? { pathname: content.buttonTarget } : content.buttonTarget}
      target={content?.linkType === "external" ? "_blank" : undefined}
      variant="contained"
      color="primary"
      fullWidth
    >
      {content.buttonLabel}
    </Button>
  );
};

const ExtraContent = ({ rentalContractCase }: { rentalContractCase: AbstractCase }) => {
  //both buttons in here because otherwise we cant produce the break line between both texts parts in the first text block
  if (rentalContractCase.leadStatus === STATUS_EXTRAJUDICIAL_COMMUNICATION_SENT) {
    return (
      <Stack spacing={3} sx={{ marginTop: 2 }}>
        <Typography>
          Es kam inzwischen zu einer Einigung mit Ihrem Vermieter und der Fall kann abgeschlossen werden? Dann teilen Sie uns dies bitte hier kurz mit:
        </Typography>
        <Button component={Link} to={getCaseLink(rentalContractCase) + "/abschliessen"} variant="contained" color="primary" fullWidth>
          Fall abschließen
        </Button>
        <Typography>Sie haben eine nicht zufriedenstellende Reaktion des Vermieters erhalten? Dann laden Sie uns diese bitte hoch:</Typography>
        <Button component={HashLink} to={getCaseLink(rentalContractCase) + "#upload"} variant="contained" color="primary" fullWidth>
          Dokument hochladen
        </Button>
      </Stack>
    );
  }

  return null;
};

export const getStatusBoxConfigRentalContractCase = (rentalContractCase: AbstractCase) => {
  const content = getContent(rentalContractCase);
  return {
    header: content.header,
    text: content.text,
    ctaButton: getButton(rentalContractCase),
    productName: getProductData(rentalContractCase.name, "label"),
    percentage: getPercentage(rentalContractCase),
    extraContent: <ExtraContent rentalContractCase={rentalContractCase} />,
  };
};
