import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconDocumentPlus = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 147.76 205.7">
      <defs>
        <clipPath id="clip-path" transform="translate(-68.84 -35.91)">
          <rect x="68.91" y="35.95" width="147.63" height="205.59" style={{ fill: "none" }} />
        </clipPath>
      </defs>
      <g style={{ clipPath: "url(#clip-path)" }}>
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          d="M120.71,45.05H83.09s-11.75,0-11.75,11.75V85.17"
          transform="translate(-68.84 -35.91)"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3px" }}
          d="M162.62,44.22s0-6.81-6.81-6.81h-27s-6.81,0-6.81,6.81V48s0,6.81,6.81,6.81h27s6.81,0,6.81-6.81Z"
          transform="translate(-68.84 -35.91)"
        />
      </g>
      <polygon
        style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3.02px" }}
        points="51.63 70.8 69 70.8 69 88.31 77.12 88.31 77.12 70.8 94.49 70.8 94.49 61.48 77.12 61.48 77.12 43.97 69 43.97 69 61.48 51.63 61.48 51.63 70.8"
      />
      <line style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3.02px" }} x1="26.28" y1="118.9" x2="120.65" y2="118.9" />
      <line style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3.02px" }} x1="26.28" y1="132.86" x2="120.65" y2="132.86" />
      <line style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3.02px" }} x1="35.38" y1="148.82" x2="109.56" y2="148.82" />
      <line style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3.02px" }} x1="36.68" y1="164.09" x2="110.85" y2="164.09" />
      <path
        style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
        d="M214.1,176.29V56.8s0-11.75-11.75-11.75H163.73"
        transform="translate(-68.84 -35.91)"
      />
      <path
        style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
        d="M71.34,94.06v133.3s0,11.75,11.75,11.75H202.35s11.75,0,11.75-11.75V185.87"
        transform="translate(-68.84 -35.91)"
      />
    </SvgIcon>
  );
};

export default IconDocumentPlus;
