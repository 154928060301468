import React from "react";
import { Box, Grid, Hidden, MenuItem } from "@mui/material";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import MoneyIcon from "../../../../assets/icons/contract/toggleable/IconMoneyEnforce";
import IncompleteIcon from "../../../../assets/icons/common/toggleable/IconIncomplete";
import BrokenIcon from "../../../../assets/icons/contract/toggleable/IconBroken";
import AlarmIcon from "../../../../assets/icons/contract/toggleable/IconAlarm";
import QuestionMarkIcon from "../../../../assets/icons/common/toggleable/IconQuestionmark";
import CheckIcon from "../../../../assets/icons/common/toggleable/IconCheck";
import CrossIcon from "../../../../assets/icons/common/toggleable/IconClose";
import { translate } from "../../../../services/translations/translations";
import _includes from "lodash/includes";
import { dateFieldDefault, requiredValidator } from "../../../../services/validationRules";
import { useForm } from "../../../../provider/Form/FormProvider";
import Group from "../../../Wizard/Group";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import Typography from "@mui/material/Typography";
import FormLegend from "../../Common/FormLegend/FormLegend";
import ValidatorDateField from "../../../FormFields/ValidatorDateField";
import NumberFieldConfigured from "../../ConfiguredFormElements/NumberFieldConfigured/NumberFieldConfigured";
import { ContractDetailsProps } from "../../../../types/ContractDetailsProps";
import wizardStyle from "../../wizardStyle";

export default function ServiceContractDetails({ submitProps, product }: ContractDetailsProps) {
  const { values, handleSubmit } = useForm();
  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} sx={wizardStyle.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Details zu Ihrem Vertrag</strong>
              </FormLegend>
            </Grid>
            <Grid item xs={12}>
              <Group
                type="radio"
                name="reasonForDispute"
                question={translate(`${product.name}.reasonForDispute.question`, values)}
                options={[
                  {
                    labelText: translate(`${product.name}.reasonForDispute.options.costEstimateExceeded.label`, values),
                    labelIcon: <MoneyIcon />,
                    labelIconActive: <MoneyIcon active />,
                    value: "costEstimateExceeded",
                  },
                  {
                    labelText: translate(`${product.name}.reasonForDispute.options.unfinishedWork.label`, values),
                    labelIcon: <IncompleteIcon />,
                    labelIconActive: <IncompleteIcon active />,
                    value: "unfinishedWork",
                  },
                  {
                    labelText: translate(`${product.name}.reasonForDispute.options.hasDefects.label`, values),
                    labelIcon: <BrokenIcon />,
                    labelIconActive: <BrokenIcon active />,
                    value: "hasDefects",
                  },
                  {
                    labelText: translate(`${product.name}.reasonForDispute.options.other.label`, values),
                    labelIcon: <QuestionMarkIcon />,
                    labelIconActive: <QuestionMarkIcon active />,
                    value: "other",
                  },
                ]}
                validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
              />
            </Grid>
            <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
              <FormLegend>Welche Dienstleistung haben Sie beauftragt?</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                id="serviceTitle"
                name="serviceTitle"
                label="Beschreibung der Dienstleistung"
                helperText="Zum Beispiel: Aufbau einer Küche"
                validators={[requiredValidator("Bitte schildern Sie uns hier kurz die Dienstleistung.")]}
              />
            </Grid>
            {values.reasonForDispute === "costEstimateExceeded" && (
              <>
                <NumberFieldConfigured caseData={product} fieldPath={"costEstimate"} required numberType={"float"} fieldType={"money"} />

                <Grid item xs={12}>
                  <Group
                    type="radio"
                    name="costEstimateAdjustment"
                    question={translate(`${product.name}.costEstimateAdjustment.question`, values)}
                    options={[
                      {
                        labelText: translate(`${product.name}.costEstimateAdjustment.options.asap.label`, values),
                        labelIcon: <CheckIcon />,
                        labelIconActive: <CheckIcon active />,
                        value: "asap",
                      },
                      {
                        labelText: translate(`${product.name}.costEstimateAdjustment.options.tooLate.label`, values),
                        labelIcon: <AlarmIcon />,
                        labelIconActive: <AlarmIcon active />,
                        value: "tooLate",
                      },
                      {
                        labelText: translate(`${product.name}.costEstimateAdjustment.options.notAtAll.label`, values),
                        labelIcon: <CrossIcon />,
                        labelIconActive: <CrossIcon active />,
                        value: "notAtAll",
                      },
                    ]}
                    validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
                  />
                </Grid>
                {_includes(["asap", "tooLate"], values.costEstimateAdjustment) && (
                  <>
                    <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                      <FormLegend>Wann hat Ihnen der Vertragspartner die Kostenerhöhung mitgeteilt?</FormLegend>
                    </Grid>
                    <Grid item xs={12}>
                      <ValidatorDateField
                        label="Datum Information Erhöhung"
                        name="costEstimateAdjustmentDate"
                        validators={[requiredValidator("Bitte geben Sie das Datum an"), dateFieldDefault]}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
            <NumberFieldConfigured caseData={product} fieldPath={"costReal"} required numberType={"float"} fieldType={"money"} />

            {_includes(["hasDefects", "unfinishedWork"], values.reasonForDispute) && (
              <>
                <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                  <FormLegend>Bitte beschreiben Sie hier die Mängel so genau wie möglich.</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField
                    label="Beschreibung Mängel"
                    name="defectsDescriptionClient"
                    validators={[requiredValidator("Bitte geben Sie die Mängel an.")]}
                    multiline
                    rows={6}
                    helperText={
                      "Beschreiben Sie möglichst detailliert, damit wir eine gute Grundlage für das rechtliche Vorgehen haben und führen Sie mehrere Mängel in einer Liste mit Spiegelstrichen auf."
                    }
                  />
                </Grid>
                <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                  <FormLegend>Wurde vom Vertragspartner bereits versucht die Mängel zu beheben und falls ja, wie oft?</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorSelectField
                    label={translate(`${product.name}.numberRepairAttempts.label`, values)}
                    name="numberRepairAttempts"
                    validators={[requiredValidator("Bitte geben Sie die Anzahl der Ausbesserungsversuche an")]}
                  >
                    <MenuItem value="0">{translate(`${product.name}.numberRepairAttempts.options.0.label`, values)}</MenuItem>
                    <MenuItem value="1">{translate(`${product.name}.numberRepairAttempts.options.1.label`, values)}</MenuItem>
                    <MenuItem value="2">{translate(`${product.name}.numberRepairAttempts.options.2.label`, values)}</MenuItem>
                    <MenuItem value="3">{translate(`${product.name}.numberRepairAttempts.options.3.label`, values)}</MenuItem>
                  </ValidatorSelectField>
                </Grid>
              </>
            )}
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
