import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../../types/IconProps";

const IconDocumentVvSchlemmer = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g>
        <path
          style={{ fill: "none" }}
          d="M134.977,42.471h29.763c6.22,0,10.733-4.078,10.733-9.693v-2.199c0-5.615-4.514-9.693-10.733-9.693
			h-29.763c-6.22,0-10.734,4.078-10.734,9.693v2.199C124.243,38.393,128.757,42.471,134.977,42.471z"
        />
        <path
          style={{ fill: "#B99B5F" }}
          d="M149.929,69.647c-6.792,0-14.064,4.385-14.064,10.912c0,6.961,0.471,13.668,0.874,17.295
			c0.277,2.498,4.932,10.723,13.191,10.723s12.913-8.225,13.19-10.723c0.403-3.627,0.873-10.334,0.873-17.295
			C163.993,74.032,156.721,69.647,149.929,69.647z"
        />
        <g>
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M241.796,210c-1.958,0-3.545,1.586-3.545,3.545v57.106c0,2.711-2.206,4.918-4.917,4.918H66.667
				c-2.71,0-4.916-2.207-4.916-4.918V92.895c0-1.959-1.588-3.545-3.545-3.545c-1.958,0-3.546,1.586-3.546,3.545v177.757
				c0,6.621,5.387,12.008,12.007,12.008h166.667c6.621,0,12.007-5.387,12.007-12.008v-57.106
				C245.341,211.586,243.754,210,241.796,210z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M58.211,80.983c1.958,0,3.545-1.586,3.545-3.545V40.145c0-2.719,2.203-4.93,4.911-4.93h54.029
				c0.08,0,0.154-0.018,0.232-0.023c1.158,6.313,6.804,10.824,14.049,10.824h29.763c7.245,0,12.891-4.514,14.049-10.824
				c0.08,0.006,0.155,0.023,0.235,0.023h54.313c2.708,0,4.91,2.211,4.91,4.93v155.504c0,1.959,1.588,3.545,3.545,3.545
				c1.958,0,3.545-1.586,3.545-3.545V40.145c0-6.627-5.384-12.02-12.001-12.02h-54.313c-0.082,0-0.157,0.02-0.237,0.023
				c-1.164-6.303-6.808-10.809-14.047-10.809h-29.763c-7.239,0-12.883,4.506-14.047,10.809c-0.079-0.004-0.154-0.023-0.234-0.023
				H66.667c-6.618,0-12.001,5.393-12.001,12.02v37.293C54.666,79.397,56.253,80.983,58.211,80.983z M124.243,30.579
				c0-5.615,4.514-9.693,10.734-9.693h29.763c6.22,0,10.733,4.078,10.733,9.693v2.199c0,5.615-4.514,9.693-10.733,9.693h-29.763
				c-6.22,0-10.734-4.078-10.734-9.693V30.579z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M210.791,190.624c0-0.979-0.793-1.773-1.772-1.773H87.734c-0.979,0-1.772,0.795-1.772,1.773
				s0.793,1.771,1.772,1.771h121.284C209.997,192.395,210.791,191.602,210.791,190.624z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M209.018,169.665H87.734c-0.979,0-1.772,0.793-1.772,1.771s0.793,1.773,1.772,1.773h121.284
				c0.979,0,1.772-0.795,1.772-1.773S209.997,169.665,209.018,169.665z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M197.973,227.226H98.897c-0.979,0-1.772,0.793-1.772,1.771s0.793,1.773,1.772,1.773h99.075
				c0.979,0,1.772-0.795,1.772-1.773S198.952,227.226,197.973,227.226z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M197.973,208.038H98.897c-0.979,0-1.772,0.793-1.772,1.771s0.793,1.773,1.772,1.773h99.075
				c0.979,0,1.772-0.795,1.772-1.773S198.952,208.038,197.973,208.038z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M114.215,125.713c-4.31,3.135-7.154,6.471-8.02,13.4c-0.121,0.973,0.567,1.857,1.539,1.979
				c0.075,0.01,0.148,0.014,0.222,0.014c0.881,0,1.645-0.656,1.757-1.553c0.718-5.748,2.782-8.205,6.587-10.973
				c1.518-1.104,6.22-2.611,10.368-3.941c9.706-3.115,14.771-4.887,14.771-8.061c0-0.979-0.793-1.773-1.772-1.773
				c-0.891,0-1.629,0.658-1.754,1.514c-1.081,1.336-8.1,3.588-12.327,4.943C120.546,122.879,116.193,124.276,114.215,125.713z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M166.643,98.245c0.433-3.895,0.895-10.588,0.895-17.686c0-8.646-9.105-14.457-17.609-14.457
				c-8.504,0-17.609,5.811-17.609,14.457c0,7.098,0.462,13.791,0.895,17.686c0.523,4.711,6.896,13.877,16.714,13.877
				c3.236,0,6.088-1.006,8.49-2.512v6.969c0,3.174,5.065,4.945,14.77,8.061c4.148,1.33,8.851,2.838,10.368,3.941
				c3.806,2.768,5.869,5.225,6.587,10.973c0.112,0.896,0.875,1.553,1.756,1.553c0.073,0,0.147-0.004,0.222-0.014
				c0.971-0.121,1.66-1.006,1.539-1.979c-0.866-6.93-3.711-10.266-8.02-13.4c-1.978-1.438-6.33-2.834-11.37-4.451
				c-4.203-1.348-11.167-3.582-12.308-4.92v-9.6C164.729,103.903,166.388,100.545,166.643,98.245z M135.865,80.559
				c0-6.527,7.272-10.912,14.064-10.912c6.792,0,14.063,4.385,14.063,10.912c0,6.961-0.47,13.668-0.873,17.295
				c-0.277,2.498-4.931,10.723-13.19,10.723s-12.914-8.225-13.191-10.723C136.335,94.227,135.865,87.52,135.865,80.559z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconDocumentVvSchlemmer;
