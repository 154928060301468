import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconAttorneyCheck = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93">
        <g>
          <path
            style={{ fill: "none" }}
            d="M276.489,29.258h-85.87c-1.473,0-2.671,1.198-2.671,2.671v114.421c0,1.474,1.198,2.671,2.671,2.671
			h85.87c1.473,0,2.671-1.198,2.671-2.671V31.929C279.161,30.456,277.962,29.258,276.489,29.258z M231.812,44.784h0.618
			c0.932,0,1.688,0.756,1.688,1.688s-0.755,1.688-1.688,1.688h-0.618c-0.932,0-1.688-0.756-1.688-1.688
			S230.88,44.784,231.812,44.784z M231.812,82.279h0.618c0.932,0,1.688,0.755,1.688,1.687c0,0.931-0.755,1.688-1.688,1.688h-0.618
			c-0.932,0-1.688-0.756-1.688-1.688C230.125,83.034,230.88,82.279,231.812,82.279z M230.125,61.093
			c0-0.932,0.756-1.688,1.688-1.688h22.873c0.932,0,1.687,0.756,1.687,1.688c0,0.931-0.755,1.687-1.687,1.687h-22.873
			C230.88,62.78,230.125,62.024,230.125,61.093z M231.812,119.772h0.618c0.932,0,1.688,0.757,1.688,1.688
			c0,0.931-0.755,1.687-1.688,1.687h-0.618c-0.932,0-1.688-0.755-1.688-1.687C230.125,120.529,230.88,119.772,231.812,119.772z
			 M230.125,98.587c0-0.932,0.756-1.688,1.688-1.688h22.873c0.932,0,1.687,0.756,1.687,1.688s-0.755,1.688-1.687,1.688h-22.873
			C230.88,100.275,230.125,99.519,230.125,98.587z M222.553,81.209l-15.086,22.094l-9.408-5.032
			c-1.096-0.586-1.509-1.949-0.923-3.045c0.585-1.096,1.949-1.508,3.044-0.923l5.848,3.128l12.81-18.759
			c0.701-1.026,2.102-1.291,3.127-0.589C222.99,78.784,223.254,80.184,222.553,81.209z M222.553,43.715l-15.086,22.093l-9.408-5.031
			c-1.096-0.587-1.509-1.949-0.923-3.046c0.585-1.095,1.949-1.508,3.044-0.922l5.848,3.127l12.81-18.758
			c0.701-1.026,2.101-1.29,3.127-0.589C222.99,41.289,223.254,42.689,222.553,43.715z M254.685,137.77h-22.873
			c-0.932,0-1.688-0.756-1.688-1.688s0.756-1.688,1.688-1.688h22.873c0.932,0,1.687,0.756,1.687,1.688
			S255.617,137.77,254.685,137.77z M269.307,130.458h-37.495c-0.932,0-1.688-0.756-1.688-1.688s0.756-1.688,1.688-1.688h37.495
			c0.932,0,1.688,0.756,1.688,1.688S270.239,130.458,269.307,130.458z M269.307,123.147h-31.869c-0.932,0-1.687-0.755-1.687-1.687
			c0-0.932,0.755-1.688,1.687-1.688h31.869c0.932,0,1.688,0.757,1.688,1.688C270.995,122.392,270.239,123.147,269.307,123.147z
			 M269.307,92.964h-37.495c-0.932,0-1.688-0.756-1.688-1.687c0-0.932,0.756-1.688,1.688-1.688h37.495
			c0.932,0,1.688,0.756,1.688,1.688C270.995,92.208,270.239,92.964,269.307,92.964z M269.307,85.653h-31.869
			c-0.932,0-1.687-0.756-1.687-1.688c0-0.932,0.755-1.687,1.687-1.687h31.869c0.932,0,1.688,0.755,1.688,1.687
			C270.995,84.897,270.239,85.653,269.307,85.653z M269.307,55.469h-37.495c-0.932,0-1.688-0.756-1.688-1.688
			s0.756-1.688,1.688-1.688h37.495c0.932,0,1.688,0.756,1.688,1.688S270.239,55.469,269.307,55.469z M269.307,48.16h-31.869
			c-0.932,0-1.687-0.756-1.687-1.688s0.755-1.688,1.687-1.688h31.869c0.932,0,1.688,0.756,1.688,1.688S270.239,48.16,269.307,48.16z
			"
          />
          <path
            style={{ fill: "none" }}
            d="M165.822,121.926V76.749c-8.073,9.995-21.087,16.32-35.151,16.32
			c-17.842,0-33.698-9.745-40.395-24.827c-0.756-1.704,0.012-3.697,1.715-4.454c1.704-0.758,3.697,0.011,4.454,1.714
			c5.615,12.646,19.05,20.817,34.227,20.817c16.008,0,30.37-9.495,35.142-23.151c-0.197-26.858-21.889-46.315-51.733-46.315
			c-29.976,0-51.731,19.633-51.731,46.682v23.219c6.749-0.719,12.54-5.061,15.073-11.491c0.683-1.734,2.641-2.587,4.376-1.902
			c1.734,0.683,2.586,2.642,1.902,4.376c-3.556,9.03-11.806,15.068-21.352,15.805v28.384c0,23.13,16.496,41.525,41.047,45.774
			c6.622,1.148,13.8,1.196,20.304,0.172C148.898,163.971,165.822,145.507,165.822,121.926z"
          />
          <path
            style={{ fill: "none" }}
            d="M151.142,213.373c-0.094,2.272,1.307,3.7,1.738,4.083c1.856,1.65,5.944,4.583,9.552,7.171
			c2.012,1.443,3.938,2.825,5.545,4.026c2.104-1.969,3.261-3.986,3.342-5.912c0.095-2.272-1.307-3.702-1.739-4.085
			c-1.854-1.648-5.939-4.579-9.544-7.165c-2.014-1.444-3.942-2.828-5.551-4.03C152.38,209.43,151.223,211.447,151.142,213.373z"
          />
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M109.871,185.854l0.016,0.004c0.605,0.195,1.23,0.337,1.863,0.431
				c1.836,0.271,3.743,0.121,5.375-0.423c2.994-0.951,4.646-2.828,4.85-3.474v-7.48c-2.578,0.293-5.223,0.444-7.896,0.444
				c-3.032,0-6.051-0.196-9.01-0.58v7.618C105.272,183.038,106.916,184.912,109.871,185.854z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M116.126,190.722c-0.286,0.045-0.58,0.073-0.873,0.103c-0.14,0.015-0.276,0.038-0.417,0.049
				c-0.239,0.018-0.483,0.02-0.725,0.028c-0.196,0.007-0.391,0.022-0.588,0.022c-0.435,0-0.872-0.017-1.306-0.051
				c-0.09-0.007-0.179-0.022-0.269-0.031c-0.341-0.032-0.681-0.068-1.018-0.121c-0.011-0.002-0.024-0.002-0.035-0.004
				l-16.551,74.214c3.384,3.041,14.393,12.987,19.171,17.962c4.778-4.976,15.787-14.922,19.171-17.962l-16.55-74.209
				C116.134,190.721,116.13,190.721,116.126,190.722z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M198.058,60.777l9.408,5.031l15.086-22.093c0.702-1.026,0.438-2.426-0.588-3.126
				c-1.026-0.701-2.426-0.437-3.127,0.589l-12.81,18.758l-5.848-3.127c-1.095-0.585-2.459-0.173-3.044,0.922
				C196.549,58.828,196.962,60.19,198.058,60.777z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M221.964,78.083c-1.025-0.701-2.426-0.437-3.127,0.589l-12.81,18.759l-5.848-3.128
				c-1.095-0.585-2.459-0.173-3.044,0.923c-0.586,1.096-0.173,2.459,0.923,3.045l9.408,5.032l15.086-22.094
				C223.254,80.184,222.99,78.784,221.964,78.083z"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M201.677,202.621c1.822-0.391,2.983-2.184,2.594-4.006c-0.74-3.459-1.604-6.31-2.569-8.474
				c-4.688-10.494-10.583-18.378-24.123-22.746c-7.918-2.554-15.76-6.149-20.401-8.417c9.734-9.47,15.394-22.391,15.394-37.052
				v-58.39c0-30.46-25.146-53.431-58.492-53.431c-33.34,0-58.48,22.97-58.48,53.431v58.39c0,14.063,5.27,26.592,14.377,35.979
				c-4.866,2.564-13.507,6.837-21.73,9.49c-13.543,4.368-19.438,12.252-24.123,22.746c-8.112,18.17-6.568,82.339-6.499,85.062
				c0.047,1.833,1.548,3.288,3.372,3.288c0.029,0,0.059,0,0.088-0.001c1.863-0.048,3.335-1.597,3.287-3.46
				c-0.017-0.651-1.581-65.349,5.914-82.138c4.166-9.332,8.779-15.445,20.032-19.074c10.234-3.301,20.729-8.778,24.999-11.113
				c6.968,5.459,15.529,9.386,25.253,11.322v8.449c0,2.453,2.527,5.25,6.015,6.902l-17.204,77.144l0.983,0.88
				c5.121,4.578,18.946,17.139,21.383,20.241l1.769,2.252l1.769-2.252c2.437-3.102,16.262-15.663,21.383-20.241l0.983-0.88
				l-17.204-77.144c3.497-1.655,6.026-4.451,6.026-6.902v-8.239c9.603-1.738,18.092-5.383,25.087-10.515
				c3.951,2.02,13.747,6.806,23.945,10.095c11.249,3.629,15.863,9.742,20.031,19.073c0.773,1.734,1.491,4.136,2.133,7.136
				c0.339,1.584,1.739,2.669,3.296,2.669C201.202,202.696,201.439,202.671,201.677,202.621z M62.348,121.926V93.542
				c9.545-0.737,17.795-6.775,21.352-15.805c0.684-1.734-0.168-3.693-1.902-4.376c-1.735-0.685-3.693,0.168-4.376,1.902
				c-2.533,6.43-8.324,10.772-15.073,11.491V63.536c0-27.049,21.756-46.682,51.731-46.682c29.845,0,51.536,19.457,51.733,46.315
				c-4.772,13.656-19.134,23.151-35.142,23.151c-15.177,0-28.612-8.172-34.227-20.817c-0.756-1.703-2.75-2.472-4.454-1.714
				c-1.703,0.756-2.471,2.75-1.715,4.454c6.697,15.082,22.553,24.827,40.395,24.827c14.064,0,27.078-6.325,35.151-16.32v45.177
				c0,23.582-16.924,42.045-42.123,45.946c-6.504,1.024-13.683,0.977-20.304-0.172C78.844,163.451,62.348,145.056,62.348,121.926z
				 M121.975,182.392c-0.204,0.646-1.856,2.523-4.85,3.474c-1.632,0.544-3.54,0.694-5.375,0.423
				c-0.633-0.093-1.258-0.235-1.863-0.431l-0.016-0.004c-2.955-0.942-4.599-2.816-4.801-3.46v-7.618
				c2.958,0.384,5.978,0.58,9.01,0.58c2.673,0,5.318-0.151,7.896-0.444V182.392z M113.517,282.892
				c-4.778-4.976-15.787-14.922-19.171-17.962l16.551-74.214c0.011,0.002,0.023,0.002,0.035,0.004
				c0.336,0.053,0.677,0.089,1.018,0.121c0.09,0.009,0.179,0.024,0.269,0.031c0.434,0.034,0.871,0.051,1.306,0.051
				c0.198,0,0.392-0.015,0.588-0.022c0.242-0.008,0.485-0.01,0.725-0.028c0.141-0.011,0.277-0.034,0.417-0.049
				c0.292-0.03,0.586-0.057,0.873-0.103c0.004,0,0.008,0,0.012-0.001l16.55,74.209C129.304,267.97,118.295,277.916,113.517,282.892z
				"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M202.761,210.414c-1.854,0.188-3.205,1.844-3.016,3.698c2.58,25.365,1.717,60.565,1.708,60.918
				c-0.048,1.863,1.424,3.412,3.287,3.46c0.029,0,0.059,0.001,0.088,0.001c1.823,0,3.325-1.455,3.372-3.288
				c0.009-0.357,0.882-35.977-1.741-61.773C206.271,211.575,204.615,210.224,202.761,210.414z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M174.064,213.612c-2.118-1.883-6.173-4.792-10.095-7.605c-2.686-1.926-5.222-3.746-6.94-5.091
				c-0.939-0.735-1.229-2.711-0.646-4.404c0.395-1.144,1.487-3.062,4.377-3.062c5.594,0,7.742,6.564,7.838,6.868
				c0.548,1.774,2.429,2.773,4.206,2.231c1.783-0.542,2.789-2.427,2.247-4.21c-0.145-0.475-3.662-11.638-14.29-11.638
				c-5.017,0-9.139,2.917-10.757,7.611c-0.942,2.734-0.868,5.619,0.062,8.03c-4.424,4.055-5.552,8.006-5.666,10.75
				c-0.146,3.536,1.348,7.054,3.998,9.408c2.119,1.884,6.177,4.795,10.102,7.61c2.683,1.925,5.217,3.742,6.934,5.086
				c0.939,0.735,1.229,2.711,0.646,4.405c-0.395,1.144-1.487,3.062-4.377,3.062c-5.594,0-7.742-6.565-7.838-6.868
				c-0.548-1.773-2.429-2.771-4.206-2.231c-1.783,0.542-2.789,2.428-2.246,4.21c0.144,0.475,3.662,11.638,14.29,11.638
				c5.017,0,9.139-2.917,10.757-7.611c0.942-2.733,0.868-5.618-0.062-8.029c4.423-4.055,5.551-8.006,5.666-10.75
				C178.209,219.486,176.714,215.968,174.064,213.612z M152.88,217.456c-0.432-0.383-1.832-1.812-1.738-4.083
				c0.081-1.926,1.238-3.942,3.342-5.912c1.608,1.202,3.537,2.585,5.551,4.03c3.605,2.586,7.69,5.517,9.544,7.165
				c0.432,0.384,1.834,1.813,1.739,4.085c-0.081,1.926-1.238,3.942-3.342,5.912c-1.607-1.201-3.533-2.583-5.545-4.026
				C158.825,222.039,154.736,219.106,152.88,217.456z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M276.489,25.883h-85.87c-3.333,0-6.046,2.712-6.046,6.046v114.421
				c0,3.334,2.712,6.046,6.046,6.046h85.87c3.333,0,6.046-2.712,6.046-6.046V31.929C282.536,28.595,279.823,25.883,276.489,25.883z
				 M279.161,146.351c0,1.474-1.198,2.671-2.671,2.671h-85.87c-1.473,0-2.671-1.198-2.671-2.671V31.929
				c0-1.473,1.198-2.671,2.671-2.671h85.87c1.473,0,2.671,1.198,2.671,2.671V146.351z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M231.812,48.16h0.618c0.932,0,1.688-0.756,1.688-1.688s-0.755-1.688-1.688-1.688h-0.618
				c-0.932,0-1.688,0.756-1.688,1.688S230.88,48.16,231.812,48.16z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M269.307,44.784h-31.869c-0.932,0-1.687,0.756-1.687,1.688s0.755,1.688,1.687,1.688h31.869
				c0.932,0,1.688-0.756,1.688-1.688S270.239,44.784,269.307,44.784z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M269.307,52.094h-37.495c-0.932,0-1.688,0.756-1.688,1.688s0.756,1.688,1.688,1.688h37.495
				c0.932,0,1.688-0.756,1.688-1.688S270.239,52.094,269.307,52.094z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M231.812,62.78h22.873c0.932,0,1.687-0.756,1.687-1.687c0-0.932-0.755-1.688-1.687-1.688h-22.873
				c-0.932,0-1.688,0.756-1.688,1.688C230.125,62.024,230.88,62.78,231.812,62.78z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M231.812,85.653h0.618c0.932,0,1.688-0.756,1.688-1.688c0-0.932-0.755-1.687-1.688-1.687h-0.618
				c-0.932,0-1.688,0.755-1.688,1.687C230.125,84.897,230.88,85.653,231.812,85.653z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M269.307,82.279h-31.869c-0.932,0-1.687,0.755-1.687,1.687c0,0.931,0.755,1.688,1.687,1.688
				h31.869c0.932,0,1.688-0.756,1.688-1.688C270.995,83.034,270.239,82.279,269.307,82.279z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M269.307,89.589h-37.495c-0.932,0-1.688,0.756-1.688,1.688c0,0.931,0.756,1.687,1.688,1.687
				h37.495c0.932,0,1.688-0.756,1.688-1.687C270.995,90.345,270.239,89.589,269.307,89.589z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M231.812,100.275h22.873c0.932,0,1.687-0.756,1.687-1.688s-0.755-1.688-1.687-1.688h-22.873
				c-0.932,0-1.688,0.756-1.688,1.688S230.88,100.275,231.812,100.275z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M231.812,123.147h0.618c0.932,0,1.688-0.755,1.688-1.687c0-0.932-0.755-1.688-1.688-1.688h-0.618
				c-0.932,0-1.688,0.757-1.688,1.688C230.125,122.392,230.88,123.147,231.812,123.147z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M269.307,119.772h-31.869c-0.932,0-1.687,0.757-1.687,1.688c0,0.931,0.755,1.687,1.687,1.687
				h31.869c0.932,0,1.688-0.755,1.688-1.687C270.995,120.529,270.239,119.772,269.307,119.772z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M269.307,127.083h-37.495c-0.932,0-1.688,0.756-1.688,1.688s0.756,1.688,1.688,1.688h37.495
				c0.932,0,1.688-0.756,1.688-1.688S270.239,127.083,269.307,127.083z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M254.685,134.395h-22.873c-0.932,0-1.688,0.756-1.688,1.688s0.756,1.688,1.688,1.688h22.873
				c0.932,0,1.687-0.756,1.687-1.688S255.617,134.395,254.685,134.395z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconAttorneyCheck;
