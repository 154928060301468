import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconBooking = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59">
        <g>
          <path
            fill="none"
            d="M155.137,88.593V56.064c0-2.816-2.292-5.108-5.108-5.108H52.792c-2.816,0-5.107,2.292-5.107,5.108v32.529
			c0,2.816,2.291,5.108,5.107,5.108h97.237C152.845,93.701,155.137,91.41,155.137,88.593z"
          />
          <path
            fill="none"
            d="M190.556,250.37c35.544,0,64.462-28.922,64.462-64.472c0-35.55-28.917-64.472-64.462-64.472
			c-35.55,0-64.471,28.922-64.471,64.472C126.085,221.448,155.006,250.37,190.556,250.37z M165.381,185.899
			c0-1.884,0.086-3.699,0.243-5.457h-9.853c-1.692,0-3.065-1.373-3.065-3.065s1.373-3.065,3.065-3.065h10.767
			c1.738-8.047,5.435-14.474,11.06-19.176c14.079-11.771,34.878-8.192,35.756-8.034c1.666,0.3,2.773,1.893,2.473,3.559
			c-0.299,1.664-1.893,2.767-3.556,2.474c-0.217-0.038-18.922-3.205-30.768,6.725c-4.221,3.539-7.112,8.381-8.66,14.453h36.164
			c1.692,0,3.065,1.373,3.065,3.065s-1.373,3.065-3.065,3.065h-37.223c-0.176,1.743-0.274,3.557-0.274,5.457
			c0,2.992,0.184,5.778,0.533,8.377h36.964c1.692,0,3.065,1.373,3.065,3.065s-1.373,3.065-3.065,3.065h-35.729
			c1.964,6.956,5.559,12.03,10.756,15.155c10.577,6.358,24.726,2.872,29.556,0.305c1.494-0.793,3.35-0.227,4.145,1.269
			c0.794,1.495,0.226,3.351-1.269,4.145c-3.474,1.845-10.468,3.989-18.244,3.989c-5.722,0-11.866-1.16-17.346-4.454
			c-7.037-4.229-11.696-11.069-13.939-20.408h-11.167c-1.692,0-3.065-1.373-3.065-3.065s1.373-3.065,3.065-3.065h10.093
			C165.549,191.64,165.381,188.854,165.381,185.899z"
          />
          <path
            fill="#137F7B"
            d="M190.556,104.509c-44.878,0-81.389,36.511-81.389,81.389s36.511,81.389,81.389,81.389
			c44.873,0,81.379-36.511,81.379-81.389S235.429,104.509,190.556,104.509z M190.556,256.5c-38.93,0-70.601-31.672-70.601-70.602
			c0-38.93,31.671-70.602,70.601-70.602c38.924,0,70.592,31.672,70.592,70.602C261.148,224.828,229.48,256.5,190.556,256.5z"
          />
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M74.909,136.035H57.354c-1.692,0-3.065,1.373-3.065,3.065s1.373,3.065,3.065,3.065h17.554
				c1.692,0,3.065-1.373,3.065-3.065S76.601,136.035,74.909,136.035z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M74.909,160.932H57.354c-1.692,0-3.065,1.373-3.065,3.065s1.373,3.065,3.065,3.065h17.554
				c1.692,0,3.065-1.373,3.065-3.065S76.601,160.932,74.909,160.932z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M74.909,185.829H57.354c-1.692,0-3.065,1.373-3.065,3.065s1.373,3.065,3.065,3.065h17.554
				c1.692,0,3.065-1.373,3.065-3.065S76.601,185.829,74.909,185.829z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M74.909,210.725H57.354c-1.692,0-3.065,1.373-3.065,3.065s1.373,3.065,3.065,3.065h17.554
				c1.692,0,3.065-1.373,3.065-3.065S76.601,210.725,74.909,210.725z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M92.633,136.035c-1.692,0-3.065,1.373-3.065,3.065s1.373,3.065,3.065,3.065h17.555
				c1.692,0,3.065-1.373,3.065-3.065s-1.373-3.065-3.065-3.065H92.633z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M190.556,98.379c-39.586,0-73.111,26.42-83.885,62.552H92.629c-1.692,0-3.065,1.373-3.065,3.065
				s1.373,3.065,3.065,3.065h12.456c-1.332,6.047-2.042,12.325-2.047,18.766H92.629c-1.692,0-3.065,1.373-3.065,3.065
				s1.373,3.065,3.065,3.065h10.622c0.445,6.473,1.597,12.754,3.379,18.769H92.633c-1.692,0-3.065,1.373-3.065,3.065
				s1.373,3.065,3.065,3.065h16.064c4.06,10.7,10.16,20.402,17.823,28.632H33.173c-2.816,0-5.108-2.292-5.108-5.108V37.82
				c0-2.816,2.292-5.108,5.108-5.108h137.852c2.816,0,5.107,2.292,5.107,5.108v54.47c0,1.692,1.373,3.065,3.065,3.065
				s3.065-1.373,3.065-3.065V37.82c0-6.196-5.041-11.238-11.237-11.238H33.173c-6.196,0-11.238,5.042-11.238,11.238v202.559
				c0,6.196,5.042,11.238,11.238,11.238h99.653c15.42,13.562,35.63,21.8,57.73,21.8c48.252,0,87.509-39.261,87.509-87.519
				S238.809,98.379,190.556,98.379z M190.556,267.288c-44.878,0-81.389-36.511-81.389-81.389s36.511-81.389,81.389-81.389
				c44.873,0,81.379,36.511,81.379,81.389S235.429,267.288,190.556,267.288z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M190.556,115.296c-38.93,0-70.601,31.672-70.601,70.602c0,38.93,31.671,70.602,70.601,70.602
				c38.924,0,70.592-31.672,70.592-70.602C261.148,146.968,229.48,115.296,190.556,115.296z M190.556,250.37
				c-35.55,0-64.471-28.922-64.471-64.472c0-35.55,28.921-64.472,64.471-64.472c35.544,0,64.462,28.922,64.462,64.472
				C255.018,221.448,226.101,250.37,190.556,250.37z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M213.591,215.866c-4.83,2.567-18.979,6.053-29.556-0.305c-5.198-3.125-8.792-8.199-10.756-15.155h35.729
				c1.692,0,3.065-1.373,3.065-3.065s-1.373-3.065-3.065-3.065h-36.964c-0.349-2.599-0.533-5.385-0.533-8.377
				c0-1.9,0.098-3.713,0.274-5.457h37.223c1.692,0,3.065-1.373,3.065-3.065s-1.373-3.065-3.065-3.065h-36.164
				c1.548-6.071,4.438-10.914,8.66-14.453c11.845-9.93,30.551-6.762,30.768-6.725c1.663,0.293,3.256-0.81,3.556-2.474
				c0.3-1.666-0.808-3.259-2.473-3.559c-0.878-0.158-21.677-3.737-35.756,8.034c-5.625,4.703-9.321,11.129-11.06,19.176h-10.767
				c-1.692,0-3.065,1.373-3.065,3.065s1.373,3.065,3.065,3.065h9.853c-0.157,1.758-0.243,3.573-0.243,5.457
				c0,2.955,0.168,5.741,0.483,8.377h-10.093c-1.692,0-3.065,1.373-3.065,3.065s1.373,3.065,3.065,3.065h11.167
				c2.243,9.339,6.902,16.179,13.939,20.408c5.48,3.294,11.624,4.454,17.346,4.454c7.775,0,14.77-2.144,18.244-3.989
				c1.495-0.794,2.063-2.649,1.269-4.145C216.941,215.64,215.084,215.073,213.591,215.866z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M161.267,88.593V56.064c0-6.196-5.042-11.238-11.238-11.238H52.792c-6.196,0-11.237,5.042-11.237,11.238
				v32.529c0,6.196,5.041,11.238,11.237,11.238h97.237C156.225,99.831,161.267,94.79,161.267,88.593z M47.685,88.593V56.064
				c0-2.816,2.291-5.108,5.107-5.108h97.237c2.816,0,5.108,2.292,5.108,5.108v32.529c0,2.816-2.292,5.108-5.108,5.108H52.792
				C49.976,93.701,47.685,91.41,47.685,88.593z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconBooking;
