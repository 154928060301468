import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconDocumentHouseSignDetailed = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 173.35 205.24">
      <defs>
        <clipPath id="clip-path" transform="translate(-64.91 -36.37)">
          <rect x="64.98" y="36.43" width="173.25" height="205.11" style={{ fill: "none" }} />
        </clipPath>
      </defs>
      <g style={{ clipPath: "url(#clip-path)" }}>
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          d="M214.74,156.19V64.4s-3.05-22.75-26.36-25.53H79.61S67.49,38.87,67.49,51V80.25"
          transform="translate(-64.91 -36.37)"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5.15px", strokeLinecap: "round" }}
          d="M67.49,89.42V226.91S67.49,239,79.61,239h123s12.12,0,12.12-12.12V190"
          transform="translate(-64.91 -36.37)"
        />
      </g>
      <polyline
        style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3px", strokeLinecap: "round" }}
        points="22.03 48.13 44.27 21.61 66.1 47.69"
      />
      <polyline
        style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3px", strokeLinecap: "round" }}
        points="27.8 49.55 27.8 73.11 40.1 73.11 40.1 54.13 49.45 54.13 49.45 73.44 60.51 73.44 61.13 73.44 61.13 49.21"
      />
      <line
        style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3.11px", strokeLinecap: "round" }}
        x1="26.89"
        y1="90.4"
        x2="124.22"
        y2="90.4"
      />
      <line
        style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3.11px", strokeLinecap: "round" }}
        x1="26.89"
        y1="104.8"
        x2="124.22"
        y2="104.8"
      />
      <line
        style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3.11px", strokeLinecap: "round" }}
        x1="26.89"
        y1="118.27"
        x2="124.22"
        y2="118.27"
      />
      <line
        style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3.11px", strokeLinecap: "round" }}
        x1="26.89"
        y1="132.66"
        x2="124.22"
        y2="132.66"
      />
      <line
        style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3.11px", strokeLinecap: "round" }}
        x1="26.89"
        y1="146.7"
        x2="124.22"
        y2="146.7"
      />
      <line
        style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3.11px", strokeLinecap: "round" }}
        x1="26.89"
        y1="161.1"
        x2="109.81"
        y2="161.1"
      />
      <g style={{ clipPath: "url(#clip-path)" }}>
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          d="M187.47,40.94V61.18s0,7.11,7.11,7.11h19"
          transform="translate(-64.91 -36.37)"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3.09px", strokeLinecap: "round" }}
          d="M166.34,222.79l21.95-7.9s43.81-43.32,46.79-46.6,1.35-6.16-.58-8.09L231.33,157l-.42-.51-3.17-3.18c-1.93-1.92-4.82-3.56-8.09-.57s-44.91,45.08-44.91,45.08a12.41,12.41,0,0,0-2.51,4l-7.08,19.69"
          transform="translate(-64.91 -36.37)"
        />
      </g>
      <polygon style={{ fill: getIconColor(active, theme) }} points="100.94 178.91 108.39 185.51 96.9 189.78 100.94 178.91" />
      <g style={{ clipPath: "url(#clip-path)" }}>
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3.09px", strokeLinecap: "round" }}
          d="M173.5,200.32a45.54,45.54,0,0,1,14.21,13.87"
          transform="translate(-64.91 -36.37)"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3.09px", strokeLinecap: "round" }}
          d="M210.67,161.83A49.82,49.82,0,0,1,226.23,177"
          transform="translate(-64.91 -36.37)"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "2.06px" }}
          d="M116,226.62a3.28,3.28,0,0,0,.94,0l1.36-.37q3.28-1,6.35-2.34a27,27,0,0,0,10.19-7.38c1.19-1.48,3.72-4.55,1.95-6.4-1.62-1.68-5-1.09-7.14-.47a17.9,17.9,0,0,0-8.32,5.22,7.74,7.74,0,0,0-1.92,7.19c.69,2.25,2.89,4.26,5.75,5.67a19.9,19.9,0,0,0,12.14,1.7c4.46-.73,8.79-2.73,11-5.29,1.06-1.21,3.24-4.52.66-5.85-2.4-1.23-5.48.84-6.69,1.83-2.05,1.66-3.5,4.3-2.47,6.46.78,1.63,2.76,2.81,5.48,2.88,6.66.17,8.76-5,10.63-6.21,0,0,1.87-1.2,2.34-1.12s0,1.44,0,1.79c0,.73-.16,2.09,1.38,2.34a4.71,4.71,0,0,0,3.08-.93c.1,0,.9-.35.88-.53"
          transform="translate(-64.91 -36.37)"
        />
      </g>
      <line style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "2.06px" }} x1="124.47" y1="169.49" x2="154.46" y2="139.78" />
    </SvgIcon>
  );
};

export default IconDocumentHouseSignDetailed;
