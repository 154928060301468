import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../../types/IconProps";

const IconCourtSchlemmer = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g>
        <path
          style={{ fill: "none" }}
          d="M91.119,150.86h-2.208l1.649,87.441h44.998l1.651-87.441H135c-2.901,0-5.262-2.36-5.262-5.262v-7.553
			H96.381v7.553C96.381,148.5,94.021,150.86,91.119,150.86z"
        />
        <path
          style={{ fill: "none" }}
          d="M167.272,150.86h-2.21l1.651,87.441h45.002l1.649-87.441h-2.208c-2.901,0-5.262-2.36-5.262-5.262
			v-7.553h-33.361v7.553C172.533,148.5,170.173,150.86,167.272,150.86z"
        />
        <g>
          <polygon
            style={{ fill: "#B99B5F" }}
            points="85.403,150.86 64.56,150.86 62.91,238.302 62.877,240.06 87.085,240.06 87.052,238.302
							"
          />
          <path
            style={{ fill: "#B99B5F" }}
            d="M57.09,138.046v7.553c0,0.967,0.786,1.754,1.753,1.754h2.276h27.726h2.275
				c0.967,0,1.754-0.787,1.754-1.754v-7.553v-1.28H57.09V138.046z"
          />
          <polygon
            style={{ fill: "#B99B5F" }}
            points="161.555,150.86 140.716,150.86 139.066,238.302 139.033,240.06 163.238,240.06
				163.205,238.302 			"
          />
          <path
            style={{ fill: "#B99B5F" }}
            d="M133.246,138.046v7.553c0,0.967,0.787,1.754,1.754,1.754h2.275h27.722h2.276
				c0.967,0,1.753-0.787,1.753-1.754v-7.553v-1.28h-35.779V138.046z"
          />
          <polygon
            style={{ fill: "#B99B5F" }}
            points="237.712,150.86 216.873,150.86 215.224,238.302 215.19,240.06 239.395,240.06
				239.361,238.302 			"
          />
          <path
            style={{ fill: "#B99B5F" }}
            d="M209.402,136.766v1.28v7.553c0,0.967,0.787,1.754,1.754,1.754h2.275h27.723h2.275
				c0.967,0,1.753-0.787,1.753-1.754v-7.553v-1.28H209.402z"
          />
        </g>
        <g>
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M268.631,108.78c-1.937,0-3.507,1.57-3.507,3.508v4.713c0,7.736-6.294,14.029-14.03,14.029H48.906
				c-7.736,0-14.03-6.293-14.03-14.029v-4.713c0-1.938-1.57-3.508-3.507-3.508s-3.507,1.57-3.507,3.508v4.713
				c0,11.604,9.44,21.045,21.044,21.045h4.676v7.553c0,2.901,2.36,5.262,5.261,5.262h2.21l-1.651,87.441H40.568
				c-1.937,0-3.507,1.57-3.507,3.508v26.487c0,1.938,1.57,3.508,3.507,3.508h145.678c1.937,0,3.507-1.57,3.507-3.508
				s-1.57-3.508-3.507-3.508H44.076v-19.472h210.622v19.472h-49.91c-1.937,0-3.507,1.57-3.507,3.508s1.57,3.508,3.507,3.508h53.417
				c1.937,0,3.507-1.57,3.507-3.508V241.81c0-1.938-1.57-3.508-3.507-3.508H242.87l-1.649-87.441h2.208
				c2.901,0,5.261-2.36,5.261-5.262v-7.553h2.404c11.604,0,21.044-9.441,21.044-21.045v-4.713
				C272.138,110.351,270.568,108.78,268.631,108.78z M245.182,138.046v7.553c0,0.967-0.786,1.754-1.753,1.754h-2.275h-27.723h-2.275
				c-0.967,0-1.754-0.787-1.754-1.754v-7.553v-1.28h35.779V138.046z M239.361,238.302l0.033,1.758H215.19l0.033-1.758l1.649-87.441
				h20.84L239.361,238.302z M211.156,150.86h2.208l-1.649,87.441h-45.002l-1.651-87.441h2.21c2.901,0,5.261-2.36,5.261-5.262v-7.553
				h33.361v7.553C205.895,148.5,208.255,150.86,211.156,150.86z M139.066,238.302l1.65-87.441h20.839l1.65,87.441l0.033,1.758
				h-24.205L139.066,238.302z M133.246,138.046v-1.28h35.779v1.28v7.553c0,0.967-0.786,1.754-1.753,1.754h-2.276h-27.722H135
				c-0.967,0-1.754-0.787-1.754-1.754V138.046z M135,150.86h2.209l-1.651,87.441H90.56l-1.649-87.441h2.208
				c2.901,0,5.262-2.36,5.262-5.262v-7.553h33.357v7.553C129.738,148.5,132.099,150.86,135,150.86z M62.91,238.302l1.65-87.441
				h20.843l1.649,87.441l0.033,1.758H62.877L62.91,238.302z M57.09,145.599v-7.553v-1.28h35.783v1.28v7.553
				c0,0.967-0.787,1.754-1.754,1.754h-2.275H61.119h-2.276C57.876,147.353,57.09,146.565,57.09,145.599z"
          />
          <rect x="40.568" y="110.534" style={{ fill: theme.palette.iconColor.main }} width="217.637" height="3.508" />
          <rect x="40.568" y="278.449" style={{ fill: theme.palette.iconColor.main }} width="217.637" height="3.508" />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M228.118,74.438c-0.779-0.574-1.877-0.407-2.452,0.372c-0.574,0.779-0.408,1.878,0.372,2.452
				l26.829,19.763H45.993L149.391,22.36l65.862,47.114c0.789,0.563,1.884,0.382,2.447-0.406c0.563-0.788,0.382-1.884-0.406-2.447
				l-66.888-47.847l-1.025-0.732L35.144,100.533h228.399L228.118,74.438z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconCourtSchlemmer;
