import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconClose = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 201.45 200.25">
      <g style={{ stroke: getIconColor(active, theme), strokeLinecap: "round", strokeWidth: "5", fill: "none" }}>
        <line x1="198.42" y1="20.1" x2="153.87" y2="63.7" />
        <polyline points="147.5 69.71 115.8 100.58 195.58 182.48 179.92 196.77 100.14 114.88 17.53 195.36 3.49 180.13 39.84 144.71" />
        <polyline points="198.95 19.45 184.38 4.87 101.76 85.35 21.99 3.46 6.38 17.37" />
        <polyline points="47.22 137.53 86.1 99.64 6.33 17.75" />
      </g>
    </SvgIcon>
  );
};

export default IconClose;
