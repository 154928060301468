import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconQuestionmark = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 124.34 205.48">
      <g style={{ fill: "none", stroke: getIconColor(active, theme), strokeLinecap: "round", strokeWidth: 5 }}>
        <path
          d="M101.29,49A60.51,60.51,0,0,0,76.84,97h29.69s2.32-28.52,28.52-28.52,30.27,23.28,26.78,34.35-19.18,21.85-32,33.18c-9.9,8.73-13.39,25-12.22,45.4h29.1s-2.33-22.12,11.06-31.43,38.42-24.45,38.42-55.31S175.8,37.92,136.8,37.92a61.92,61.92,0,0,0-24.91,5"
          transform="translate(-74.34 -35.42)"
        />
        <path d="M151.25,222.61A19.13,19.13,0,1,0,144,234.5" transform="translate(-74.34 -35.42)" />
      </g>
    </SvgIcon>
  );
};

export default IconQuestionmark;
