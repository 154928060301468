import React from "react";
import { Box, Grid, Hidden } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import wizardStyle from "../wizardStyle";
import { useForm } from "../../../provider/Form/FormProvider";
import FormFader from "../Common/Fader/FormFader";
import FormLegend from "../Common/FormLegend/FormLegend";
import { translate } from "../../../services/translations/translations";
import { requiredValidator } from "../../../services/validationRules";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import ToolsIcon from "../../../assets/icons/contract/toggleable/IconTools";
import RentIncreaseIcon from "../../../assets/icons/provider/toggleable/IconPriceIncrease";
import UtilitiesStatementIcon from "../../../assets/icons/provider/toggleable/IconProviderWrongInvoice";
import DepositIcon from "../../../assets/icons/contract/toggleable/IconMoneyEnforce";
import LandlordHasCancelledIcon from "../../../assets/icons/provider/toggleable/IconProviderCancellation";
import IllegalContractClauseIcon from "../../../assets/icons/provision/toggleable/IconDocumentHouseSignDetailed";
import QuestionMarkIcon from "../../../assets/icons/common/toggleable/IconQuestionmark";
import Group from "../../Wizard/Group";
import RentalContractReasonForDisputeDetails from "./RentalContractReasonForDisputeDetails";

export default function RentalContractDetails({ submitProps }: any) {
  const { values, handleSubmit } = useForm();
  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} sx={wizardStyle.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Details zu Ihrem Anliegen</strong>
              </FormLegend>
            </Grid>

            <Grid item xs={12}>
              <Group
                type="radio"
                name="reasonForDispute"
                question={translate(`rentalContract.reasonForDispute.question`, values)}
                options={[
                  {
                    labelText: translate(`rentalContract.reasonForDispute.options.housingDeficiencies.label`, values),
                    labelIcon: <ToolsIcon />,
                    labelIconActive: <ToolsIcon active />,
                    value: "housingDeficiencies",
                  },
                  {
                    labelText: translate(`rentalContract.reasonForDispute.options.rentIncrease.label`, values),
                    labelIcon: <RentIncreaseIcon />,
                    labelIconActive: <RentIncreaseIcon active />,
                    value: "rentIncrease",
                  },
                  {
                    labelText: translate(`rentalContract.reasonForDispute.options.utilitiesStatement.label`, values),
                    labelIcon: <UtilitiesStatementIcon />,
                    labelIconActive: <UtilitiesStatementIcon active />,
                    value: "utilitiesStatement",
                  },
                  {
                    labelText: translate(`rentalContract.reasonForDispute.options.deposit.label`, values),
                    labelIcon: <DepositIcon />,
                    labelIconActive: <DepositIcon active />,
                    value: "deposit",
                  },
                  {
                    labelText: translate(`rentalContract.reasonForDispute.options.landlordHasCancelled.label`, values),
                    labelIcon: <LandlordHasCancelledIcon />,
                    labelIconActive: <LandlordHasCancelledIcon active />,
                    value: "landlordHasCancelled",
                  },
                  {
                    labelText: translate(`rentalContract.reasonForDispute.options.illegalContractClause.label`, values),
                    labelIcon: <IllegalContractClauseIcon />,
                    labelIconActive: <IllegalContractClauseIcon active />,
                    value: "illegalContractClause",
                  },
                  {
                    labelText: translate(`rentalContract.reasonForDispute.options.other.label`, values),
                    labelIcon: <QuestionMarkIcon />,
                    labelIconActive: <QuestionMarkIcon active />,
                    value: "other",
                  },
                ]}
                validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
              />
            </Grid>
            <RentalContractReasonForDisputeDetails values={values} />
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
