import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconContract = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g>
        <path
          style={{ fill: "none" }}
          d="M231.68,199.378V40.888c0-2.908-2.358-5.273-5.257-5.273H68.32h-6.321h-1.5
			c-2.898,0-5.255,2.365-5.255,5.273v30.688c1.98,5.43,4.23,11.314,6.755,17.529c1.943,4.785,4.048,9.762,6.321,14.885
			c8.125,18.305,18.385,38.398,30.936,57.75h105.077c1.746,0,3.161,1.414,3.161,3.16c0,1.744-1.415,3.16-3.161,3.16H103.449
			c3.224,4.729,6.591,9.393,10.099,13.959h90.786c1.746,0,3.161,1.414,3.161,3.16c0,1.744-1.415,3.16-3.161,3.16h-85.778
			c1.44,1.764,2.895,3.518,4.38,5.242c2.668,3.098,5.374,6.096,8.103,9.014h62.2c1.579,0,2.859,1.279,2.859,2.859
			c0,1.578-1.28,2.859-2.859,2.859h-56.702c25.81,26.154,53.374,44.48,75.672,56.076c4.594,2.389,8.963,4.492,13.047,6.32
			c2.113,0.943,4.154,1.82,6.105,2.619c0.195-0.549,0.321-1.131,0.321-1.744v-0.875v-6.32v-50.746c0-1.746,1.415-3.16,3.161-3.16
			v-7.945C233.095,202.538,231.68,201.122,231.68,199.378z M193.237,143.476H93.052c-1.579,0-2.859-1.281-2.859-2.859
			c0-1.58,1.28-2.859,2.859-2.859h100.186c1.579,0,2.859,1.279,2.859,2.859C196.097,142.194,194.816,143.476,193.237,143.476z
			 M193.237,123.644H93.052c-1.579,0-2.859-1.281-2.859-2.859c0-1.58,1.28-2.859,2.859-2.859h100.186
			c1.579,0,2.859,1.279,2.859,2.859C196.097,122.362,194.816,123.644,193.237,123.644z M204.333,104.11H81.956
			c-1.746,0-3.161-1.416-3.161-3.16c0-1.746,1.415-3.16,3.161-3.16h122.377c1.746,0,3.161,1.414,3.161,3.16
			C207.494,102.694,206.079,104.11,204.333,104.11z M204.333,84.28H81.956c-1.746,0-3.161-1.416-3.161-3.16
			c0-1.746,1.415-3.16,3.161-3.16h122.377c1.746,0,3.161,1.414,3.161,3.16C207.494,82.864,206.079,84.28,204.333,84.28z"
        />
        <g>
          <path
            style={{ fill: "#137F7B" }}
            d="M118.157,248.179c16.971-7.846,21.833-14.289,23.199-17.639c0.758-1.861,0.81-3.563,0.154-5.061
				c-0.144-0.328-1.001-0.607-2.449-0.607c-1.806,0-4.533,0.436-7.943,1.762c-6.711,2.607-17.192,9.588-13.916,19.205
				C117.487,246.675,117.806,247.452,118.157,248.179z"
          />
          <path
            style={{ fill: "#137F7B" }}
            d="M225.254,270.71c-4.084-1.828-8.453-3.932-13.047-6.32
				c-22.298-11.596-49.862-29.922-75.672-56.076H93.052c-1.579,0-2.859-1.281-2.859-2.859c0-1.58,1.28-2.859,2.859-2.859h37.986
				c-2.729-2.918-5.435-5.916-8.103-9.014c-1.485-1.725-2.939-3.479-4.38-5.242H81.956c-1.746,0-3.161-1.416-3.161-3.16
				c0-1.746,1.415-3.16,3.161-3.16h31.591c-3.507-4.566-6.875-9.23-10.099-13.959H81.956c-1.746,0-3.161-1.416-3.161-3.16
				c0-1.746,1.415-3.16,3.161-3.16h17.3c-12.551-19.352-22.812-39.445-30.936-57.75c-2.273-5.123-4.378-10.1-6.321-14.885
				c-2.524-6.215-4.775-12.1-6.755-17.529v200.01c0,2.896,2.357,5.256,5.255,5.256h165.924c2.284,0,4.213-1.475,4.937-3.512
				C229.408,272.53,227.367,271.653,225.254,270.71z M110.929,251.296c1.384-0.559,2.706-1.111,3.969-1.66
				c-0.397-0.836-0.755-1.717-1.071-2.646c-3.629-10.652,5.42-19.568,15.999-23.68c6.221-2.42,13.257-3.129,14.951,0.738
				c1.042,2.379,1.001,5.09-0.118,7.838c-2.607,6.396-11.059,13.018-24.613,19.344c5.832,7.428,16.348,6.535,24.845,4.111
				c0.234-0.068,0.488-0.148,0.759-0.24c-0.952-1.33-1.343-2.865-1.099-4.475c0.765-5.047,7.737-13.01,13.819-14.236
				c2.846-0.572,5.202,0.404,6.636,2.75c1.036,1.693,1.174,3.637,0.401,5.617c-1.608,4.123-7.176,8.1-12.177,10.797
				c3.521,0.73,7.996,0.049,11.375-2.686c2.357-1.908,4.409-3.818,6.058-5.354c3.513-3.27,5.276-4.908,7.176-4.086
				c1.617,0.705,1.617,2.703,1.617,3.361c0,0.727-0.024,1.408-0.046,2.031c-0.02,0.555-0.045,1.24-0.027,1.74
				c0.446-0.162,1.201-0.502,2.438-1.215c0.854-0.49,1.944-0.197,2.435,0.656c0.492,0.854,0.198,1.943-0.656,2.436
				c-2.741,1.578-4.753,2.369-6.304,1.436c-1.601-0.965-1.531-2.918-1.45-5.18c0.012-0.344,0.025-0.707,0.034-1.086
				c-0.8,0.668-1.832,1.629-2.786,2.518c-1.689,1.572-3.791,3.529-6.244,5.514c-5.727,4.637-13.507,4.527-18.097,2.074
				c-1.141,0.482-2.132,0.842-2.88,1.057c-4.41,1.258-8.456,1.881-12.096,1.881c-7.557,0-13.366-2.682-17.069-7.912
				c-1.43,0.625-2.911,1.246-4.44,1.863c-0.914,0.373-1.952-0.072-2.321-0.984C109.574,252.704,110.016,251.665,110.929,251.296z"
          />
          <path
            style={{ fill: "#137F7B" }}
            d="M149.12,253.653c4.998-2.369,11.536-6.531,12.963-10.191c0.483-1.238,0.162-2-0.121-2.463
				c-0.374-0.611-0.912-1.203-2.049-1.203c-0.249,0-0.527,0.027-0.838,0.09c-4.442,0.896-10.438,7.586-10.998,11.275
				C147.917,252.212,148.465,253.05,149.12,253.653z"
          />
        </g>
        <g>
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M247.917,190.085c1.746,0,3.161-1.416,3.161-3.16V28.437c0-6.395-5.193-11.596-11.577-11.596
				H73.577c-6.385,0-11.579,5.201-11.579,11.596v0.857h-1.5c-6.383,0-11.577,5.201-11.577,11.594v230.697
				c0,6.383,5.193,11.576,11.577,11.576h165.924c6.385,0,11.579-5.193,11.579-11.576v-0.875h1.5c6.383,0,11.577-5.193,11.577-11.576
				v-57.941c0-1.746-1.415-3.16-3.161-3.16s-3.161,1.414-3.161,3.16v57.941c0,2.896-2.357,5.256-5.255,5.256h-1.5v-50.746
				c0-1.746-1.415-3.16-3.161-3.16s-3.161,1.414-3.161,3.16v50.746v6.32v0.875c0,0.613-0.126,1.195-0.321,1.744
				c-0.724,2.037-2.652,3.512-4.937,3.512H60.499c-2.898,0-5.255-2.359-5.255-5.256V71.575V40.888c0-2.908,2.357-5.273,5.255-5.273
				h1.5h6.321h158.103c2.899,0,5.257,2.365,5.257,5.273v158.49c0,1.744,1.415,3.16,3.161,3.16s3.161-1.416,3.161-3.16V40.888
				c0-6.393-5.194-11.594-11.579-11.594H68.32v-0.857c0-2.908,2.358-5.275,5.257-5.275h165.924c2.898,0,5.255,2.367,5.255,5.275
				v158.488C244.756,188.669,246.171,190.085,247.917,190.085z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M204.333,161.739H99.256h-17.3c-1.746,0-3.161,1.414-3.161,3.16c0,1.744,1.415,3.16,3.161,3.16
				h21.493h100.884c1.746,0,3.161-1.416,3.161-3.16C207.494,163.153,206.079,161.739,204.333,161.739z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M204.333,97.79H81.956c-1.746,0-3.161,1.414-3.161,3.16c0,1.744,1.415,3.16,3.161,3.16h122.377
				c1.746,0,3.161-1.416,3.161-3.16C207.494,99.204,206.079,97.79,204.333,97.79z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M204.333,77.96H81.956c-1.746,0-3.161,1.414-3.161,3.16c0,1.744,1.415,3.16,3.161,3.16h122.377
				c1.746,0,3.161-1.416,3.161-3.16C207.494,79.374,206.079,77.96,204.333,77.96z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M207.494,185.179c0-1.746-1.415-3.16-3.161-3.16h-90.786H81.956c-1.746,0-3.161,1.414-3.161,3.16
				c0,1.744,1.415,3.16,3.161,3.16h36.599h85.778C206.079,188.339,207.494,186.923,207.494,185.179z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M196.097,205.454c0-1.58-1.28-2.859-2.859-2.859h-62.2H93.052c-1.579,0-2.859,1.279-2.859,2.859
				c0,1.578,1.28,2.859,2.859,2.859h43.483h56.702C194.816,208.313,196.097,207.032,196.097,205.454z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M93.052,143.476h100.186c1.579,0,2.859-1.281,2.859-2.859c0-1.58-1.28-2.859-2.859-2.859H93.052
				c-1.579,0-2.859,1.279-2.859,2.859C90.192,142.194,91.473,143.476,93.052,143.476z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M93.052,123.644h100.186c1.579,0,2.859-1.281,2.859-2.859c0-1.58-1.28-2.859-2.859-2.859H93.052
				c-1.579,0-2.859,1.279-2.859,2.859C90.192,122.362,91.473,123.644,93.052,123.644z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M177.294,253.874c1.551,0.934,3.563,0.143,6.304-1.436c0.854-0.492,1.147-1.582,0.656-2.436
				c-0.491-0.854-1.581-1.146-2.435-0.656c-1.237,0.713-1.991,1.053-2.438,1.215c-0.018-0.5,0.007-1.186,0.027-1.74
				c0.022-0.623,0.046-1.305,0.046-2.031c0-0.658,0-2.656-1.617-3.361c-1.899-0.822-3.663,0.816-7.176,4.086
				c-1.648,1.535-3.7,3.445-6.058,5.354c-3.378,2.734-7.853,3.416-11.375,2.686c5.001-2.697,10.569-6.674,12.177-10.797
				c0.773-1.98,0.634-3.924-0.401-5.617c-1.434-2.346-3.79-3.322-6.636-2.75c-6.082,1.227-13.054,9.189-13.819,14.236
				c-0.244,1.609,0.147,3.145,1.099,4.475c-0.271,0.092-0.525,0.172-0.759,0.24c-8.497,2.424-19.013,3.316-24.845-4.111
				c13.555-6.326,22.006-12.947,24.613-19.344c1.119-2.748,1.16-5.459,0.118-7.838c-1.694-3.867-8.73-3.158-14.951-0.738
				c-10.579,4.111-19.628,13.027-15.999,23.68c0.316,0.93,0.673,1.811,1.071,2.646c-1.263,0.549-2.585,1.102-3.969,1.66
				c-0.913,0.369-1.354,1.408-0.985,2.322c0.369,0.912,1.408,1.357,2.321,0.984c1.529-0.617,3.01-1.238,4.44-1.863
				c3.703,5.23,9.512,7.912,17.069,7.912c3.64,0,7.686-0.623,12.096-1.881c0.748-0.215,1.739-0.574,2.88-1.057
				c4.59,2.453,12.371,2.563,18.097-2.074c2.453-1.984,4.555-3.941,6.244-5.514c0.954-0.889,1.985-1.85,2.786-2.518
				c-0.008,0.379-0.022,0.742-0.034,1.086C175.763,250.956,175.693,252.909,177.294,253.874z M118.157,248.179
				c-0.351-0.727-0.67-1.504-0.955-2.34c-3.277-9.617,7.205-16.598,13.916-19.205c3.41-1.326,6.137-1.762,7.943-1.762
				c1.448,0,2.305,0.279,2.449,0.607c0.656,1.498,0.604,3.199-0.154,5.061C139.99,233.89,135.128,240.333,118.157,248.179z
				 M148.077,251.161c0.559-3.689,6.555-10.379,10.998-11.275c0.312-0.063,0.589-0.09,0.838-0.09c1.137,0,1.675,0.592,2.049,1.203
				c0.283,0.463,0.604,1.225,0.121,2.463c-1.427,3.66-7.965,7.822-12.963,10.191C148.465,253.05,147.917,252.212,148.077,251.161z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconContract;
