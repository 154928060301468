import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconSuitCase = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48">
        <g>
          <rect x="23.627" y="141.62" style={{ fill: "none" }} width="104.324" height="22.141" />
          <path
            style={{ fill: "none" }}
            d="M127.951,128.886c0-2.003,1.624-3.627,3.627-3.627h36.432c2.003,0,3.627,1.624,3.627,3.627v5.479
			h104.736V96.183c0-4.848-3.945-8.792-8.795-8.792H32.422c-4.85,0-8.795,3.944-8.795,8.792v38.183h104.324V128.886z"
          />
          <rect x="171.637" y="141.62" style={{ fill: "none" }} width="104.736" height="22.141" />
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M191.557,46.4h-83.58c-2.565,0-4.652,2.085-4.652,4.648v5.879h92.881v-5.879
				C196.205,48.485,194.12,46.4,191.557,46.4z"
            />
            <polygon
              style={{ fill: "#137F7B" }}
              points="135.205,137.993 135.205,167.387 135.205,171.627 164.383,171.627 164.383,167.387
				164.383,137.993 164.383,132.513 135.205,132.513 			"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M280,178.16c-2.003,0-3.627,1.624-3.627,3.627v63.021c0,4.848-3.945,8.793-8.795,8.793H87.189
				c-2.003,0-3.627,1.624-3.627,3.627s1.624,3.627,3.627,3.627h180.389c8.85,0,16.049-7.198,16.049-16.047v-63.021
				C283.627,179.783,282.003,178.16,280,178.16z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M267.578,80.137H32.422c-8.85,0-16.049,7.198-16.049,16.046v41.81v29.395v77.42
				c0,8.849,7.199,16.047,16.049,16.047h39.213c2.003,0,3.627-1.624,3.627-3.627s-1.624-3.627-3.627-3.627H32.422
				c-4.85,0-8.795-3.945-8.795-8.793v-73.793h104.324v4.24c0,2.003,1.624,3.627,3.627,3.627h36.432c2.003,0,3.627-1.624,3.627-3.627
				v-4.24h111.99v-33.021v-41.81C283.627,87.335,276.428,80.137,267.578,80.137z M23.627,96.183c0-4.848,3.945-8.792,8.795-8.792
				h235.156c4.85,0,8.795,3.944,8.795,8.792v38.183H171.637v-5.479c0-2.003-1.624-3.627-3.627-3.627h-36.432
				c-2.003,0-3.627,1.624-3.627,3.627v5.479H23.627V96.183z M164.383,167.387v4.24h-29.178v-4.24v-29.395v-5.479h29.178v5.479
				V167.387z M23.627,163.76V141.62h104.324v22.141H23.627z M276.373,163.76H171.637V141.62h104.736V163.76z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M99.697,75.377c2.003,0,3.627-1.624,3.627-3.627V60.554h92.881V71.75
				c0,2.003,1.624,3.627,3.627,3.627s3.627-1.624,3.627-3.627V51.048c0-6.563-5.339-11.902-11.902-11.902h-83.58
				c-6.565,0-11.906,5.339-11.906,11.902V71.75C96.07,73.754,97.694,75.377,99.697,75.377z M103.324,51.048
				c0-2.563,2.087-4.648,4.652-4.648h83.58c2.563,0,4.648,2.085,4.648,4.648v5.879h-92.881V51.048z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconSuitCase;
