import Grid from "@mui/material/Grid";
import React from "react";
import CheckIcon from "../../../../../assets/icons/common/toggleable/IconCheck";
import CourtIcon from "../../../../../assets/icons/generic/toggleable/IconCourtDetailed";
import { useForm } from "../../../../../provider/Form/FormProvider";
import { requiredValidator } from "../../../../../services/validationRules";
import Group from "../../../../Wizard/Group";
import { AbstractCase } from "../../../../../types/Entities/AbstractCase";

type FinalProcessFeedbackClientProps = {
  product: AbstractCase;
  hasClaimStatusClient: boolean;
};
const FinalProcessFeedbackClient = ({ product, hasClaimStatusClient }: FinalProcessFeedbackClientProps) => {
  const { values } = useForm();

  if (
    (product.extrajudicialReaction === "fullyAgreed" && values.clientAgrees !== "no") ||
    (product.extrajudicialReaction === "noReaction" && values.reactionReceived === "") ||
    values.finalClaimStatusClient === "fullyAgreed" ||
    (hasClaimStatusClient && values.finalClaimStatusClient === "")
  ) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Group
        type={"radio"}
        name={"finalProcessFeedbackClient"}
        question={"Wie möchten Sie jetzt weiter vorgehen?"}
        options={[
          {
            labelText:
              product.caseCategory === "enforceClaim"
                ? "Der Fall kann abgeschlossen werden"
                : "Ich werde der Forderung wie gewünscht nachkommen, um eine Klage zu vermeiden.",
            labelIcon: <CheckIcon />,
            labelIconActive: <CheckIcon active />,
            value: "agreement",
            optionHintText: product.caseCategory === "enforceClaim" ? "Ich bin damit einverstanden, dass der Fall abgeschlossen wird." : null,
          },
          {
            labelText:
              product.caseCategory === "enforceClaim"
                ? "Ich möchte die Forderung aufrecht erhalten und Klage einreichen"
                : "Ich möchte der Forderung nicht nachkommen und eine Klage abwarten. ",
            labelIcon: <CourtIcon />,
            labelIconActive: <CourtIcon active />,
            value: "lawsuit",
            optionHintText:
              product.caseCategory === "enforceClaim"
                ? "Ich akzeptiere die Reaktion des Vermieters nicht und möchte die Forderung per Klage im gerichtlichen Verfahren durchsetzen."
                : null,
          },
        ]}
        validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
      />
    </Grid>
  );
};
export default FinalProcessFeedbackClient;
