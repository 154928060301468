import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconPostbox = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0  171.87 203.03">
      <defs>
        <clipPath id="clip-path" transform="translate(-62 -37.23)">
          <rect x="62" y="37.23" width="171.87" height="203.03" style={{ fill: "none" }} />
        </clipPath>
      </defs>
      <g style={{ clipPath: "url(#clip-path)" }}>
        <polyline
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          points="46.54 47.52 46.54 2.5 82.37 2.5 75.54 13.08 82.08 23.66 56.41 23.66"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          d="M86.09,115.25c0-7.65,6.38-14.74,6.38-14.74s10.63-14.17,29.06-14.17,31.18,9.92,36.14,19.84c0,0,5,7.09,5,23.39v50.31"
          transform="translate(-62 -37.23)"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          d="M125.78,86.34h63.61c18.43,0,31.18,9.92,36.15,19.84,0,0,5,7.09,5,23.39V136"
          transform="translate(-62 -37.23)"
        />
        <line
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          x1="21.26"
          y1="152.57"
          x2="169.37"
          y2="152.57"
        />
        <line
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          x1="132.15"
          y1="200.53"
          x2="132.15"
          y2="155.78"
        />
        <line
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          x1="24.47"
          y1="134.62"
          x2="24.47"
          y2="127.58"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3px", strokeLinecap: "round" }}
          d="M68.25,121.14s-4.75,0-4.75,4.75v32s0,4.75,4.75,4.75h50.23s4.75,0,4.75-4.75V125.89s0-4.75-4.75-4.75Z"
          transform="translate(-62 -37.23)"
        />
        <polyline
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3px", strokeLinecap: "round" }}
          points="2.11 85.74 30.75 104.64 59.4 85.13"
        />
        <line
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3px", strokeLinecap: "round" }}
          x1="99.21"
          y1="59.03"
          x2="153.07"
          y2="59.03"
        />
        <polyline
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          points="168.5 111.59 168.5 142.65 63.07 142.65 19.84 142.65"
        />
      </g>
    </SvgIcon>
  );
};

export default IconPostbox;
