import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconDocumentDownload = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_3">
        <g>
          <path
            fill="#137F7B"
            d="M172.438,102.543c-0.77,0-2.571,0-3.943-1.473c-1.342-1.441-1.221-3.159-1.161-3.985
			c0.017-0.22,0.037-0.493,0.037-0.832V24h-33.074v72.254c0,0.339,0.021,0.612,0.037,0.832c0.059,0.826,0.181,2.543-1.161,3.984
			c-1.371,1.473-3.173,1.473-3.942,1.473h-11.802c5.449,8.9,25.358,41.395,33.405,54.527c8.063-13.157,28.031-45.749,33.409-54.527
			H172.438z"
          />
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M228.465,46.926h-45.389c-2.209,0-4,1.791-4,4s1.791,4,4,4h45.389c2.189,0,3.971,1.784,3.971,3.977
				V206.09c0,2.209,1.791,4,4,4s4-1.791,4-4V58.902C240.436,52.298,235.064,46.926,228.465,46.926z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M63.564,102.094c2.209,0,4-1.791,4-4V58.902c0-2.193,1.782-3.977,3.973-3.977h47.068c2.209,0,4-1.791,4-4
				s-1.791-4-4-4H71.537c-6.602,0-11.973,5.373-11.973,11.977v39.191C59.564,100.303,61.355,102.094,63.564,102.094z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M236.436,216.082c-2.209,0-4,1.791-4,4v51.945c0,2.189-1.78,3.973-3.971,3.973H71.537
				c-2.19,0-3.973-1.782-3.973-3.973V112.086c0-2.209-1.791-4-4-4s-4,1.791-4,4v159.941c0,6.602,5.371,11.973,11.973,11.973h156.928
				c6.602,0,11.971-5.371,11.971-11.973v-51.945C240.436,217.873,238.645,216.082,236.436,216.082z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M108.915,97.543c-1.177,2.099-1.037,4.728,0.372,7.032c1.391,2.274,38.133,62.237,38.133,62.237
				c0.727,1.187,2.019,1.91,3.41,1.91h0.004c1.393,0,2.684-0.725,3.41-1.91c0,0,36.742-59.963,38.133-62.237
				c1.409-2.304,1.549-4.933,0.372-7.032c-1.038-1.851-2.933-3-4.946-3h-12.434v-72.82c0-5.723-4.861-5.723-6.459-5.723h-36.156
				c-1.598,0-6.459,0-6.459,5.723v72.82h-12.434C111.848,94.543,109.953,95.693,108.915,97.543z M134.332,97.086
				c-0.016-0.22-0.037-0.493-0.037-0.832V24h33.074v72.254c0,0.338-0.021,0.612-0.037,0.832c-0.059,0.826-0.182,2.543,1.161,3.985
				c1.372,1.473,3.175,1.473,3.944,1.473h11.804c-5.378,8.778-25.347,41.37-33.409,54.526
				c-8.047-13.131-27.956-45.625-33.405-54.526h11.802c0.77,0,2.571,0,3.942-1.473C134.513,99.629,134.391,97.912,134.332,97.086z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M207.398,176.75H92.07c-2.209,0-4,1.791-4,4s1.791,4,4,4h115.329c2.209,0,4-1.791,4-4
				S209.607,176.75,207.398,176.75z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M211.398,197.082c0-2.209-1.791-4-4-4H92.07c-2.209,0-4,1.791-4,4s1.791,4,4,4h115.329
				C209.607,201.082,211.398,199.291,211.398,197.082z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M194.732,211.746h-89.997c-2.209,0-4,1.791-4,4s1.791,4,4,4h89.997c2.209,0,4-1.791,4-4
				S196.941,211.746,194.732,211.746z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M194.732,230.078h-89.997c-2.209,0-4,1.791-4,4s1.791,4,4,4h89.997c2.209,0,4-1.791,4-4
				S196.941,230.078,194.732,230.078z"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconDocumentDownload;
