import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconTrafficLawMinimal = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23">
        <g>
          <path
            style={{ fill: "#137F7B" }}
            d="M29.026,154.892c-0.512,0.653-1.001,1.32-1.45,2.012c-2.112,3.239-3.408,6.812-3.859,10.629v13.864
			h14.604c1.823-13.528,13.437-23.996,27.457-23.996c11.479,0,21.898,7.214,25.927,17.953c0.721,1.92-0.252,4.06-2.172,4.78
			c-0.431,0.162-0.873,0.227-1.308,0.226v4.754h10.729c0-0.004-0.001-0.007-0.001-0.011c0-2.051,1.662-3.713,3.713-3.713h94.665
			c2.051,0,3.713,1.662,3.713,3.713c0,0.004-0.001,0.007-0.001,0.011h6.68c0-15.281,12.432-27.712,27.712-27.712
			c11.479,0,21.898,7.214,25.932,17.952c0.721,1.919-0.252,4.06-2.172,4.781c-0.432,0.162-0.873,0.227-1.309,0.226v4.753h7.297
			c-0.002-1.504,0.906-2.921,2.398-3.48c2.718-1.033,8.391-4.284,8.706-7.777v-18.964H29.026z"
          />
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M197.331,181.39h-94.665c-2.051,0-3.713,1.662-3.713,3.713c0,0.004,0.001,0.007,0.001,0.011
				c0.006,2.046,1.665,3.702,3.712,3.702h94.665c2.047,0,3.705-1.656,3.712-3.702c0-0.004,0.001-0.007,0.001-0.011
				C201.044,183.052,199.382,181.39,197.331,181.39z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M280,126.281h-90.668c-2.051,0-3.713,1.662-3.713,3.713s1.662,3.713,3.713,3.713h86.955v21.186
				v18.964c-0.315,3.493-5.988,6.744-8.706,7.777c-1.492,0.559-2.4,1.976-2.398,3.48c0,0.432,0.064,0.871,0.225,1.299
				c0.559,1.491,1.974,2.411,3.477,2.411c0.434,0,0.873-0.076,1.303-0.237c1.322-0.496,12.94-5.11,13.52-14.361
				c0.004-0.077,0.007-0.154,0.007-0.232v-44C283.713,127.943,282.051,126.281,280,126.281z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M65.777,164.827c8.401,0,16.027,5.279,18.975,13.135c0.559,1.489,1.972,2.396,3.472,2.397
				c0.435,0.001,0.877-0.064,1.308-0.226c1.92-0.72,2.893-2.86,2.172-4.78c-4.029-10.738-14.448-17.953-25.927-17.953
				c-14.02,0-25.634,10.467-27.457,23.996H23.717v-13.864c0.452-3.817,1.748-7.39,3.859-10.629c0.448-0.692,0.938-1.359,1.45-2.012
				c13.728-17.521,49.377-21.186,58.532-21.186h84c2.051,0,3.713-1.662,3.713-3.713v-24c0-2.051-1.662-3.713-3.713-3.713
				s-3.713,1.662-3.713,3.713v20.287H92.744c7.2-6.543,17.894-16.343,22.77-21.216c7.112-7.115,11.539-10.467,21.374-10.467h84.217
				l21.575,21.995c1.437,1.465,3.786,1.486,5.251,0.051c1.463-1.436,1.486-3.786,0.05-5.25l-22.666-23.108
				c-0.698-0.712-1.653-1.113-2.65-1.113h-85.776c-12.984,0-19.226,5.241-26.625,12.643c-6.629,6.625-24.965,23.204-28.886,26.741
				c-16.333,1.275-47.798,7.42-60.028,26.303c-2.783,4.269-4.478,8.998-5.036,14.057c-0.015,0.135-0.022,0.271-0.022,0.407v17.788
				c0,2.051,1.662,3.713,3.713,3.713H38.32c1.819,13.532,13.435,24.003,27.458,24.003c11.507,0,21.928-7.23,25.931-17.991
				c0.715-1.922-0.264-4.059-2.186-4.774c-1.92-0.715-4.06,0.264-4.774,2.186c-2.927,7.868-10.551,13.154-18.971,13.154
				c-10.658,0-19.404-8.266-20.208-18.721c0.224-0.478,0.359-1.006,0.359-1.569s-0.135-1.091-0.359-1.569
				C46.377,173.089,55.122,164.827,65.777,164.827z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M235.435,164.827c8.401,0,16.028,5.279,18.979,13.136c0.559,1.488,1.973,2.395,3.473,2.397
				c0.436,0,0.877-0.064,1.309-0.226c1.92-0.721,2.893-2.862,2.172-4.781c-4.033-10.737-14.453-17.952-25.932-17.952
				c-15.28,0-27.712,12.432-27.712,27.712s12.432,27.712,27.712,27.712c11.505,0,21.928-7.229,25.935-17.99
				c0.716-1.922-0.263-4.06-2.184-4.775c-1.921-0.715-4.06,0.262-4.775,2.184c-2.931,7.869-10.556,13.156-18.976,13.156
				c-11.186,0-20.286-9.101-20.286-20.287S224.249,164.827,235.435,164.827z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconTrafficLawMinimal;
