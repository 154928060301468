import Grid from "@mui/material/Grid";
import React from "react";
import CheckIcon from "../../../../../assets/icons/common/toggleable/IconCheck";
import CourtIcon from "../../../../../assets/icons/generic/toggleable/IconCourtDetailed";
import { useForm } from "../../../../../provider/Form/FormProvider";
import { requiredValidator } from "../../../../../services/validationRules";
import Group from "../../../../Wizard/Group";
import Paper from "@mui/material/Paper";
import feedbackFormStyle from "../feedbackFormStyle";
import ButtonLoading from "../../../../Button/ButtonLoading";
import HighlightBox from "../../../../Box/HighlightBox/HighlightBox";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

type FinalProcessFeedbackClientProps = {
  isLoading: boolean;
};

const FinalProcessFeedbackFlexServiceClient = ({ isLoading }: FinalProcessFeedbackClientProps) => {
  const { handleSubmit, values } = useForm();

  return (
    <Paper sx={feedbackFormStyle.paper} elevation={4}>
      <Box component={"form"} sx={{ ...feedbackFormStyle.applicationForm, ...feedbackFormStyle.wizardForm }} onSubmit={handleSubmit}>
        <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
          <Group
            type={"radio"}
            name={"clientReaction"}
            question={"Wie möchten Sie jetzt weiter vorgehen?"}
            options={[
              {
                labelText: "Ich benötige weitere rechtliche Hilfe",
                labelIcon: <CourtIcon />,
                labelIconActive: <CourtIcon active />,
                value: "continueNewCase",
                optionHintText: "Ich möchte versuchen, meine Ansprüche außergerichtlich durchzusetzen.",
              },
              {
                labelText: "Mein Anliegen wurde erledigt",
                labelIcon: <CheckIcon />,
                labelIconActive: <CheckIcon active />,
                value: "closeCase",
                optionHintText: "Ich habe keine weiteren rechtlichen Anliegen. Der Fall ist abgeschlossen.",
              },
            ]}
            validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
          />
          {values.clientReaction === "continueNewCase" && (
            <HighlightBox>
              <Typography>Wir fragen an, ob Rechtsschutz besteht und ADVOCARD die weiteren Kosten übernimmt.</Typography>
              <br />
              <Typography>Gegebenenfalls </Typography>
              <ul>
                <li>zahlen Sie die vereinbarte Selbstbeteiligung</li>
                <li>entfällt ihr Schadensfreiheitsrabatt</li>
              </ul>
            </HighlightBox>
          )}
          <ButtonLoading sx={feedbackFormStyle.button} fullWidth variant="contained" color="primary" type="submit" isLoading={isLoading}>
            Meine Rückmeldung übermitteln
          </ButtonLoading>
        </Grid>
      </Box>
    </Paper>
  );
};
export default FinalProcessFeedbackFlexServiceClient;
