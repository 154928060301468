import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconPlane = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 512 512">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path d="M128,443.733H93.867c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533H128c4.71,0,8.533-3.823,8.533-8.533S132.71,443.733,128,443.733z" />
        <path d="M128,392.533H42.667c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533H128c4.71,0,8.533-3.823,8.533-8.533S132.71,392.533,128,392.533z" />
        <path d="M128,341.333H76.8c-4.71,0-8.533,3.823-8.533,8.533S72.09,358.4,76.8,358.4H128c4.71,0,8.533-3.823,8.533-8.533S132.71,341.333,128,341.333z" />
        <path d="M93.867,119.467H128c4.71,0,8.533-3.823,8.533-8.533c0-4.71-3.823-8.533-8.533-8.533H93.867c-4.71,0-8.533,3.823-8.533,8.533C85.333,115.644,89.156,119.467,93.867,119.467z" />
        <path d="M179.2,136.533c4.71,0,8.533-3.823,8.533-8.533V68.267h38.665c1.041,0,3.686,1.493,4.702,3.806l25.6,59.307c1.391,3.226,4.531,5.154,7.834,5.154c1.135,0,2.278-0.222,3.379-0.7c4.326-1.869,6.323-6.886,4.454-11.213L246.75,65.271c-3.567-8.158-12.126-14.071-20.352-14.071h-38.665c-9.412,0-17.067,7.842-17.067,17.493V128C170.667,132.71,174.49,136.533,179.2,136.533z" />
        <path d="M187.733,204.8c0-9.412-7.654-17.067-17.067-17.067c-9.412,0-17.067,7.654-17.067,17.067s7.654,17.067,17.067,17.067C180.079,221.867,187.733,214.212,187.733,204.8z" />
        <path d="M110.933,68.267H128c4.71,0,8.533-3.823,8.533-8.533c0-4.71-3.823-8.533-8.533-8.533h-17.067c-4.71,0-8.533,3.823-8.533,8.533C102.4,64.444,106.223,68.267,110.933,68.267z" />
        <path d="M324.267,187.733c-9.412,0-17.067,7.654-17.067,17.067s7.654,17.067,17.067,17.067c9.412,0,17.067-7.654,17.067-17.067S333.679,187.733,324.267,187.733z" />
        <path d="M349.867,290.133h102.4c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533h-102.4c-4.71,0-8.533,3.823-8.533,8.533S345.156,290.133,349.867,290.133z" />
        <path d="M102.4,204.8c0,9.412,7.654,17.067,17.067,17.067s17.067-7.654,17.067-17.067s-7.654-17.067-17.067-17.067S102.4,195.388,102.4,204.8z" />
        <path d="M290.133,204.8c0-9.412-7.654-17.067-17.067-17.067c-9.412,0-17.067,7.654-17.067,17.067s7.654,17.067,17.067,17.067C282.479,221.867,290.133,214.212,290.133,204.8z" />
        <path d="M459.213,205.201c-5.581-20.881-22.733-51.601-58.146-51.601H69.111l-14.874-37.171c-3.191-8.388-11.273-14.029-20.104-14.029H17.067C7.654,102.4,0,110.054,0,119.467V179.2c0,81.348,63.718,145.067,145.067,145.067c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533c-71.774,0-128-56.226-128-128v-59.733h17.067c1.212,0,3.311,0.811,4.207,3.174l17.067,42.667c1.297,3.234,4.437,5.359,7.927,5.359h296.934l6.92,27.674c0.085,0.333,0.188,0.666,0.307,0.99c5.35,13.901,16.648,22.536,29.483,22.536h21.154c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533h-21.154c-7.868,0-11.87-7.458-13.372-11.145l-5.743-22.989h23.202c34.825,0,42.479,42.291,42.786,44.075c0.683,4.105,4.241,7.125,8.414,7.125c23.526,0,42.667,19.14,42.667,42.667s-19.14,42.667-42.667,42.667H313.054l10.394-21.948c2.014-4.258,0.196-9.344-4.062-11.366c-4.25-2.005-9.344-0.205-11.366,4.062l-76.843,162.227c-0.93,2.031-3.541,3.558-4.779,3.558h-38.665v-153.6H281.6c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533H179.2c-4.71,0-8.533,3.823-8.533,8.533v162.133c0,9.412,7.654,17.067,17.067,17.067h38.665c8.158,0,16.691-5.683,20.25-13.414l58.317-123.119h147.302c32.939,0,59.733-26.795,59.733-59.733C512,233.95,488.892,208.649,459.213,205.201z" />
        <path d="M238.933,204.8c0-9.412-7.654-17.067-17.067-17.067c-9.412,0-17.067,7.654-17.067,17.067s7.654,17.067,17.067,17.067C231.279,221.867,238.933,214.212,238.933,204.8z" />
      </g>
    </SvgIcon>
  );
};

export default IconPlane;
