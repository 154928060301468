import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconHints = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26">
        <g>
          <path
            style={{ fill: "none" }}
            d="M160.8,172.667c-3.395,0.313-7,0.473-10.8,0.473c-3.82,0-7.412-0.166-10.8-0.477
			c0.487,5.025,0.672,10.408,0.662,15.75c5.777,3.889,12.574,6.793,20.437,8.359C160.018,188.849,160.055,180.341,160.8,172.667z"
          />
          <path
            style={{ fill: "none" }}
            d="M124.478,162.493c1.912,0.689,4.019,1.266,6.273,1.756c-0.114-0.508-0.232-1.008-0.359-1.494
			c-1.962-7.547-4.818-12.27-8.259-13.66c-2.585-1.043-5.269-0.047-6.927,0.854c0.659,3.109,1.525,6.193,2.606,9.215
			c0.183,0.127,0.355,0.26,0.549,0.385c1.452,0.945,1.979,2.773,1.341,4.318c0.096,0.217,0.195,0.43,0.292,0.643
			C120.705,162.749,122.679,161.847,124.478,162.493z"
          />
          <path
            style={{ fill: "none" }}
            d="M133.829,228.987h-10.152c-0.023,3.811-0.043,7.914-0.05,11.572h52.745
			c-0.007-3.658-0.026-7.762-0.05-11.572H166.17H133.829z"
          />
          <path
            style={{ fill: "none" }}
            d="M125.747,259.222h48.505c1.054-3.365,1.712-7.225,1.98-11.568h-52.465
			C124.035,251.997,124.693,255.856,125.747,259.222z"
          />
          <path
            style={{ fill: "none" }}
            d="M122.419,169.28c2.745,4.869,6.156,9.393,10.271,13.346c-0.103-3.773-0.329-7.463-0.72-10.912
			C128.485,171.099,125.292,170.29,122.419,169.28z"
          />
          <path
            style={{ fill: "none" }}
            d="M114.049,142.683c2.865-1.117,6.701-1.795,10.741-0.164c5.687,2.297,9.881,8.506,12.467,18.451
			c0.368,1.416,0.688,2.912,0.968,4.473c3.62,0.395,7.539,0.604,11.774,0.604c4.446,0,8.344-0.221,11.772-0.596
			c0.28-1.563,0.601-3.063,0.97-4.48c0.84-3.232,1.858-6.102,3.027-8.523c0.851-1.766,2.97-2.504,4.736-1.652
			c1.764,0.852,2.504,2.971,1.653,4.734c-0.968,2.008-1.827,4.439-2.551,7.227c-0.127,0.488-0.247,0.992-0.362,1.504
			c5.58-1.207,9.339-2.844,11.795-4.334c1.676-1.012,3.856-0.48,4.872,1.195c1.015,1.676,0.48,3.855-1.195,4.871
			c-4.302,2.609-9.942,4.529-16.688,5.719c-0.916,8.066-0.926,17.459-0.576,26.092c11.441,1.006,24.869-0.65,40.407-5.854
			c12.513-13.916,19.787-32.197,19.787-51.9c0-41.104-32.111-74.83-72.562-77.461c-15.05,15.652-28.79,32.117-35.797,45.98
			C114.574,117.886,112.687,130.188,114.049,142.683z M172.081,144.286c4.899-3.645,11.093-3.664,16.995-0.055
			c4.974,3.037,6.229,9.24,2.981,14.748c-0.661,1.121-1.844,1.744-3.059,1.744c-0.612,0-1.232-0.158-1.798-0.49
			c-1.688-0.996-2.249-3.17-1.254-4.857c1.269-2.152,1.05-4.104-0.569-5.092c-2.374-1.451-5.87-2.682-9.062-0.307
			c-1.571,1.17-3.793,0.844-4.962-0.729C170.183,147.679,170.509,145.456,172.081,144.286z"
          />
          <path
            style={{ fill: "none" }}
            d="M150,276.452c9.619,0,16.625-3.4,20.991-10.137h-41.981C133.375,273.052,140.381,276.452,150,276.452z
			"
          />
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M167.453,197.802c0.418,10.293,1.349,19.5,1.868,24.092h6.952c-0.027-3.67-0.05-6.186-0.051-6.25
				c-0.014-1.469,0.879-2.793,2.245-3.332c11.549-4.555,21.508-11.594,29.393-20.363
				C192.322,197.151,178.895,198.808,167.453,197.802z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M122.419,169.28c-0.112-0.041-0.234-0.074-0.345-0.113c-1.843-0.664-2.798-2.697-2.135-4.539
				c0.015-0.043,0.04-0.078,0.056-0.119c-0.098-0.213-0.196-0.426-0.292-0.643c-0.083,0.203-0.181,0.4-0.304,0.59
				c-1.069,1.641-3.265,2.105-4.908,1.037c-5.373-3.496-8.416-8.195-8.348-12.889c0.05-3.498,1.793-6.549,4.782-8.375
				c0.814-0.496,1.878-1.063,3.123-1.547c-1.362-12.494,0.525-24.797,5.239-34.115c7.008-13.863,20.748-30.328,35.797-45.98
				c-1.683-0.109-3.375-0.186-5.085-0.186c-42.815,0-77.647,34.832-77.647,77.646c0,32.117,19.304,60.482,49.179,72.264
				c1.366,0.539,2.259,1.863,2.245,3.332c0,0.064-0.023,2.58-0.051,6.25h6.952c0.769-6.797,2.436-23.707,2.012-39.268
				C128.575,178.673,125.164,174.149,122.419,169.28z M128.737,76.015c1.048-0.344,2.002-0.637,2.917-0.893
				c1.888-0.525,3.844,0.574,4.371,2.461c0.528,1.887-0.574,3.844-2.46,4.371c-0.878,0.246-1.749,0.516-2.615,0.801
				c-0.367,0.119-0.74,0.178-1.107,0.178c-1.491,0-2.879-0.947-3.369-2.441C125.862,78.63,126.876,76.626,128.737,76.015z
				 M89.963,133.55c-0.195,1.818-1.733,3.168-3.522,3.168c-0.126,0-0.254-0.006-0.383-0.02c-1.947-0.209-3.357-1.957-3.148-3.904
				c1.173-10.939,5.087-21.561,11.318-30.717c6.083-8.934,13.942-16.07,23.36-21.219c1.72-0.939,3.874-0.309,4.813,1.41
				c0.939,1.719,0.308,3.875-1.411,4.814c-8.423,4.604-15.454,10.992-20.898,18.984C94.516,114.263,91.013,123.767,89.963,133.55z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M139.861,188.413c-0.024,13.627-1.31,26.99-2.038,33.48h24.353
				c-0.578-5.148-1.506-14.621-1.878-25.121C152.436,195.206,145.638,192.302,139.861,188.413z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M113.236,152.706c-0.03,2.047,1.679,4.426,4.576,6.457c-1.082-3.021-1.947-6.105-2.606-9.215
				c-0.21,0.115-0.407,0.229-0.583,0.336C113.73,150.829,113.251,151.667,113.236,152.706z"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M50.163,142.081c0-1.959-1.588-3.547-3.547-3.547H23.848c-1.959,0-3.547,1.588-3.547,3.547
				s1.588,3.547,3.547,3.547h22.768C48.575,145.628,50.163,144.04,50.163,142.081z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M63.4,196.536l-18.887,12.719c-1.625,1.094-2.055,3.299-0.961,4.924
				c0.685,1.016,1.805,1.564,2.945,1.564c0.681,0,1.37-0.195,1.978-0.605l18.887-12.719c1.625-1.094,2.055-3.297,0.961-4.922
				C67.229,195.874,65.025,195.44,63.4,196.536z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M42.576,73.667l18.821,12.811c0.611,0.416,1.305,0.615,1.992,0.615
				c1.135,0,2.249-0.543,2.936-1.551c1.102-1.619,0.683-3.826-0.937-4.928L46.567,67.804c-1.62-1.105-3.826-0.682-4.928,0.936
				C40.538,70.36,40.957,72.565,42.576,73.667z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M103.97,47.308c0.578,1.342,1.885,2.145,3.259,2.145c0.468,0,0.944-0.094,1.401-0.291
				c1.799-0.775,2.629-2.861,1.854-4.66l-9.009-20.908c-0.775-1.799-2.862-2.635-4.661-1.854c-1.799,0.773-2.629,2.861-1.854,4.66
				L103.97,47.308z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M131.653,75.122c-0.914,0.256-1.868,0.549-2.917,0.893c-1.861,0.611-2.875,2.615-2.263,4.477
				c0.49,1.494,1.878,2.441,3.369,2.441c0.367,0,0.74-0.059,1.107-0.178c0.866-0.285,1.736-0.555,2.615-0.801
				c1.886-0.527,2.988-2.484,2.46-4.371C135.497,75.696,133.542,74.597,131.653,75.122z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M117.588,80.858c-9.417,5.148-17.276,12.285-23.36,21.219
				c-6.231,9.156-10.145,19.777-11.318,30.717c-0.209,1.947,1.201,3.695,3.148,3.904c0.129,0.014,0.256,0.02,0.383,0.02
				c1.789,0,3.327-1.35,3.522-3.168c1.049-9.783,4.552-19.287,10.129-27.482c5.444-7.992,12.476-14.381,20.898-18.984
				c1.719-0.939,2.351-3.096,1.411-4.814C121.462,80.55,119.308,79.919,117.588,80.858z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M176.315,149.978c3.192-2.375,6.688-1.145,9.062,0.307c1.62,0.988,1.838,2.939,0.569,5.092
				c-0.995,1.688-0.434,3.861,1.254,4.857c0.565,0.332,1.186,0.49,1.798,0.49c1.214,0,2.397-0.623,3.059-1.744
				c3.248-5.508,1.993-11.711-2.981-14.748c-5.902-3.609-12.096-3.59-16.995,0.055c-1.572,1.17-1.897,3.393-0.728,4.963
				C172.521,150.821,174.744,151.147,176.315,149.978z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M276.152,138.534h-22.769c-1.959,0-3.547,1.588-3.547,3.547s1.588,3.547,3.547,3.547h22.769
				c1.959,0,3.547-1.588,3.547-3.547S278.111,138.534,276.152,138.534z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M255.485,209.255l-18.887-12.719c-1.625-1.096-3.829-0.662-4.923,0.961
				c-1.094,1.625-0.664,3.828,0.961,4.922l18.887,12.719c0.607,0.41,1.296,0.605,1.978,0.605c1.141,0,2.26-0.549,2.945-1.564
				C257.541,212.554,257.11,210.349,255.485,209.255z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M236.609,87.093c0.687,0,1.381-0.199,1.992-0.615l18.821-12.811
				c1.619-1.102,2.039-3.307,0.937-4.928c-1.102-1.617-3.308-2.037-4.928-0.936L234.61,80.614c-1.619,1.102-2.039,3.309-0.937,4.928
				C234.36,86.55,235.475,87.093,236.609,87.093z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M180.876,45.169c0.345,0.105,0.694,0.156,1.037,0.156c1.519,0,2.924-0.984,3.391-2.514
				l6.648-21.777c0.572-1.873-0.483-3.855-2.356-4.428c-1.873-0.566-3.856,0.484-4.428,2.357l-6.648,21.777
				C177.947,42.614,179.002,44.597,180.876,45.169z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M234.741,140.048c0-46.725-38.015-84.74-84.741-84.74s-84.741,38.016-84.741,84.74
				c0,34.23,20.095,64.557,51.404,77.932c-0.013,1.637-0.032,4-0.051,6.719c-0.051,0.24-0.081,0.488-0.081,0.742
				c0,0.24,0.025,0.475,0.071,0.701c-0.036,5.379-0.071,11.836-0.071,16.916c0,0.311,0.01,0.611,0.013,0.918
				c-0.002,0.045-0.013,0.086-0.013,0.131c0,0.061,0.015,0.117,0.018,0.176c0.112,7.637,1.273,14.229,3.413,19.723
				c0.026,0.07,0.049,0.141,0.079,0.209c4.988,12.6,15.202,19.332,29.959,19.332c14.757,0,24.971-6.732,29.959-19.332
				c0.031-0.068,0.053-0.139,0.08-0.209c2.14-5.494,3.301-12.088,3.413-19.723c0.003-0.059,0.018-0.115,0.018-0.176
				c0-0.045-0.011-0.086-0.013-0.131c0.003-0.307,0.013-0.607,0.013-0.918c0-5.08-0.035-11.537-0.071-16.916
				c0.046-0.227,0.071-0.461,0.071-0.701c0-0.254-0.03-0.502-0.081-0.742c-0.019-2.719-0.038-5.082-0.051-6.719
				C214.646,204.604,234.741,174.278,234.741,140.048z M227.647,140.048c0,19.703-7.274,37.984-19.787,51.9
				c-7.885,8.77-17.844,15.809-29.393,20.363c-1.366,0.539-2.259,1.863-2.245,3.332c0,0.064,0.023,2.58,0.051,6.25h-6.952
				c-0.52-4.592-1.45-13.799-1.868-24.092c-0.351-8.633-0.34-18.025,0.576-26.092c6.746-1.189,12.386-3.109,16.688-5.719
				c1.675-1.016,2.21-3.195,1.195-4.871c-1.016-1.676-3.195-2.207-4.872-1.195c-2.456,1.49-6.215,3.127-11.795,4.334
				c0.115-0.512,0.234-1.016,0.362-1.504c0.724-2.787,1.583-5.219,2.551-7.227c0.851-1.764,0.111-3.883-1.653-4.734
				c-1.766-0.852-3.885-0.113-4.736,1.652c-1.169,2.422-2.188,5.291-3.027,8.523c-0.369,1.418-0.69,2.918-0.97,4.48
				c-3.428,0.375-7.326,0.596-11.772,0.596c-4.235,0-8.154-0.209-11.774-0.604c-0.28-1.561-0.601-3.057-0.968-4.473
				c-2.586-9.945-6.781-16.154-12.467-18.451c-4.04-1.631-7.876-0.953-10.741,0.164c-1.245,0.484-2.309,1.051-3.123,1.547
				c-2.989,1.826-4.732,4.877-4.782,8.375c-0.068,4.693,2.975,9.393,8.348,12.889c1.643,1.068,3.839,0.604,4.908-1.037
				c0.123-0.189,0.221-0.387,0.304-0.59c0.637-1.545,0.111-3.373-1.341-4.318c-0.193-0.125-0.366-0.258-0.549-0.385
				c-2.897-2.031-4.606-4.41-4.576-6.457c0.015-1.039,0.494-1.877,1.387-2.422c0.175-0.107,0.372-0.221,0.583-0.336
				c1.658-0.9,4.342-1.896,6.927-0.854c3.441,1.391,6.297,6.113,8.259,13.66c0.126,0.486,0.245,0.986,0.359,1.494
				c-2.254-0.49-4.361-1.066-6.273-1.756c-1.799-0.646-3.773,0.256-4.483,2.016c-0.016,0.041-0.041,0.076-0.056,0.119
				c-0.664,1.842,0.292,3.875,2.135,4.539c0.111,0.039,0.233,0.072,0.345,0.113c2.873,1.01,6.066,1.818,9.551,2.434
				c0.391,3.449,0.617,7.139,0.72,10.912c0.424,15.561-1.243,32.471-2.012,39.268h-6.952c0.027-3.67,0.05-6.186,0.051-6.25
				c0.014-1.469-0.879-2.793-2.245-3.332c-29.875-11.781-49.179-40.146-49.179-72.264c0-42.814,34.833-77.646,77.647-77.646
				c1.71,0,3.402,0.076,5.085,0.186C195.536,65.218,227.647,98.944,227.647,140.048z M139.861,188.413
				c0.01-5.342-0.175-10.725-0.662-15.75c3.388,0.311,6.98,0.477,10.8,0.477c3.8,0,7.406-0.16,10.8-0.473
				c-0.745,7.674-0.782,16.182-0.502,24.105c0.372,10.5,1.3,19.973,1.878,25.121h-24.353
				C138.552,215.403,139.837,202.04,139.861,188.413z M176.372,240.56h-52.745c0.007-3.658,0.026-7.762,0.05-11.572h10.152h32.341
				h10.152C176.346,232.798,176.365,236.901,176.372,240.56z M123.767,247.653h52.465c-0.268,4.344-0.926,8.203-1.98,11.568h-48.505
				C124.693,255.856,124.035,251.997,123.767,247.653z M150,276.452c-9.619,0-16.625-3.4-20.991-10.137h41.981
				C166.624,273.052,159.618,276.452,150,276.452z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconHints;
