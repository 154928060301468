import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconTools = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 512 512">
      <g style={{ fill: getIconColor(active, theme) }}>
        <g>
          <g>
            <path
              d="M368.41,415.663l-52.782-52.781c-0.865-0.865-1.921-1.517-3.081-1.905l-17.842-5.947
			c-2.834-0.944-5.958-0.207-8.069,1.905l-4.832,4.832l-4.521-4.521l11.978-11.978c19.947,7.93,42.695,3.396,58.181-12.09
			c16.47-16.471,20.566-41.548,10.191-62.4c-2.358-4.739-8.885-5.814-12.635-2.064l-29.894,29.895l-9.665-9.664l29.895-29.895
			c3.75-3.751,2.677-10.277-2.064-12.636c-20.852-10.374-45.93-6.278-62.399,10.191c-15.487,15.486-20.025,38.234-12.09,58.183
			l-11.978,11.978l-25.215-25.215l9.293-9.293c3.182-3.183,2.998-8.565-0.396-11.522l-46.091-40.144
			c-3.127-2.725-7.825-2.561-10.754,0.37l-20.815,20.815c-2.931,2.931-3.092,7.63-0.371,10.754l40.144,46.092
			c2.956,3.395,8.337,3.58,11.522,0.397l6.318-6.319l25.215,25.214l-31.492,31.491c-19.947-7.933-42.695-3.395-58.181,12.09
			c-16.471,16.47-20.566,41.547-10.191,62.4c2.358,4.739,8.885,5.814,12.635,2.064l29.894-29.895l9.664,9.664l-29.894,29.894
			c-3.75,3.751-2.678,10.277,2.063,12.636c7.681,3.822,15.933,5.679,24.119,5.679c14.037-0.001,27.876-5.467,38.28-15.871
			c15.487-15.486,20.024-38.234,12.09-58.182l31.491-31.491l4.521,4.521l-4.832,4.832c-2.112,2.112-2.849,5.236-1.905,8.07
			l5.947,17.842c0.387,1.161,1.04,2.215,1.905,3.083l52.782,52.782c4.612,4.611,10.669,6.917,16.727,6.917
			c6.058,0,12.116-2.306,16.728-6.918l10.407-10.408c4.467-4.467,6.928-10.407,6.928-16.726
			C375.338,426.071,372.878,420.132,368.41,415.663z M198.94,301.896l-29.762-34.172l10.407-10.407l34.171,29.762L198.94,301.896z
			 M219.61,392.614c-2.445,2.445-3.012,6.2-1.397,9.258c7.903,14.964,5.164,33.067-6.815,45.045
			c-8.845,8.847-21.192,12.652-33.084,10.783l26.393-26.393c3.079-3.08,3.079-8.072,0-11.151l-20.815-20.815
			c-3.079-3.078-8.072-3.078-11.15,0l-26.394,26.394c-1.872-11.894,1.937-24.24,10.782-33.084
			c11.98-11.979,30.081-14.717,45.045-6.815c3.059,1.615,6.812,1.048,9.257-1.396l35.37-35.37l8.177,8.177L219.61,392.614z
			 M266.131,346.092l-8.177-8.177l15.857-15.855c2.445-2.445,3.012-6.2,1.397-9.258c-7.903-14.965-5.164-33.068,6.815-45.045
			c8.845-8.845,21.191-12.656,33.084-10.782l-26.394,26.393c-3.036,3.037-3.036,8.115,0,11.151l20.815,20.815
			c3.079,3.078,8.072,3.078,11.15,0l26.394-26.394c1.872,11.894-1.938,24.24-10.782,33.084c-11.979,11.98-30.08,14.718-45.046,6.815
			c-3.057-1.615-6.812-1.048-9.257,1.396L266.131,346.092z M357.258,437.964v0.001l-10.407,10.407
			c-3.074,3.076-8.077,3.075-11.152,0l-51.466-51.466l-3.817-11.453l13.924-13.922l11.453,3.818l51.465,51.464
			C360.297,429.852,360.299,434.926,357.258,437.964z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M509.217,129.084l-69.388-58.875c-1.426-1.209-3.234-1.872-5.102-1.872h-34.168V50.99c0-4.356-3.53-7.885-7.885-7.885
			h-19.45V8.936c0-4.356-3.53-7.885-7.885-7.885H146.661c-4.355,0-7.885,3.529-7.885,7.885v34.168h-19.45
			c-4.355,0-7.885,3.529-7.885,7.885v17.347H75.17c-1.91,0-3.755,0.693-5.193,1.95L2.692,129.162C0.982,130.66,0,132.822,0,135.096
			v44.156c0,4.356,3.53,7.885,7.885,7.885h13.142v29.963c0,4.356,3.53,7.885,7.885,7.885c4.355,0,7.885-3.529,7.885-7.885v-29.963
			h76.747v27.86c0,4.356,3.53,7.885,7.885,7.885h50.464c4.355,0,7.885-3.529,7.885-7.885v-27.86h152.444v27.86
			c0,4.356,3.53,7.885,7.885,7.885h50.464c4.355,0,7.885-3.529,7.885-7.885v-27.86h76.747v308.041H36.797V259.154
			c0-4.356-3.53-7.885-7.885-7.885c-4.355,0-7.885,3.529-7.885,7.885v243.91c0,4.356,3.53,7.885,7.885,7.885h454.177
			c4.355,0,7.885-3.529,7.885-7.885V187.138h13.142c4.355,0,7.885-3.529,7.885-7.885v-44.156
			C512,132.781,510.982,130.582,509.217,129.084z M384.789,58.875v9.462h-36.797v-9.462H384.789z M127.211,58.875h36.797v9.462
			h-36.797V58.875z M164.008,207.113h-34.694v-62.029h34.694V207.113z M382.686,207.113h-34.694v-62.029h34.694V207.113z
			 M496.23,171.368h-97.774v-34.168c0-4.356-3.53-7.885-7.885-7.885h-50.464c-4.355,0-7.885,3.529-7.885,7.885v34.168H179.778
			v-34.168c0-4.356-3.53-7.885-7.885-7.885h-50.464c-4.355,0-7.885,3.529-7.885,7.885v34.168H15.77v-32.693l62.363-54.567h186.278
			c4.355,0,7.885-3.529,7.885-7.885c0-4.356-3.53-7.885-7.885-7.885h-84.632V50.99c0-4.356-3.53-7.885-7.885-7.885h-17.347V16.821
			h202.908v26.283h-17.347c-4.355,0-7.885,3.529-7.885,7.885v17.347h-17.347c-4.355,0-7.885,3.529-7.885,7.885
			c0,4.356,3.53,7.885,7.885,7.885h116.958l64.397,54.641V171.368z"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconTools;
