import React from "react";
import InquiryToCustomer from "../../../types/Entities/InquiryToCustomer";
import InquiryToCustomerAppointmentBox from "./InquiryToCustomerAppointmentBox";
import InquiryToCustomerInformationAndDocuments from "./InquiryToCustomerInformationAndDocuments";
import InquiryToCustomerLinkBox from "./InquiryToCustomerLinkBox";
import InquiryToCustomerDecisionBox from "./InquiryToCustomerDecisionBox";
import { AbstractCase } from "../../../types/Entities/AbstractCase";

type InquiryToCustomerBoxProps = {
  inquiryToCustomer: InquiryToCustomer;
  product: AbstractCase;
};

export default function InquiryToCustomerBox({ inquiryToCustomer, product }: InquiryToCustomerBoxProps) {
  switch (inquiryToCustomer.inquiryType) {
    case "appointment":
      return <InquiryToCustomerAppointmentBox inquiryToCustomer={inquiryToCustomer} product={product} />;
    case "decision":
      return <InquiryToCustomerDecisionBox inquiryToCustomer={inquiryToCustomer} product={product} />;
    case "link":
      return <InquiryToCustomerLinkBox inquiryToCustomer={inquiryToCustomer} />;
    default:
      return <InquiryToCustomerInformationAndDocuments inquiryToCustomer={inquiryToCustomer} product={product} />;
  }
}
