import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";

const IconDataInquiry = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 225 225">
      <g style={{ fill: "none", stroke: theme.palette.iconColor.main, strokeLinecap: "round" }}>
        <path style={{ strokeWidth: "5px" }} d="M149.83,119.82V28S146.78,5.28,123.47,2.5H14.7S2.58,2.5,2.58,14.63V43.88" />
        <path style={{ strokeWidth: "5.15px" }} d="M2.58,53.05V190.54s0,12.09,12.12,12.09h123s12.12,0,12.12-12.12V153.63" />
        <path style={{ strokeWidth: "3.11px" }} d="M26.89,90.4H124.22" />
        <path style={{ strokeWidth: "3.11px" }} d="M26.89,104.8H124.22" />
        <path style={{ strokeWidth: "3.11px" }} d="M26.89,118.27H124.22" />
        <path style={{ strokeWidth: "3.11px" }} d="M26.89,132.66H124.22" />
        <path style={{ strokeWidth: "3.11px" }} d="M26.89,146.7H124.22" />
        <path style={{ strokeWidth: "3.11px" }} d="M26.89,161.1H109.81" />
      </g>
      <g style={{ fill: "none", stroke: theme.palette.iconColor.main, strokeWidth: "5px", strokeLinecap: "round" }}>
        <path d="M122.56,4.57V24.81s0,7.11,7.11,7.11h19" />
        <path d="M101.43,186.42l21.95-7.9s43.81-43.32,46.79-46.6,1.35-6.16-.58-8.09l-3.17-3.2-.42-.51-3.17-3.18c-1.93-1.92-4.82-3.56-8.09-.57s-44.91,45.08-44.91,45.08a12.46,12.46,0,0,0-2.51,4l-7.08,19.69" />
      </g>
      <polygon style={{ fill: theme.palette.iconColor.main }} points="100.94 178.91 108.39 185.51 96.9 189.78 100.94 178.91" />
      <g style={{ fill: "none", stroke: theme.palette.iconColor.main, strokeWidth: "3.09px" }}>
        <path d="M108.59,164a45.55,45.55,0,0,1,14.21,13.87" />
        <path d="M145.76,125.46a49.85,49.85,0,0,1,15.56,15.17" />
      </g>
      <path
        style={{ fill: "none", stroke: "#ff3c1f", strokeWidth: "2.06px" }}
        d="M51.09,190.25a3,3,0,0,0,.94,0l1.36-.37a59.61,59.61,0,0,0,6.35-2.34,27,27,0,0,0,10.19-7.38c1.19-1.48,3.72-4.55,1.95-6.4-1.62-1.68-5-1.09-7.14-.47a17.88,17.88,0,0,0-8.32,5.22,7.73,7.73,0,0,0-1.92,7.19c.69,2.25,2.89,4.26,5.75,5.67a19.94,19.94,0,0,0,12.14,1.7c4.46-.73,8.79-2.73,11-5.29,1.06-1.21,3.24-4.52.66-5.85-2.4-1.23-5.48.84-6.69,1.83-2,1.66-3.5,4.3-2.47,6.46.78,1.63,2.76,2.81,5.48,2.88,6.66.17,8.76-5,10.63-6.21,0,0,1.87-1.2,2.34-1.12s0,1.44,0,1.79c0,.73-.16,2.09,1.38,2.34a4.72,4.72,0,0,0,3.08-.9c.1,0,.9-.35.88-.53"
      />
      <path style={{ fill: "none", stroke: theme.palette.iconColor.main, strokeWidth: "2.06px" }} d="M124.47,169.49l29.99-29.71" />
      <text style={{ fontSize: "12px", fontFamily: "Roboto-Regular, Roboto", letterSpacing: "0.15em" }} x="24.33" y="76.29">
        Datenauskunft
      </text>
    </SvgIcon>
  );
};

export default IconDataInquiry;
