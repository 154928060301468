import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import UploadBox from "./UploadBox";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import CheckIcon from "../../../assets/icons/common/toggleable/IconCheck";
import CrossIcon from "../../../assets/icons/common/toggleable/IconClose";
import Pad from "../../Wizard/Pad";
import _get from "lodash/get";
import { getNoteStep, handleDocumentUploadDescriptionSave } from "../../../services/documentUploadFormService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../../services/reactQuery/reactQueryService";
import structuredClone from "@ungap/structured-clone";
import { MediaObjectCombinationRequest } from "../../../types/Entities/MediaObject/MediaObjectCombinationRequest";
import { getProductData } from "../../../services/productService";

const FURTHER_DOCUMENT_LIMIT = 9;

type FurtherUploadFormStepProps = {
  product: AbstractCase;
  currentStep: number;
  setCurrentStep: Function;
  steps: number;
  setSteps: Function;
  setIsLoading: Function;
  mediaObjectCombinationRequest: MediaObjectCombinationRequest;
};

export default function FurtherUploadFormStep({
  mediaObjectCombinationRequest,
  product,
  currentStep,
  setCurrentStep,
  steps,
  setSteps,
  setIsLoading,
}: FurtherUploadFormStepProps) {
  const [hasFurtherDocument, setHasFurtherDocument] = useState(!!_get(mediaObjectCombinationRequest, ["filesToCombine", currentStep], false));
  const [description, setDescription] = useState(_get(mediaObjectCombinationRequest, ["filesToCombine", currentStep, "description"], ""));
  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource, {
    onSuccess: () => queryClient.invalidateQueries(queryKeys.item(getProductData(product.name, "apiUri"), product.id)),
  });

  useEffect(() => {
    const apiUrl = "/media_object_combination_requests";
    let filesToCombineClone = structuredClone(mediaObjectCombinationRequest.filesToCombine);
    if (hasFurtherDocument && !mediaObjectCombinationRequest.filesToCombine[currentStep]) {
      filesToCombineClone[currentStep] = { files: [], description: "", fieldName: "uploadedFile" };
      updateMutation.mutate({
        uri: apiUrl,
        id: mediaObjectCombinationRequest.id,
        data: {
          filesToCombine: filesToCombineClone,
        },
      });
    }
    if (!hasFurtherDocument && mediaObjectCombinationRequest.filesToCombine[currentStep]) {
      filesToCombineClone.splice(currentStep, 1);
      updateMutation.mutate({
        uri: apiUrl,
        id: mediaObjectCombinationRequest.id,
        data: {
          filesToCombine: filesToCombineClone,
        },
      });
    }
  }, [hasFurtherDocument]);

  const handleNextStepClick = async () => {
    if (hasFurtherDocument && currentStep < FURTHER_DOCUMENT_LIMIT) {
      setIsLoading(true);
      await handleDocumentUploadDescriptionSave(mediaObjectCombinationRequest, currentStep, description, updateMutation);
      setIsLoading(false);

      if (currentStep + 1 === getNoteStep(steps)) {
        //increase the general number of steps if the user presses next and the next step would be the note step
        setSteps((previousSteps: number) => previousSteps + 1);
      }
      setCurrentStep((previousStep: number) => previousStep + 1);
      return;
    }
    setCurrentStep(getNoteStep(steps));
  };

  const hasDocument = _get(mediaObjectCombinationRequest, ["filesToCombine", currentStep, "files"], []).length > 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h4"} sx={{ textAlign: "center" }}>
          Weitere Dateien
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"subtitle2"} sx={{ textAlign: "center" }}>
          Möchten Sie uns eine weitere Datei zukommen lassen?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Pad
          labelText={"Ja"}
          onChange={() => setHasFurtherDocument(true)}
          type={"radio"}
          active={hasFurtherDocument}
          error={false}
          name={"yes"}
          labelIcon={<CheckIcon />}
          labelIconActive={<CheckIcon active />}
        />
      </Grid>
      <Grid item xs={12}>
        <Pad
          labelText={"Nein"}
          onChange={() => setHasFurtherDocument(false)}
          type={"radio"}
          active={!hasFurtherDocument}
          error={false}
          name={"no"}
          labelIcon={<CrossIcon />}
          labelIconActive={<CrossIcon active />}
        />
      </Grid>
      {hasFurtherDocument && (
        <Grid item xs={12}>
          <UploadBox
            product={product}
            mediaObjectCombinationRequest={mediaObjectCombinationRequest}
            currentStep={currentStep}
            description={description}
            setDescription={setDescription}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <Button fullWidth onClick={() => setCurrentStep((currentStep: number) => currentStep - 1)}>
          Zurück
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button disabled={hasFurtherDocument && (!hasDocument || description.length < 5)} fullWidth variant={"contained"} onClick={handleNextStepClick}>
          Weiter
        </Button>
      </Grid>
    </Grid>
  );
}
