/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React from "react";
import InquiryToCustomer from "../../../types/Entities/InquiryToCustomer";
import { Box, Stack, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import TextContainer from "../../Container/TextContainer";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

type InquiryToCustomerLinkBoxProps = {
  inquiryToCustomer: InquiryToCustomer;
};

export default function InquiryToCustomerLinkBox({ inquiryToCustomer }: InquiryToCustomerLinkBoxProps) {
  return (
    <Paper sx={{ padding: "1rem" }}>
      <TextContainer>
        <Stack spacing={2}>
          <Typography variant={"h4"} sx={{ textAlign: "center" }}>
            Benötigte Rückmeldung
          </Typography>
          {!!inquiryToCustomer.inquiryTextInformation && (
            <Box sx={{ all: "initial" }}>
              <Typography dangerouslySetInnerHTML={{ __html: inquiryToCustomer.inquiryTextInformation }} />
            </Box>
          )}
          <Button fullWidth={true} component={Link} to={`${inquiryToCustomer.linkUrl}`} variant={"contained"} color={"primary"}>
            Jetzt Rückmeldung geben
          </Button>
        </Stack>
      </TextContainer>
    </Paper>
  );
}
