/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import FormFader from "../Common/Fader/FormFader";
import Paper from "@mui/material/Paper";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import nationalities from "../../../services/nationalities";
import Person from "../Common/Person/Person";
import { useForm } from "../../../provider/Form/FormProvider";
import Address from "../Common/Address/Address";
import ValidatorAutocomplete from "../../FormFields/ValidatorAutocomplete";
import { isRequired } from "../../../services/validationRules";
import QuestionIcon from "../../../assets/icons/common/toggleable/IconQuestionmark";
import HouseIcon from "../../../assets/icons/provision/toggleable/IconHouse";
import PersonIcon from "../../../assets/icons/provision/toggleable/IconPerson";
import Group from "../../Wizard/Group";
import _get from "lodash/get";
import Contact from "../Common/Contact/Contact";
import Typography from "@mui/material/Typography";
import FormLegend from "../Common/FormLegend/FormLegend";
import { translate } from "../../../services/translations/translations";
import FormSubmitProps from "../../../types/FormSubmitProps";
import { Box, Divider, ListItem } from "@mui/material";
import { FormChangeEvent, FormDataTypes } from "../../../types/FormDataTypes";
import wizardStyle from "../wizardStyle";

type PersonalProps = {
  submitProps: FormSubmitProps;
  personType: "client" | "partner";
};

export default function Personal({ submitProps, personType }: PersonalProps) {
  const { values, initialValues, handleChange, handleSubmit }: FormDataTypes = useForm();
  const commonAddressType = _get(values, "commonAddressType");

  useEffect(() => {
    const sourceAddress =
      !commonAddressType || commonAddressType === "commonAddress" ? initialValues.commonAddress : values[commonAddressType].residenceAddress;
    handleChange({
      target: {
        name: "commonAddress",
        value: {
          streetAddress: sourceAddress.streetAddress,
          postalCode: sourceAddress.postalCode,
          addressLocality: sourceAddress.addressLocality,
        },
        type: "text",
      },
    } as FormChangeEvent);
  }, [commonAddressType]);

  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} sx={wizardStyle.headline}>
              Persönliche Angaben
            </Typography>
          </Hidden>
          <Typography variant={"h4"} sx={wizardStyle.subheadline}>
            {personType === "client" ? "Ihre Angaben" : "Angaben zum Ehepartner"}
          </Typography>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Person
              path={personType}
              headline={personType === "client" ? "Wie heißen Sie?" : "Wie heißt Ihr Ehepartner?"}
              showDiverseGenderOption={personType === "client"}
            />
            <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
              <FormLegend>{translate(`divorce.${personType}.nationality.question`, values)}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorAutocomplete
                label={translate(`divorce.${personType}.nationality.label`, values)}
                name={`${personType}.nationality`}
                options={nationalities}
                validators={[{ validator: isRequired, message: "Bitte geben Sie die Staatsangehörigkeit an" }]}
                renderOption={(props, nationality) => (
                  <React.Fragment key={nationality.label}>
                    <ListItem {...props}>{nationality.label}</ListItem>
                    {nationality.hasDivider ? <Divider sx={{ borderBottomWidth: 2 }} /> : null}
                  </React.Fragment>
                )}
              />
            </Grid>
            <Address path={`${personType}.residenceAddress`} headline={personType === "client" ? "Wo wohnen Sie?" : "Wo wohnt Ihr Ehepartner?"} />
            {personType === "client" && (
              <>
                <Group
                  type="radio"
                  name="commonAddressType"
                  question={translate("divorce.commonAddressType.question", values)}
                  options={[
                    {
                      labelText: translate("divorce.commonAddressType.options.client.label", values),
                      labelIcon: <HouseIcon />,
                      labelIconActive: <HouseIcon active />,
                      value: "client",
                    },
                    {
                      labelText: translate("divorce.commonAddressType.options.partner.label", values),
                      labelIcon: <PersonIcon />,
                      labelIconActive: <PersonIcon active />,
                      value: "partner",
                    },
                    {
                      labelText: translate("divorce.commonAddressType.options.commonAddress.label", values),
                      labelIcon: <QuestionIcon />,
                      labelIconActive: <QuestionIcon active />,
                      value: "commonAddress",
                    },
                  ]}
                />
                <Address path="commonAddress" headline="" />
                <Contact />
              </>
            )}
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
