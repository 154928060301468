import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconHouse = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0  176.78 206.44">
      <defs>
        <clipPath id="clip-path" transform="translate(-56.16 -35.52)">
          <rect x="56.23" y="35.63" width="176.65" height="206.27" style={{ fill: "none" }} />
        </clipPath>
      </defs>
      <g style={{ clipPath: "url(#clip-path)" }}>
        <polyline
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          points="17.32 103.75 17.32 203.94 69.07 203.94 69.07 139.63 107.02 139.63"
        />
      </g>
      <line
        style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
        x1="159.14"
        y1="103.75"
        x2="159.14"
        y2="161.2"
      />
      <g style={{ clipPath: "url(#clip-path)" }}>
        <line
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          x1="2.5"
          y1="106.53"
          x2="37.86"
          y2="63.99"
        />
        <path
          style={{ fill: getIconColor(active, theme) }}
          d="M154.21,209.86a2.28,2.28,0,1,0-2.27-2.28,2.27,2.27,0,0,0,2.27,2.28"
          transform="translate(-56.16 -35.52)"
        />
      </g>
      <polyline
        style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3px", strokeLinecap: "round" }}
        points="126.42 37.5 126.42 22.4 146.96 22.4 146.96 64.41"
      />
      <line
        style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3px", strokeLinecap: "round" }}
        x1="29.13"
        y1="107.28"
        x2="147.65"
        y2="107.28"
      />
      <g style={{ clipPath: "url(#clip-path)" }}>
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3px", strokeLinecap: "round" }}
          d="M144.42,119.74a12.21,12.21,0,1,0-12.21-12.2A12.21,12.21,0,0,0,144.42,119.74Z"
          transform="translate(-56.16 -35.52)"
        />
        <polyline
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          points="159.14 171.21 159.14 203.94 107.38 203.94 107.38 139.63 69.43 139.63"
        />
        <polyline
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          points="43.95 56.69 87.81 3.9 174.28 107.19"
        />
      </g>
    </SvgIcon>
  );
};

export default IconHouse;
