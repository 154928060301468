import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconDefend = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 512 512">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path d="M461.2,171.5c-7.7,0-14.8,2.4-20.6,6.6V72.7c0.2-29.3-34-46.5-57.4-29.3v-7.3C383.2,16.2,367,0,347,0 s-36.2,16.2-36.2,36.1v7.3c-23.4-17.2-57.5,0-57.4,29.3v43.9c-23.4-17.2-57.5,0-57.4,29.3l0,113.6c-38.8-16.4-86.9-11.9-122,12 c-8.1,5.4,0.2,17.9,8.3,12.5c75.8-51.3,181,5.4,180.2,96.9c-6.4,154.1-226,154.1-232.4,0c0-26.3,8.6-51.2,25-71.9 c6-7.6-5.8-16.9-11.8-9.3c-67.8,84.8-5.6,212.8,103,212.3c37.8,0,72-16.1,95.9-41.8c54.9,54.9,151.1,55.4,206.8,1.2 c7.1-6.6-3.2-17.6-10.3-10.9c-50.4,49.1-137.6,48.3-187-1.8c47.2-61.8,26.1-155.3-40.9-191.9l0-120.9c1.2-28,41.2-28,42.4,0v107.5 c0,9.7,15,9.7,15,0c0-43.7,0-137.8,0-180.7c0-11.6,9.5-21.1,21.2-21.1s21.2,9.5,21.2,21.1v148.1c0,9.7,15,9.7,15,0V72.7V36.1 c1.1-27.9,41.2-28,42.4,0c0,23.3,0,158.4,0,184.7c0,9.7,15,9.7,15,0V72.7c0-11.6,9.5-21.1,21.2-21.1s21.2,9.5,21.2,21.1 c-0.2,56.6,0.1,176.4,0,232.3c-36.4,3.8-64.9,34.6-64.9,72c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5c0-31.6,25.7-57.3,57.4-57.3 c4.1,0,7.5-3.4,7.5-7.5V207c0-11.3,9.2-20.5,20.6-20.5s20.6,9.2,20.6,20.5v152.7c0.1,25.6-6.5,50.9-20,72.8 c-4.9,7.9,7.4,16.6,12.8,7.9c14.8-24,22.6-52.4,22.2-80.6V207C496.8,187.4,480.8,171.5,461.2,171.5L461.2,171.5z" />
        <path d="M83.3,457.7c0.2,2.2,1.3,4.2,3.1,5.5c95.3,66.5,208.9-47.2,142.5-142.5c-3.5-3.8-7.3-4.1-11.4-0.9l-132,132 C83.9,453.4,83.1,455.5,83.3,457.7L83.3,457.7z M221.6,337c44.1,77.6-41.4,163.1-119,119L221.6,337z" />
        <path d="M206.5,298.4C111.2,231.9-2.4,345.6,64.1,440.9c2.7,3.8,8.1,4.1,11.4,0.9l132-132 C210.6,305.7,210.3,301.9,206.5,298.4z M71.3,424.7c-44.1-77.6,41.4-163.1,119-119L71.3,424.7z" />
      </g>
    </SvgIcon>
  );
};

export default IconDefend;
