/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React from "react";
import partnersSectionStyles from "./partnersSectionStyles";
import Container from "../../../Container/Container";
import Grid from "@mui/material/Grid";
import advocard from "../../../../assets/img/partner/advocard_300px_light.png";
import arag from "../../../../assets/img/partner/arag_300px_light.png";
import check from "../../../../assets/img/partner/check24_300px_light.png";
import wbs from "../../../../assets/img/partner/WBS_grey.png";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const PartnersSection = () => {
  return (
    <Box sx={partnersSectionStyles.partnerSectionContainer}>
      <Typography variant={"h2"}>Unsere Partner</Typography>
      <Container>
        <Grid container justifyContent={"space-evenly"} alignItems={"center"} spacing={4}>
          <Grid item md={2} xs={6} sx={partnersSectionStyles.gridItem}>
            <Box sx={partnersSectionStyles.logoContainer}>
              <Box component={"img"} src={advocard} sx={partnersSectionStyles.logo} alt={"Logo Advocard"} />
            </Box>
          </Grid>
          <Grid item md={2} xs={6} sx={partnersSectionStyles.gridItem}>
            <Box sx={partnersSectionStyles.logoContainer}>
              <Box component={"img"} src={arag} sx={partnersSectionStyles.logo} alt={"Logo Arag"} />
            </Box>
          </Grid>
          <Grid item md={2} xs={6} sx={partnersSectionStyles.gridItem}>
            <Box sx={partnersSectionStyles.logoContainer}>
              <Box component={"img"} src={wbs} sx={partnersSectionStyles.logo} alt={"Logo WBS"} />
            </Box>
          </Grid>
          <Grid item md={2} xs={6} sx={partnersSectionStyles.gridItem}>
            <Box sx={partnersSectionStyles.logoContainer}>
              <Box component={"img"} src={check} sx={partnersSectionStyles.logo} alt={"Logo CHECK24"} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PartnersSection;
