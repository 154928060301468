import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconDivorce = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36">
        <g>
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M74.513,79.885v6.832h65.755h19.434h110.344l-38.073-24.794h-72.271h-19.434H74.513v6.539
				c0,1.48-1.2,2.681-2.68,2.681v6.063C73.313,77.205,74.513,78.405,74.513,79.885z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M225.487,173.139v-6.832h-65.785h-19.434H29.954l38.074,24.794h72.241h19.434h65.785v-6.544
				c0-1.48,1.199-2.681,2.68-2.681v-6.057C226.687,175.82,225.487,174.62,225.487,173.139z"
            />
          </g>
          <g>
            <polygon
              style={{ fill: "#FFFFFF" }}
              points="159.702,86.717 140.269,86.717 74.513,86.717 74.513,111.51 140.269,111.51
				159.702,111.51 231.973,111.51 270.046,86.717 			"
            />
            <polygon
              style={{ fill: "#FFFFFF" }}
              points="140.269,141.513 68.027,141.513 29.954,166.307 140.269,166.307 159.702,166.307
				225.487,166.307 225.487,141.513 159.702,141.513 			"
            />
          </g>
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M276.421,84.471l-42.189-27.475c-0.436-0.284-0.943-0.434-1.463-0.434h-73.066V20h-19.434v36.563
			H71.833c-1.48,0-2.68,1.2-2.68,2.68v9.219c0,1.48,1.2,2.681,2.68,2.681s2.68-1.2,2.68-2.681v-6.539h65.755h19.434h72.271
			l38.073,24.794l-38.073,24.793h-72.271h-19.434H74.513V86.717v-6.832c0-1.48-1.2-2.68-2.68-2.68s-2.68,1.2-2.68,2.68v34.306
			c0,1.48,1.2,2.681,2.68,2.681h68.436v19.281H67.231c-0.52,0-1.027,0.15-1.463,0.434l-42.189,27.474
			c-0.759,0.495-1.217,1.34-1.217,2.246s0.458,1.751,1.217,2.246l42.189,27.474c0.436,0.284,0.943,0.434,1.463,0.434h73.037V280
			h19.434v-83.538h68.465c1.48,0,2.68-1.2,2.68-2.68v-9.224c0-1.48-1.199-2.681-2.68-2.681s-2.68,1.2-2.68,2.681v6.544h-65.785
			h-19.434H68.027l-38.074-24.794l38.074-24.794h72.241h19.434h65.785v24.794v6.832c0,1.48,1.199,2.681,2.68,2.681
			s2.68-1.2,2.68-2.681v-34.306c0-1.48-1.199-2.68-2.68-2.68h-68.465v-19.281h73.066c0.52,0,1.027-0.151,1.463-0.435l42.189-27.474
			c0.76-0.495,1.217-1.339,1.217-2.246S277.181,84.965,276.421,84.471z"
          />
        </g>
      </g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconDivorce;
